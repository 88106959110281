const RICSHubRequestsCard = (props) => {
    const count = props.count;
    const showReports = props.showReports;

    const handleRequestsClick = () => {
        showReports();
    }

    return (
        <>
            <div className="rics-hub-request-card-container">
                <div className="rics-hub-request-card-headings">
                    <div className="col">
                        RICS REQUESTS
                    </div>
                </div>
                <div className="rics-hub-request-card-content">
                    <div className="col">Open</div>
                    <div className="count col">{count}</div>
                </div>
                <div className="rics-hub-request-card-buttons">
                    <button onClick={handleRequestsClick}>See request tickets</button>
                </div>
            </div>
        </>
    )
}

export default RICSHubRequestsCard