import {useState, useEffect} from "react";
import valuation_api from "../api/valuation";
import comparison_api from "../api/comparison";
import PropertyDropdown from "./PropertyDropdown";
import download_icon from "../assets/download.png"
import GenericDialog from "./GenericDialog";
import ButtonLoadingWheel from "./ButtonLoadingWheel";

const CompareProperties = () => {

    const [valuations, setValuations] = useState();

    const [firstValuation, setFirstValuation] = useState();
    const [secondValuation, setSecondValuation] = useState();
    const [thirdValuation, setThirdValuation] = useState();

    const [firstDetails, setFirstDetails] = useState(false);
    const [secondDetails, setSecondDetails] = useState(false);
    const [thirdDetails, setThirdDetails] = useState(false);

    const [showDownloading, setShowDownloading] = useState(false);

    // Errors
    const [showErrorDialog, setShowErrorDialog] = useState(false);
    const [error, setError] = useState();
    const okButton = [{"text":"OK", "id": "1"}];

    const handleDropdownChange = (dropdown, valuation) => {
        if (dropdown === 1) {
            setFirstValuation(valuation)
        } else if (dropdown === 2) {
            setSecondValuation(valuation)
        } else if (dropdown === 3) {
            setThirdValuation(valuation)
        }
    }

    const downloadCSV = (filename, text) => {
        let element = document.createElement("a")
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
        element.setAttribute("download", filename)
        element.style.display = "none"

        document.body.appendChild(element)
        element.click()
        document.body.removeChild(element)
    }

    const handleDownload = () => {
        if (showDownloading) return;
        setShowDownloading(true);

        let date = new Date()
        let time = date.toISOString().split("T")[0]

        let response = comparison_api.createTable(firstValuation.id, secondValuation.id, thirdValuation.id)
        response.then(
            response => {
                downloadCSV(`Comparison_${time}.csv`,response.data)
            }
        ).finally(() => {
            setShowDownloading(false);
        }
        ).catch(err => {
            setError("Failed to create comparison file");
            setShowErrorDialog(true);
        })
    }

    const handleOKDialogResult = (res) => {
        setShowErrorDialog(false);
    }

    useEffect(() => {
        const searchData = {
            "type": 3,
        }
        let response = valuation_api.getMyValuations(searchData);
        response.then(
            response => {
                setValuations(response.data.valuation_summaries);
            }
        ).catch (err => {
            setError("Failed to retrieve previous valuation data");
            setShowErrorDialog(true);
        })
    }, []);

    useEffect(() => {
        if (!firstValuation) return;
        let response = comparison_api.getOverview(firstValuation.id);
        response.then(
            response => {
                setFirstDetails(response.data)
            }
        ).catch(err => {
            setError("Failed to retrieve the first valuations overview data");
            setShowErrorDialog(true);
        })
    },[firstValuation])

    useEffect(() => {
        if (!secondValuation) return;
        let response = comparison_api.getOverview(secondValuation.id);
        response.then(
            response => {
                setSecondDetails(response.data)
            }
        ).catch (err => {
            setError("Failed to retrieve the second valuations overview data");
            setShowErrorDialog(true);
        })
    },[secondValuation])

    useEffect(() => {
        if (!thirdValuation) return;
        let response = comparison_api.getOverview(thirdValuation.id);
        response.then(
            response => {
                setThirdDetails(response.data)
            }
        ).catch(err => {
            setError("Failed to retrieve the third valuations overview data");
            setShowErrorDialog(true);
        })
    },[thirdValuation])

    const schoolList = (array, type) => {
        if (!array) array=[]
        let local = [...array]
        let _p = []
        let _s = []
        let _schools = []
        if (type === "primary") {
            local.forEach((school) => {
                if (school.tab_type === 3) {
                    if (school.card_type === 1) {
                        _p.push({
                            "name": school.name,
                            "rating": school.rating
                        })
                    }
                }
            })
            _schools.push(_p)
        } else if (type === "secondary") {
            local.forEach((school) => {
                if (school.tab_type === 3) {
                    if (school.card_type === 2) {
                        _s.push({
                            "name": school.name,
                            "rating": school.rating
                        })
                    }
                }
            })
            _schools.push(_s)
        }
        return _schools
    }

    const getIndex = (value, array, type) => {
        let response = ""
        if (!array) return response;
        if (type === 1) {
            let idx = array.map(item => item.title).indexOf(value)
            if (idx === -1) return response
            response = array[idx].value
        } else if (type === 2) {
            let idx = array.map(item => item.name).indexOf(value)
            if (idx === -1) return response
            response = array[idx].rating
            if (value === "ultrafast_broadband" || value === "superfast_broadband") {
                if (parseInt(response) > 0) {
                    response = "✓"
                } else {
                    response = "X"
                }
            }
        }
        return response
    }

    const getRange = (value, acc) => {
        let min = (value * (acc/ 100)).toLocaleString()
        let max = (value * (((100 - acc)+100)/100)).toLocaleString()
        let range = `£${min} - £${max}`
        return range
    }

    return(
        <>
            <div className="compare-properties-container">
                {valuations && valuations.length > 2 ?
                    <div className="compare-properties-dropdown-row">
                        <PropertyDropdown valuations={valuations}
                                          valuation={firstValuation}
                                          dropdownNum={1}
                                          handleDropdownChange={handleDropdownChange.bind(this)}/>
                        <PropertyDropdown valuations={valuations}
                                          valuation={secondValuation}
                                          dropdownNum={2}
                                          handleDropdownChange={handleDropdownChange.bind(this)}/>
                        <PropertyDropdown valuations={valuations}
                                          valuation={thirdValuation}
                                          dropdownNum={3}
                                          handleDropdownChange={handleDropdownChange.bind(this)}/>
                    </div>
                : null }
                {firstDetails && secondDetails && thirdDetails ?
                    <>
                        <div className="compare-properties-table flex">
                            <div className="compare-properties-column">
                                <div className="compare-properties-table-header">Overview</div>
                                <div className="recent-searches-divider"/>
                                <div className="compare-properties-table-title">Value range</div>
                                <div className="recent-searches-divider"/>
                                <div className="compare-properties-table-title">Property type</div>
                                <div className="recent-searches-divider"/>
                                <div className="compare-properties-table-title">Tenure type</div>
                                <div className="compare-properties-table-header shade">Details</div>
                                <div className="recent-searches-divider"/>
                                <div className="compare-properties-table-title">Property size</div>
                                <div className="recent-searches-divider"/>
                                <div className="compare-properties-table-title">No. of Bedrooms</div>
                                <div className="recent-searches-divider"/>
                                <div className="compare-properties-table-title">No. of Bathrooms</div>
                                <div className="recent-searches-divider"/>
                                <div className="compare-properties-table-title">EPC rating</div>
                                <div className="recent-searches-divider"/>
                                <div className="compare-properties-table-title">New build status</div>
                                <div className="recent-searches-divider"/>
                                <div className="compare-properties-table-title">Flood risk</div>
                                <div className="compare-properties-table-header shade">The local area</div>
                                <div className="recent-searches-divider"/>
                                <div className="compare-properties-table-title">Health index</div>
                                <div className="recent-searches-divider"/>
                                <div className="compare-properties-table-title">Ultrafast broadband available</div>
                                <div className="recent-searches-divider"/>
                                <div className="compare-properties-table-title">Superfast broadband available</div>
                                <div className="recent-searches-divider"/>
                                <div className="compare-properties-table-title">Air quality</div>
                                <div className="compare-properties-table-header shade">Schools</div>
                                <div className="recent-searches-divider"/>
                                <div className="compare-properties-table-title school">Nearby Primary schools</div>
                                <div className="recent-searches-divider"/>
                                <div className="compare-properties-table-title school">Nearby Secondary schools</div>
                                <div className="recent-searches-divider"/>
                            </div>
                            {firstDetails && secondDetails && thirdDetails ?
                                [firstDetails, secondDetails, thirdDetails].map((details) => {
                                    return (
                                        <div className="compare-properties-column">
                                            <div className="compare-properties-table-header"></div>
                                            <div className="recent-searches-divider"/>
                                            <div className="compare-properties-row-value">
                                                {getRange(details.predicted_valuation, details.accuracy)}
                                            </div>
                                            <div className="recent-searches-divider"/>
                                            <div className="compare-properties-row-value">
                                                {getIndex('Property Type', details.further_details, 1)}
                                            </div>
                                            <div className="recent-searches-divider"/>
                                            <div className="compare-properties-row-value">
                                                {getIndex('Tenure', details.further_details, 1)}
                                            </div>
                                            <div className="compare-properties-table-header shade"></div>
                                            <div className="recent-searches-divider"/>
                                            <div className="compare-properties-row-value">
                                                {getIndex('Property Size', details.further_details, 1)}
                                            </div>
                                            <div className="recent-searches-divider"/>
                                            <div className="compare-properties-row-value">
                                                {getIndex('No. of bedrooms', details.further_details, 1)}
                                            </div>
                                            <div className="recent-searches-divider"/>
                                            <div className="compare-properties-row-value">
                                                {getIndex('No. of bathrooms', details.further_details, 1)}
                                            </div>
                                            <div className="recent-searches-divider"/>
                                            <div className="compare-properties-row-value">
                                                {getIndex('EPC Rating', details.further_details, 1)}
                                            </div>
                                            <div className="recent-searches-divider"/>
                                            <div className="compare-properties-row-value">
                                                {getIndex('New Build status', details.further_details, 1)}
                                            </div>
                                            <div className="recent-searches-divider"/>
                                            <div className="compare-properties-row-value">
                                                {getIndex('Flood risk score', details.further_details, 1)}
                                            </div>
                                            <div className="compare-properties-table-header shade"></div>
                                            <div className="recent-searches-divider"/>
                                            <div className="compare-properties-row-value">
                                                {getIndex('health_index', details.local_area, 2)}
                                            </div>
                                            <div className="recent-searches-divider"/>
                                            <div className="compare-properties-row-value">
                                                {getIndex('ultrafast_broadband', details.local_area, 2)}
                                            </div>
                                            <div className="recent-searches-divider"/>
                                            <div className="compare-properties-row-value">
                                                {getIndex('superfast_broadband', details.local_area, 2)}
                                            </div>
                                            <div className="recent-searches-divider"/>
                                            <div className="compare-properties-row-value">
                                                {getIndex('aqi', details.local_area, 2)}
                                            </div>
                                            <div className="compare-properties-table-header shade"></div>
                                            <div className="recent-searches-divider"/>
                                            <div className="compare-properties-list-cell">
                                                {schoolList(details.local_area, "primary")[0].slice(0, 3).map((school) => {
                                                    return (
                                                        <div className="compare-properties-row-value school">
                                                            <div className="compare-properties-row-school">
                                                                {school.name}
                                                            </div>
                                                            <div className="compare-properties-row-school">
                                                                {school.rating}
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                            <div className="recent-searches-divider"/>
                                            <div className="compare-properties-list-cell">
                                                {schoolList(details.local_area, "secondary")[0].slice(1, 4).map((school) => {
                                                    return (
                                                        <div className="compare-properties-row-value school">
                                                            <div className="compare-properties-row-school">
                                                                {school.name}
                                                            </div>
                                                            <div className="compare-properties-row-school">
                                                                {school.rating}
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                            <div className="recent-searches-divider"/>
                                        </div>
                                    )
                                })
                                : null}
                        </div>
                        <div className="compare-properties-download-container">
                            <div className={showDownloading && showDownloading
                                ? "compare-properties-download-button grey"
                                : "compare-properties-download-button link"}
                                 onClick={handleDownload}>
                                <div className="compare-properties-download-text">
                                    {showDownloading && showDownloading
                                        ? "Preparing Excel file"
                                        : "Download table (Excel)"}
                                </div>
                                <div className="compare-properties-download-img">
                                    {showDownloading && showDownloading
                                        ? <ButtonLoadingWheel show={showDownloading}/>
                                        : <img src={download_icon} alt={"download"}/> }
                                </div>
                            </div>
                        </div>
                    </>
                    : (valuations && valuations.length > 2) ?
                        <div className="compare-properties-required-container">
                            <div className="compare-properties-required-card">
                                <div className="compare-properties-required-text">
                                    Select 3 insight valuations to start comparing.
                                </div>
                            </div>
                        </div>
                        :
                        <div className="compare-properties-required-container">
                            <div className="compare-properties-required-card">
                                <div className="compare-properties-required-text">
                                    Make 3 insight valuations to start comparing.
                                </div>
                            </div>
                        </div>
                }
            </div>
            {showErrorDialog ? <GenericDialog title="Data Error" text={error} buttons={okButton}
                                              result={handleOKDialogResult.bind(this)}/> : null}
        </>
    )
}

export default CompareProperties