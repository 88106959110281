import {useEffect, useState} from "react";
import PageSubMenu from "../components/PageSubMenu";
import InsightRecentActivityScroller from "../components/InsightRecentActivityScroller";


const InsightRecentActivity = (props) => {

    const title = props.title;
    const subTitle = props.subTitle;
    const salesData = props.salesData;
    const rentData = props.rentData;
    const salesAvgData = props.salesAvgData;
    const predictedValue = props.predictedValue;


    const [tabMenus, setTabMenus] = useState()
    const [sectionName, setSectionName] = useState('Recent sales activity');

    useEffect(() => {
        if (!props.tabs) return;
        let menus = props.tabs;

        setTabMenus(menus);
        setSectionName(menus[0].name);
    }, [props.tabs]);

    const UpdateActiveMenu = (idx) => {
        if(!tabMenus) return;
        setSectionName(tabMenus[idx].name);
    }

    return (
        <>
            <div className="insight-recent-container">
                <div className="insight-recent-title-container">
                    <div className="insight-recent-title">
                        {title}
                    </div>
                </div>
                {subTitle ?
                    <div className="insight-recent-subtitle-container">
                        <div className="insight-recent-subtitle">
                            {subTitle}
                        </div>
                    </div>
                    :null}
                <PageSubMenu items={tabMenus}
                             setActiveMenu={UpdateActiveMenu.bind(this)}
                             spacing="0"
                             setSelection={sectionName}
                             indicator="40"/>
                <InsightRecentActivityScroller setSelection={sectionName && sectionName}
                                               title={title}
                                               tabs={tabMenus && tabMenus}
                                               salesData={salesData}
                                               salesAvgData={salesAvgData}
                                               rentData={rentData}
                                               predictedValue={predictedValue}/>
            </div>
        </>
    )
}

export default InsightRecentActivity