import {useEffect, useState} from "react";
import star from "../assets/rating_star.png"
import arrow from "../assets/arrow_top_right.png"

const LocalAreaCardComponent = (props) => {

    const title = props.title;
    const image = props.image;
    const [data, setData] =  useState([])
    const [empty, setEmpty] = useState(true)
    const [fullNoRating, setFullNoRating] = useState(false);

    useEffect(() => {
        if (title === "Train stations nearby" || title === "Bus stops nearby") {
            setFullNoRating(true);
        } else {
            setFullNoRating(false);
        }
    },[title])

    useEffect(() => {
        if (!props.data) return
        if (props.data.length !== 0) {
            setData(props.data.sort((a, b) => a.distance - b.distance))
        } else {
            setData([])
        }

    },[props.data])

    useEffect(() => {
        if (data.length !== 0) {
            setEmpty(false)
        }
    },[data])

    return (
        <>
            <div className="insight-local-grid-item-container">
                <div className="insight-local-card-container">
                    <div className="insight-local-card-header-container">
                        <div className="insight-local-card-header-icon-container">
                            <img src={image} className="insight-local-card-header-icon" alt="icon"/>
                        </div>
                        <div className="insight-local-card-header-title-container">
                            <div className="insight-local-card-header-title">{title}</div>
                        </div>
                        <div className="insight-local-card-header-distance-container">
                            est. distance
                        </div>
                    </div>
                    <div className="insight-local-card-details-container">
                        {empty && empty?
                            <div className={"insight-local-card-row-container "}>
                                <div className="insight-local-card-organization-container empty">
                                    <div className="insight-local-card-organization empty">
                                        Our data providers currently do not have any infomation for this property.
                                    </div>
                                </div>
                            </div>
                            :
                            <>
                                {data && data.slice(0,5).map((row, idx) => {
                                    return (
                                        <div className={"insight-local-card-row-container " + idx}>
                                            <div className="insight-local-card-organization-container">
                                                <div className="insight-local-card-organization">
                                                    {row.name}
                                                </div>
                                            </div>
                                            {row.distance > 0 && row.distance !== null ?
                                                <div className="insight-local-card-distance-container">
                                                    <div className="insight-local-card-distance-arrow-container">
                                                        <img src={arrow} alt="arrow"/>
                                                    </div>
                                                    <div className="insight-local-card-distance-value-container">
                                                        <div className="insight-local-card-distance-value">
                                                            {(row.distance/1000).toFixed(1)} km
                                                        </div>
                                                    </div>
                                                </div>
                                                :null}

                                        </div>
                                    )
                                })}
                            </>
                        }

                    </div>
                </div>
            </div>
        </>
    )
}

export default LocalAreaCardComponent