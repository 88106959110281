import axiosInstance from "../http/axios_instance"
const http = axiosInstance("/protected/orgadmin/",  null, "/dashboard")

const getAllReportDetails = (filters) => http.post("/report_details", filters);
const getManagementReports = (body) => http.post("/management_reports", body);
const downloadCSV = (filters) => http.post("/reports_csv", filters);

export default {
    getAllReportDetails,
    getManagementReports,
    downloadCSV
}