import {useEffect, useState} from "react";

const InsightKeyDetail = (props) => {

    // Report Data
    // const [furtherDetails, setFurtherValuation] = useState();
    const single = props.single;
    const details = props.details;
    const detailsPD = props.detailsPD;
    const text = props.text;

    // Error Showing
    const setError = props.setError
    const setShowErrorDialog = props.setShowErrorDialog

    const handleShowDownloadError = (error) => {
        setError(error);
        setShowErrorDialog(true);
    }

    return (
        <>
            {details ?
                <div className={single ? "insight-key-detail-single-container" : "insight-key-detail-double-container"}>
                    <div className="insight-key-detail-img-container">
                        <img className="insight-key-detail-img" src={"/images/valuation/" + details.icon_filename} alt="card image"/>
                    </div>
                    <div className="insight-key-detail-text-container">
                        <div className="insight-key-detail-text">
                            {details.value + " " + text}
                        </div>
                    </div>
                </div>
            :
                detailsPD ?
                    <div className={"insight-key-detail-double-container prop-details"}>
                        <div className="insight-key-detail-img-container">
                            <img className="insight-key-detail-img" src={"/images/valuation/" + detailsPD.icon_filename} alt="card image"/>
                        </div>
                        <div className="insight-key-detail-text-container">
                            <div className="insight-key-detail-text">
                                {detailsPD.value + " " + text}
                            </div>
                        </div>
                    </div>
                : <div className="insight-key-detail-double-container prop-details empty"/>
            }
        </>
    )
}

export default InsightKeyDetail