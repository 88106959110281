const formatAddress = (address) => {
    let output = "";
    if (address.paon && address.paon !== ""){
        output += address.paon + ", ";
    }
    if (address.saon && address.saon !== ""){
        output += " " + address.saon;
    }
    if (address.street && address.street !== ""){
        output += " " + address.street;
    }
    if (address.locality && address.locality !== ""){
        output += ", " + address.locality;
    }
    if (address.district && address.district !== ""){
        output += ", " + address.district;
    }
    if (address.town && address.town !== ""){
        output += ", " + address.town;
    }
    if (address.county && address.county !== ""){
        output += ", " + address.county;
    }
    if (address.postcode && address.postcode !== ""){
        output += ", " + address.postcode;
    }
    return output;
}

const formatSearchAddress = (address) => {
    let output = "";

    if (address.address && address.address !== ""){
        output += address.address;
    }

    if (address.postcode && address.postcode !== ""){
        output += ", " + address.postcode.toUpperCase();
    }

    return output;
}


const formatPostCodeAndCount = (address) => {
    let output = "";

    if ((address.postcode && address.postcode !== "") && parseInt(address.property_count)){
        let _address_word;
        if (parseInt(address.property_count) > 1) _address_word = " addresses"
        else _address_word = " address"
        output += address.postcode.toUpperCase() + " - " + address.property_count + _address_word;
    }
    else output = address.postcode.toUpperCase();

    return output
}

const getPdfFilenameAdminDetail = (detail) => {
    let filename = "";
    filename += detail.property.address;
    filename += "-";
    filename += detail.property.postcode;
    filename += "-";
    const dt = new Date(detail.report_date * 1000);
    filename += dt.getDate() + "-" + (dt.getMonth()+1) + "-" + dt.getFullYear() + "-" +
        dt.getHours() + "-" + dt.getMinutes() + "-" + dt.getSeconds();
    filename = filename.trim();
    filename = filename.replaceAll(",", "");
    filename = filename.replaceAll(" ", "-");
    filename += ".pdf";
    return filename;
}

const getPdfFilename = (report) => {
    let filename = "";
    if (report.address && report.address !== ""){
        filename += report.address.toUpperCase();
    }
    if (report.postcode && report.postcode !== ""){
        filename += ", " + report.postcode.toUpperCase();
    }

    if (report.date !== undefined) {
        const dt = new Date(report.date * 1000);
        filename += dt.getDate() + "-" + (dt.getMonth()+1) + "-" + dt.getFullYear() + "-" +
            dt.getHours() + "-" + dt.getMinutes() + "-" + dt.getSeconds();
    }
    filename = filename.trim();
    filename = filename.replaceAll(",", "");
    filename = filename.replaceAll(" ", "-");
    filename += ".pdf";
    return filename;
}

const getFileName = (report, file_type) => {
    let filename = "";
    if (report.address && report.address !== ""){
        filename += report.address.toUpperCase();
    }
    if (report.postcode && report.postcode !== ""){
        filename += ", " + report.postcode.toUpperCase();
    }

    if (report.date !== undefined) {
        const dt = new Date(report.date * 1000);
        filename += dt.getDate() + "-" + (dt.getMonth()+1) + "-" + dt.getFullYear() + "-" +
            dt.getHours() + "-" + dt.getMinutes() + "-" + dt.getSeconds();
    }
    filename = filename.trim();
    filename = filename.replaceAll(",", "");
    filename = filename.replaceAll(" ", "-");
    filename += file_type;
    return filename;
}

export default {
    formatAddress,
    formatSearchAddress,
    formatPostCodeAndCount,
    getPdfFilename,
    getPdfFilenameAdminDetail,
    getFileName
}