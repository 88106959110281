const ROLE_USER = 1;
const ROLE_ORGANISATION_ADMIN = 2;
const ROLE_ADMIN = 3;
const ROLE_VALUER = 4;
const ROLE_RETAIL_USER = 5;

export default {
    ROLE_USER,
    ROLE_ORGANISATION_ADMIN,
    ROLE_ADMIN,
    ROLE_VALUER,
    ROLE_RETAIL_USER
}