import React from 'react'

const GoogleMapsWidget = (props) => {

    const mapLocation = props.mapLocation

    return (
        <div className="insight-maps-widget">
            <iframe
                width="620"
                height="300"
                loading="lazy"
                allowFullScreen
                referrerPolicy="no-referrer-when-downgrade"
                src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&q=${mapLocation}`}>
            </iframe>
        </div>
    )
}

export default GoogleMapsWidget