import SplashScreen from "../components/SplashScreen";
import eye from "../assets/Eye.png";
import important from "../assets/important.png";
import {useCallback, useEffect, useState} from "react";
import styles from "../models/Styles";
import kapi from "../api/kratos";
import auth from "../api/auth";
import logo from "../assets/logo-splash.png";

const ResetPassword = () => {
    const [password, setPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState();
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [passwordMismatch, setPasswordMismatch] = useState(false);
    const [flowResponse, setFlowResponse] = useState();

    const InitSettings = () => {
        const response = kapi.init_settings();
        response.then(response => {
            setFlowResponse(response.data);
        }).catch(err => {
            console.log(err);
            setPassword("");
            setConfirmPassword("");
        })
    }

    const PageLoad = useCallback(() => {
        const params = new URLSearchParams(window.location.search);
        const flowId = params.get('flow');
        setPassword("");
        setConfirmPassword("");

        if (!flowId) {
            InitSettings();
        }
        else {
            const response = kapi.get_settings(flowId);
            response.then(response => {
                setFlowResponse(response.data);
            }).catch(err => {
                console.log("Unable to retrieve registration: " + err);
            });
        }
    }, [])

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    }

    const toggleShowConfirmedPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    }

    const handleConfirmedPasswordCheck = () => {
        let mismatch = false;
        if (confirmPassword && password && confirmPassword !== password){
            mismatch = true;
        }
        setPasswordMismatch(mismatch);
    }

    const logout = () => {
        auth.logout();
    }

    useEffect(() => {
        PageLoad()
    }, [PageLoad])

    return (
        <>
            <div className="reset-page-container">
                <div className="reset-container">
                    <div className="reset-form-container">
                        <form name="reset-form" action={flowResponse && flowResponse.ui.action}
                              method="POST">
                            <input name="csrf_token" type="hidden"
                                   value={flowResponse && flowResponse.ui.nodes.find(n => n.attributes.name === 'csrf_token')?.attributes.value}/>
                            <div>
                                {flowResponse && flowResponse?.ui?.messages?.map((msg, idx) => {
                                    return(
                                        <div className="messages">{msg.text}</div>
                                    )
                                })}
                            </div>
                            <div className="reset-title-container">
                                <div className="reset-title">Reset password</div>
                                <div className="reset-title-logo">
                                    <img src={logo} alt="Finvise logo" />
                                </div>
                            </div>
                            <div className="reset-sub-title">Please set a new password for your account</div>
                            <div className="field-input">
                                <div className="label">Password</div>
                                <div className="field-input-entry">
                                    <input type="password" placeholder="Set password" name="password"
                                           type={showPassword ? "text" : "password"} value={password}
                                           onChange={(ev) => setPassword(ev.target.value)}
                                           onBlur={handleConfirmedPasswordCheck}
                                           required/>
                                    <img src={eye} onClick={toggleShowPassword} alt="eye logo"
                                        className="eye"/>
                                </div>
                                <div className="row">
                                    <div>{flowResponse && flowResponse.ui.nodes.find(n => n.attributes.name === 'password')?.messages.map((msg, idx) => {
                                        return (
                                            <div className="messages">{msg.text}</div>
                                        )})}
                                    </div>
                                </div>
                            </div>
                            <div className="password-size">Password must be minimum 8 characters.</div>
                            <div className="field-input">
                                <div className="label">Confirm password</div>
                                <div className="field-input-entry">
                                    <input type="password" placeholder="Confirm password" name="confirm-password"
                                           type={showConfirmPassword ? "text" : "password"} value={confirmPassword}
                                           onChange={(ev) => setConfirmPassword(ev.target.value)}
                                           className={passwordMismatch ? "error" : ""}
                                           onBlur={handleConfirmedPasswordCheck}
                                           required/>
                                    <img src={important} alt="important logo" className="important-info"
                                         style={passwordMismatch ?
                                             styles.show : styles.hide}/>
                                    <img src={eye} onClick={toggleShowConfirmedPassword} alt="eye logo"
                                        className="eye"/>
                                </div>
                                <div className="password-error" style={passwordMismatch ?
                                    styles.show : styles.hide}>Passwords must match.</div>
                            </div>
                            <div className="field-input">
                                <button className="reset-button" name="method" type="submit" value="password"
                                        disabled={!password || !confirmPassword || confirmPassword !== password}>
                                    Change password
                                </button>
                            </div>
                            <div className="field-input">
                                <div className="password-reset-tologin label" onClick={logout}>
                                    Go back to sign-in
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
                <div className="splash-container">
                    <SplashScreen />
                </div>
            </div>
        </>
    )
}

export default ResetPassword