const formatCurrencyNumber = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const time_diff = (date) => {
    const now = new Date();
    let seconds = Math.round((now - date) / 1000);
    let string_message = ""
    if (seconds < 60) {
        string_message = "JUST NOW";
    }
    else if (seconds < 120) {
        string_message = "1 MINUTE AGO";
    }
    else if (seconds < 3600) {
        string_message = Math.round(seconds / 60) + " MINUTES AGO";
    }
    else if (seconds < 7200) {
        string_message = "1 HOUR AGO";
    }
    else if (seconds < 86400) {
        string_message = Math.round(seconds / 3600) + " HOURS AGO";
    }
    else if (seconds < 172800) {
        string_message = "1 DAY AGO";
    }
    else if (seconds < 604800) {
        string_message = Math.round(seconds / 86400) + " DAYS AGO";
    }
    return string_message
}

const display_date = (dt) => {
    const options = {dateStyle: 'long'}
    const displayDate = new Date(dt);
    return displayDate.toLocaleString("en-GB", options);
}

const display_date_short = (dt) => {
    const options = {year: '2-digit', month: '2-digit', day: '2-digit'}
    const displayDate = new Date(dt);
    return displayDate.toLocaleString("en-GB", options);
}

const display_date_time = (dt) => {
    const options = {dateStyle: 'short', timeStyle: 'short'}
    const displayDate = new Date(dt);
    return displayDate.toLocaleString("en-GB", options);
}

const short_display_date = (dt) => {
    const options = {dateStyle: 'short'}
    const displayDate = new Date(dt);
    return displayDate.toLocaleString("en-GB", options);
}

const date_display_diff = (diff) => {
    if (!diff) return "";
    let result = "";
    const hours = Math.floor(diff/(60*60*1000));
    if (hours > 0){
        result += hours + "H";
    }
    diff = diff - (hours * 60*60*1000);
    const minutes = Math.floor(diff/(60*1000));
    if (minutes > 0)
        result += " " + minutes + "M";
    return result;
}

export default {
    formatCurrencyNumber,
    time_diff,
    display_date,
    display_date_short,
    display_date_time,
    short_display_date,
    date_display_diff
}