import MainHeader from "../components/MainHeader";
import AdminOrganisationGrid from "../components/AdminOrganisationGrid";
import {useEffect, useState} from "react";
import AdminNewOrganisation from "../components/AdminNewOrganisation";
import AdminReportOverviewGrid from "../components/AdminReportOverviewGrid";
import org_api from "../api/admin/admin_orgs";
import roles from "../models/Roles";
import RICSValuationHub from "../components/rics_hub/RICSValuationHub";

const AdminOrganisations = () => {
    const [showNewOrg, setShowNewOrg] = useState(false);
    const [showOverview, setShowOverview] = useState(false);
    const [showOrganisations, setShowOrganisations] = useState(true);
    const [showRICSHub, setShowRICSHub] = useState(false);
    const [profile, setProfile] = useState();
    const [organisations, setOrganisations] = useState();

    const updateNewOrg = (show) => {
        getOrgs();
        setShowNewOrg(show);
        setShowOrganisations(!show);
    }

    const updateShowOrg = (show) => {
        setShowNewOrg(show);
        setShowOrganisations(!show);
    }

    const updateProfile = (profile) => {
        setProfile(profile);
    }

    const getOrgs = () => {
        let response = org_api.getOrganisations();
        response.then(
            response => {
                setOrganisations(response.data.organisations);
            }
        )
    }

    useEffect(() => {
        getOrgs()
    }, [])

    useEffect(() => {
        // Don't let a non admin navigate here by hand
        if (profile && profile.role_id !== roles.ROLE_ADMIN)
            window.location.assign("/");
    }, [profile])

    function handleOverviewClick() {
        setShowNewOrg(false);
        setShowOrganisations(false);
        setShowOverview(true);
        setShowRICSHub(false);
    }

    function handleShowOrganisationsClick() {
        setShowOverview(false);
        setShowNewOrg(false);
        setShowOrganisations(true);
        setShowRICSHub(false);
    }

    function handleShowRICSHubClick() {
        setShowOverview(false);
        setShowNewOrg(false);
        setShowOrganisations(false);
        setShowRICSHub(true);
    }

    return (
        <>
            <MainHeader updateProfile={updateProfile.bind(this)} />
            <div className="admin-organisation-page-container">
                <div className="admin-organisation-container">
                    <div className="admin-organisation-sidemenu col">
                        <div className="admin-organisation-menu-title">
                            FINVISE
                        </div>
                        <div className="admin-organisation-menu-subtitle">
                            Admin view
                        </div>
                        <div className={showOverview ?
                            "admin-organisation-category-title link active" :
                            "admin-organisation-category-title link"}
                             onClick={handleOverviewClick}>
                            Overview
                        </div>
                        <div className={showOrganisations ?
                            "admin-organisation-category-title link active" :
                            "admin-organisation-category-title link"}
                             onClick={handleShowOrganisationsClick}>
                            Organisations
                        </div>
                        <div className={showRICSHub ?
                            "admin-organisation-category-title link active" :
                            "admin-organisation-category-title link"}
                             onClick={handleShowRICSHubClick}>
                            RICS Valuation Hub
                        </div>
                    </div>
                    <div className="admin-organisation-content col">
                        {showNewOrg ?
                            <>
                                <AdminNewOrganisation update={updateNewOrg.bind(this)}/>
                            </>
                            : null }
                        {showOrganisations ?
                            <>
                                <div className="admin-organisation-content-title">Organisations</div>
                                <AdminOrganisationGrid update={updateShowOrg.bind(this)}
                                    organisations={organisations}/>
                            </>
                            : null }
                        {showOverview ?
                            <>
                                <AdminReportOverviewGrid profile={profile}/>
                            </>
                        : null}
                        {showRICSHub ?
                            <>
                                <RICSValuationHub/>
                            </>
                            : null}
                    </div>
                </div>
            </div>
        </>
    )
}

export default AdminOrganisations