import {useState, useEffect} from "react";
import MainHeader from "../components/MainHeader";
import CompareProperties from "../components/CompareProperties";
import valuation_api from "../api/valuation";
import address_formatter from "../utils/Address";
import date_formatter from "../utils/DateUtils";
const PropertyComparison = () => {

    const [valuations, setValuations] = useState();

    const handleSeeInsight = (valuation_id) => {
        const base = process.env.REACT_APP_WEB_URL;
        window.location.replace(base + "/valuation?id=" + valuation_id)
    }

    useEffect(() => {
        const searchData = {
            "type": 3,
        }
        let response = valuation_api.getMyValuations(searchData);
        response.then(
            response => {
                setValuations(response.data.valuation_summaries);
            }
        )
    }, []);

    return (
        <>
            <MainHeader />
            <div className="comparison-container">
                <div className="comparison-title-container">
                    <div className="comparison-title">
                        Your recent searches
                    </div>
                </div>
                <div className="comparison-recent-searches-container">
                    <div className="comparison-recent-searches-title-container">
                        <div className="comparison-recent-searches-title">
                            Your recent searches
                        </div>
                    </div>
                    <div className="recent-searches-grid-contents">
                        <div className="recent-searches-grid-col-headers">
                            <div className="property-address col">Property address</div>
                            <div className="purchase-date col">Purchase date</div>
                            <div className="insight col"></div>
                        </div>
                        <div className="recent-searches-grid-rows">
                            {valuations && valuations.map((detail, idx) => {
                                return (
                                    <>
                                        <div className="recent-searches-grid-row">
                                            <div className="property-address col">
                                                {address_formatter.formatSearchAddress(detail)}
                                            </div>
                                            <div className="purchase-date col">
                                                {date_formatter.display_date_time(detail.date*1000)}
                                            </div>
                                            <div className="insight col" data-index={idx}
                                                 onClick={() => handleSeeInsight(detail.id)}>
                                                See insight
                                            </div>
                                        </div>
                                        <div className="recent-searches-divider"/>
                                    </>
                                )
                            })}
                        </div>
                    </div>
                </div>
                <div className="comparison-title-container">
                    <div className="comparison-title">
                        Compare your properties
                    </div>
                </div>
                <CompareProperties />
            </div>
        </>
    )

}

export default PropertyComparison