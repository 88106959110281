import OrganisationReportSummary from "../components/OrganisationReportSummary";
import org_api from "../api/admin/admin_orgs";
import org_admin_api from "../api/orgadmin/orgadmin_orgs";
import {useEffect, useState} from "react";
import OrganisationTeamSummary from "../components/OrganisationTeamSummary";
import OrganisationCreditSummary from "../components/OrganisationCreditSummary";
import OrganisationConstants from "../models/OrganisationConstants";
import MainHeader from "../components/MainHeader";
import OrganisationPeople from "../components/OrganisationPeople";
import OrganisationCreditDetails from "../components/OrganisationCreditDetails";
import AdminReportOverviewGrid from "../components/AdminReportOverviewGrid";
import CreditBasedBillingCard from "../components/CreditBasedBillingCard";
import UsageBasedBillingCard from "../components/UsageBasedBillingCard";
import roles from "../models/Roles";
import ManagementReports from "../components/ManagementReports";

const AdminOrganisation = () => {
    const params = new URLSearchParams(window.location.search);
    const org_id = params.get("id");
    const [organisation, setOrganisation] = useState();
    const [profile, setProfile] = useState();
    const [filter, setFilter] = useState();
    const [activeSection, setActiveSection] = useState(OrganisationConstants.SUMMARY_SECTION);

    const getOrganisation = () => {
        if (!profile) return;
        if (profile.role_id === roles.ROLE_ADMIN && !org_id) return;

        let response = null;
        if (profile.role_id === roles.ROLE_ADMIN) {
            // Pass the id through to the api call for full admin
            response = org_api.getOrganisation(org_id);
        }
        if (profile.role_id === roles.ROLE_ORGANISATION_ADMIN) {
            // Let the api determine the org id based on the org admin logged in
            response = org_admin_api.getOrganisation();
        }
        response.then(
            response => {
                if (response.data.organisations && response.data.organisations.length >0)
                    setOrganisation(response.data.organisations[0]);
            }
        )
    }

    const updateSection = (section) => {
        getOrganisation();
        setActiveSection(section);
    }

    const updateOverviewFilter = (filter) => {
        setFilter(filter);
        setActiveSection(OrganisationConstants.REPORTS_SECTION);
    }

    const handleNameClick = () => {
        setActiveSection(OrganisationConstants.SUMMARY_SECTION);
    }

    const handlePeopleClick = () => {
        setActiveSection(OrganisationConstants.PEOPLE_SECTION);
    }

    const handleReportsClick = () => {
        setActiveSection(OrganisationConstants.REPORTS_SECTION);
    }

    const handleValuationReportsClick = () => {
        setActiveSection(OrganisationConstants.REPORT_VALUATION_SECTION);
    }

    const handleRICSReportsClick = () => {
        setActiveSection(OrganisationConstants.REPORT_RICS_SECTION);
    }

    const handleInsightsReportsClick = () => {
        setActiveSection(OrganisationConstants.REPORT_INSIGHT_SECTION);
    }

    const handleAllReportsClick = () => {
        setActiveSection(OrganisationConstants.REPORT_ALL_SECTION);
    }

    const handleOrgClick = () => {
        window.location.assign("/admin/organisations");
    }

    const updateProfile = (profile) => {
        setProfile(profile);
    }

    useEffect(() => {
        getOrganisation()
    }, [profile, org_id])

    return (
        <>
            <MainHeader updateProfile={updateProfile.bind(this)}/>
            <div className="admin-organisation-page-container">
                <div className="admin-organisation-container">
                    <div className="admin-organisation-sidemenu col">
                        {profile && profile.role_id === roles.ROLE_ADMIN ?
                            <>
                                <div className="admin-organisation-menu-title">
                                    FINVISE
                                </div>
                                <div className="admin-organisation-menu-subtitle">
                                    Admin view
                                </div>

                                <div className="admin-organisation-category-title link"  onClick={handleOrgClick}>
                                    Organisations
                                </div>
                            </>
                            : null
                        }
                        <div className="admin-organisation-category-content">
                            <div className={activeSection === OrganisationConstants.SUMMARY_SECTION ? "admin-organisation-category-name active link"
                                : "admin-organisation-category-name link"} onClick={handleNameClick}>
                                {organisation && organisation.name}</div>
                            <div className={activeSection === OrganisationConstants.REPORTS_SECTION ? "admin-organisation-category-subheading active link"
                                : "admin-organisation-category-subheading link"} onClick={handleReportsClick}>
                                Overview
                            </div>
                            <div className={activeSection === OrganisationConstants.REPORT_VALUATION_SECTION ||
                            activeSection === OrganisationConstants.REPORT_RICS_SECTION ||
                            activeSection === OrganisationConstants.REPORT_INSIGHT_SECTION ||
                            activeSection === OrganisationConstants.REPORT_ALL_SECTION ? "admin-organisation-category-subheading active"
                                : "admin-organisation-category-subheading"} >
                                Reports
                            </div>
                            <div className={activeSection === OrganisationConstants.REPORT_VALUATION_SECTION ? "admin-organisation-category-sub-subheading active link"
                                : "admin-organisation-category-sub-subheading link"} onClick={handleValuationReportsClick}>
                                Finvise valuations
                            </div>
                            <div className={activeSection === OrganisationConstants.REPORT_RICS_SECTION ? "admin-organisation-category-sub-subheading active link"
                                : "admin-organisation-category-sub-subheading link"} onClick={handleRICSReportsClick}>
                                RICS reports
                            </div>
                            <div className={activeSection === OrganisationConstants.REPORT_INSIGHT_SECTION ? "admin-organisation-category-sub-subheading active link"
                                : "admin-organisation-category-sub-subheading link"} onClick={handleInsightsReportsClick}>
                                Market insights
                            </div>
                            <div className={activeSection === OrganisationConstants.REPORT_ALL_SECTION ? "admin-organisation-category-sub-subheading active link"
                                : "admin-organisation-category-sub-subheading link"} onClick={handleAllReportsClick}>
                                All reports
                            </div>
                            <div className={activeSection === OrganisationConstants.PEOPLE_SECTION ? "admin-organisation-category-subheading active link"
                                : "admin-organisation-category-subheading link"} onClick={handlePeopleClick}>
                                Teams
                            </div>
                        </div>
                    </div>
                    <div className="admin-organisation-content col">
                        {activeSection === OrganisationConstants.SUMMARY_SECTION ?
                            <>
                                <div className="admin-orgs-content-title">
                                    {organisation && organisation.name}
                                </div>
                                <div className="summary-grids">
                                    <OrganisationReportSummary organisation={organisation} updateSection={updateSection.bind(this)}/>
                                    <OrganisationTeamSummary organisation={organisation} updateSection={updateSection.bind(this)} />
                                    {profile && organisation && (profile.role_id === 2 || profile.role_id === 3) && organisation.billing_method === 1 ?
                                        <CreditBasedBillingCard organisation={organisation}
                                                                role_id = {profile.role_id}
                                                                updateSection={updateSection.bind(this)}/>
                                        : null }
                                    {profile && organisation && profile.role_id === 3 && organisation.billing_method === 2 ?
                                        <UsageBasedBillingCard organisation={organisation}
                                                                updateSection={updateSection.bind(this)}/>
                                        : null }
                                </div>
                            </>
                            : null }
                        {activeSection === OrganisationConstants.REPORTS_SECTION ?
                            <>
                                <AdminReportOverviewGrid profile={profile} organisation={organisation} filter={filter}/>
                            </>
                            : null }
                        {activeSection === OrganisationConstants.REPORT_VALUATION_SECTION ?
                            <>
                                <ManagementReports report_type={1} heading="Valuation reports" orgId={org_id}
                                                   updateFilter={updateOverviewFilter.bind(this)}/>
                            </>
                            : null }
                        {activeSection === OrganisationConstants.REPORT_RICS_SECTION ?
                            <>
                                <ManagementReports report_type={2} heading="RICS reports" orgId={org_id}
                                                   updateFilter={updateOverviewFilter.bind(this)}/>
                            </>
                            : null }
                        {activeSection === OrganisationConstants.REPORT_INSIGHT_SECTION ?
                            <>
                                <ManagementReports report_type={3} heading="Market insight reports" orgId={org_id}
                                                   updateFilter={updateOverviewFilter.bind(this)}/>
                            </>
                            : null }
                        {activeSection === OrganisationConstants.REPORT_ALL_SECTION ?
                            <>
                                <ManagementReports report_type={0} heading="All reports" orgId={org_id}
                                                   updateFilter={updateOverviewFilter.bind(this)}/>
                            </>
                            : null }
                        {activeSection === OrganisationConstants.PEOPLE_SECTION ?
                            <>
                                <OrganisationPeople organisation={organisation} profile={profile}/>
                            </>
                            : null }
                        {activeSection === OrganisationConstants.CREDIT_SECTION ?
                            <>
                                <OrganisationCreditDetails organisation={organisation} updateSection={updateSection.bind(this)}/>
                            </>
                            : null }
                    </div>
                </div>
            </div>
        </>
    )
}

export default AdminOrganisation