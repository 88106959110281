import SplashScreen from "../components/SplashScreen";
import eye from "../assets/Eye.png";
import {useCallback, useEffect, useState} from "react";
import kapi from "../api/kratos";
import logo from "../assets/logo-splash.png";

const Login = () => {
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [showPassword, setShowPassword] = useState(false);
    const [flowResponse, setFlowResponse] = useState("");

    const InitLogin = () => {
        let response = kapi.init_login();
        response.then(response => {
            setFlowResponse(response.data);
        }, function(error){
            console.log(error);
        })
    }

    useEffect(() => {
        if (JSON.parse(localStorage.getItem('forgotten-password')) !== true) return;
        window.location.assign("/forgot-password");
    },[])

    const PageLoad = useCallback(() => {
        const params = new URLSearchParams(window.location.search);
        const flowId = params.get('flow');

        if (!flowId) {
            InitLogin();
        }
        else {
            const response = kapi.get_login(flowId);
            response.then(response => {
                setFlowResponse(response.data);
            }).catch(err => {
                console.log("Unable to retrieve login: " + err);
            });
        }

    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
        PageLoad();
    }, [PageLoad])

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    }

    return (
        <>
            <div className="login-page-container">
                <div className="login-container">
                    <div className="login-form-container">
                        <form name="login-form" action={flowResponse && flowResponse.ui.action}
                              method="POST">
                            <div>
                                {flowResponse && flowResponse?.ui?.messages?.map((msg, idx) => {
                                    return(
                                        <div className="messages">{msg.text}</div>
                                    )
                                })}
                            </div>
                            <input name="csrf_token" type="hidden"
                                   value={flowResponse && flowResponse.ui.nodes.find(n => n.attributes.name === 'csrf_token')?.attributes.value}/>
                            <div className="login-title-container">
                                <div className="login-title">Login</div>
                                <div className="login-title-logo">
                                    <img src={logo} alt="Finvise logo" />
                                </div>
                            </div>
                            <div className="field-input">
                                <div className="label">Email</div>
                                <div className="field-input-entry">
                                    <input className="login-input" name="password_identifier" type="email" value={email}
                                           onChange={(ev) => setEmail(ev.target.value)}
                                           placeholder="Email" required/>
                                </div>
                            </div>
                            <div className="field-input">
                                <div className="label">Password</div>
                                <div className="field-input-entry">
                                    <input placeholder="Set password" name="password"
                                           type={showPassword ? "text" : "password"} value={password}
                                           onChange={(ev) => setPassword(ev.target.value)}
                                           required/>
                                    <img src={eye} onClick={toggleShowPassword} alt="eye logo"
                                         className="eye"/>
                                </div>
                            </div>
                            <div className="field-input">
                                <div className="label">
                                    <a href="forgot-password">Forgot password</a>
                                </div>
                            </div>
                            <div className="field-input">
                                <button className="login-button" name="method" type="submit" value="password"
                                        disabled={!email || !password}>
                                    Login
                                </button>
                            </div>
                        </form>

                    </div>
                </div>
                <div className="splash-container">
                    <SplashScreen />
                </div>
            </div>
        </>
    )
}

export default Login