import {useEffect, useState} from "react";

import papi from "../api/pdf";

import valuation_api from "../api/valuation";
import address_formatter from "../utils/Address";
import date_formatter from "../utils/DateUtils";

import download_logo from "../assets/download.png";
import search_logo from "../assets/profile_search.png";

import PageNavigation from "./PageNavigation";
import GenericDialog from "./GenericDialog";
import CompareProperties from "./CompareProperties";


const RetailValuationHistory = () => {

    const PAGE_SIZE = 8
    const today = new Date();
    const diff = (24*60*60*1000) * 30; // 30 day difference
    const thirtyDaysAgo = today.getTime() - diff;

    const [showInsightInfo, setShowInsightInfo] = useState(594);

    const [valuations, setValuations] = useState([]);

    // Search Values
    const [searchString, setSearchString] = useState("");
    const [page, setPage] = useState(1)
    const [maxPage, setMaxPage] = useState()

    // Error Vars
    const [showDownloading, setShowDownloading] = useState(false);
    const [showErrorDialog, setShowErrorDialog] = useState(false);
    const [error, setError] = useState();
    const okButton = [{"text":"OK", "id": "1"}];

    const handleRowClick = (summary_id) => {
        if (!summary_id) return;
        valuation_api.deleteNotifications(summary_id)
        window.location.assign("/profile/review/"+summary_id)
    }

    useEffect(() => {
        const searchData = {
            "search": searchString,
            "type": 3,
            "page": page,
            "page_size": PAGE_SIZE
        }
        let response = valuation_api.getMyValuations(searchData);
        response.then(
            response => {
                setMaxPage(response.data.total_pages)
                setValuations(response.data.valuation_summaries);
            }
        )
    }, [page, searchString]);


    const handleOKDialogResult = (res) => {
        setShowErrorDialog(false);
    }

    const handleDownloadReport = (ev) => {
        setShowDownloading(true);
        let response = papi.getReport(ev.target.id);
        response.then(
            response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                let filename = address_formatter.getPdfFilename(valuations[ev.target.dataset.index]);
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
            }
        ).catch((err) => {
            setError("Failed to download report");
            setShowErrorDialog(true);
        }).finally(() => {
                setShowDownloading(false);
            }
        )
    }

    const handleSeeInsight = (valuation_id) => {
        const base = process.env.REACT_APP_WEB_URL;
        window.location.replace(base + "/valuation?id=" + valuation_id)
    }

    useEffect(() => {
        setDefaultBehaviour()
    }, [])

    function setDefaultBehaviour() {
        let container = document.getElementById("history-container");
        if (container){
            container.addEventListener("keypress", function (ev) {
                if (ev.key === "Enter") {
                    ev.preventDefault();
                    document.getElementById("search-icon").click();
                }
            })
        }
    }

    const nextPage = (ev) => {
        if (!page || !ev || page === maxPage) return;
        setPage(page + 1)
    }

    const previousPage = (ev) => {
        if (!page || !ev || page === 1) return;
        setPage(page - 1)
    }

    const numberPageClick = (page_number) => {
        if (!page_number) return;
        setPage(page_number)
    }

    return (
        <>
            <div className="valuation-history-retail-container" id="history-container">
                <div className="valuation-history-retail-grid-container">
                    <div className="valuation-grid-header">
                        <div className="valuation-grid-retail-search-container">
                            <div className="search">
                                <input type="text" placeholder="Search for property" value={searchString}
                                       onChange={(ev) => setSearchString(ev.target.value)}/>
                                <div>
                                    <img src={search_logo} alt="Magnifying Glass"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="valuation-grid-contents">
                        <div className="valuation-grid-retail-col-headers">
                            <div className="address col">Address</div>
                            <div className="date col">Date / Time </div>
                            <div className="insight col">Insight</div>
                            <div className="report col">Report</div>
                            <div className="mobile-date col">Date</div>
                            <div className="mobile-report col">Report</div>
                        </div>
                        <div className="valuation-grid-rows">
                            {valuations && valuations.map((detail, idx) => {
                                return (
                                    <>
                                        <div className="valuation-grid-retail-row link" >

                                            <div className="address col link" onClick={() => handleRowClick(detail.id)}>
                                                {address_formatter.formatSearchAddress(detail)}
                                            </div>
                                            <div className="date col" onClick={() => handleRowClick(detail.id)}>
                                                {date_formatter.display_date_time(detail.date*1000)}
                                            </div>
                                            {detail.date*1000 > thirtyDaysAgo ?
                                                <div className="insight col" data-index={idx}
                                                     onClick={() => handleSeeInsight(detail.id)}>
                                                    See insight
                                                </div> :
                                                <>
                                                    <div className="insight disabled col" data-index={idx} id={detail.id}
                                                         onMouseOver={(ev) => {if (parseInt(ev.target.id) === detail.id){setShowInsightInfo(detail.id)}}}
                                                         onMouseLeave={() => setShowInsightInfo(0)}>
                                                        See insight
                                                        {showInsightInfo === detail.id ?
                                                            <div className="insight-info-container">
                                                                <div className="insight-info-content">
                                                                    Your insights dashboard expires after 30 days.
                                                                    To see your insights always, join the monthly membership
                                                                    via your profile.
                                                                </div>
                                                            </div> : null }
                                                    </div>
                                                </>
                                            }
                                            <div className="report col" id={detail.id} data-index={idx}
                                                 onClick={handleDownloadReport}>
                                                PDF
                                            </div>
                                            <div className="mobile-date col" onClick={() => handleRowClick(detail.id)}>
                                                {date_formatter.display_date_short(detail.date*1000)}
                                            </div>
                                            <div className="mobile-report col" onClick={handleDownloadReport} id={detail.id} data-index={idx}>
                                                <img src={download_logo} alt="Download icon" />
                                            </div>
                                        </div>
                                    </>
                                )
                            })}
                        </div>
                    </div>
                    <div className="valuation-page-navigation-container">
                        <PageNavigation page={page}
                                        maxPage={maxPage}
                                        previousPage={previousPage.bind(this)}
                                        numberPageClick={numberPageClick.bind(this)}
                                        nextPage={nextPage.bind(this)}/>
                    </div>



                </div>
                <>
                    <div className="recent-searches-divider"/>
                    <div className="comparison-title-container reports">
                        <div className="comparison-title">
                            Compare your searches
                        </div>
                    </div>
                    <CompareProperties />
                </>
            </div>
            {showDownloading ? <GenericDialog title="Download" text="Your report is being prepared"/>
                : null}
            {showErrorDialog ?
                <GenericDialog title="Valuation - Error" text={error} buttons={okButton}
                               result={handleOKDialogResult.bind(this)} /> : null }
        </>
    )
}

export default RetailValuationHistory