import {useEffect, useState} from "react";

const ValuationStatusPill = (props) => {
    const status_id = props.status_id;
    const status_name = props.status_name;
    const [statusClass, setStatusClass] = useState();
    function updateClassName() {
        switch (status_id){
            case 1:
                setStatusClass("pending");
                break;
            case 4:
                setStatusClass("ready");
                break;
            case 2:
            case 3:
                setStatusClass("review");
                break;
            case 5:
                setStatusClass("cancelled");
                break;
        }
    }

    useEffect(() => {
        updateClassName()
    }, [status_id])
    return (
        <>
            <div className={"status-container " + statusClass}>
                <div className="dot"></div>
                <div className="text">{status_name}</div>
            </div>
        </>
    )
}

export default ValuationStatusPill