import MainHeader from "../components/MainHeader";
import person_logo from "../assets/person.png";
import AdminUsersGrid from "../components/AdminUsersGrid";
import {useEffect, useState} from "react";
import AdminNewUser from "../components/AdminNewUser";

const AdminDashboard = (props) => {
    const [showNewUser, setShowNewUser] = useState(false);
    const [profile, setProfile] = useState();

    const updateShowNewUser = (show) => {
        setShowNewUser(show);
    }

    const updateProfile = (profile) => {
        setProfile(profile);
    }

    useEffect(() => {
        // Don't let a non admin navigate here by hand
        if (profile && profile.role_id !== 3)
            window.location.assign("/");
    }, [profile])

    return (
        <>
            <MainHeader updateProfile={updateProfile.bind(this)}/>
            <div className="admin-dashboard-page-container">
                <div className="admin-dashboard-container">
                    <div className="admin-dashboard-sidemenu col">
                        <div className="admin-dashboard-menu-title">
                            Admin view
                        </div>
                        <div className="admin-menu-category-title">
                            INTERNAL
                        </div>
                        <div className="admin-menu-category-item active">
                            <img src={person_logo} alt="Person logo" />
                            <span>FINVISE</span>
                        </div>
                    </div>
                    <div className="admin-dashboard-content col">
                        {showNewUser ?

                            <>
                                <AdminNewUser update={updateShowNewUser.bind(this)}/>
                            </> :
                            <>
                                <div className="admin-dashboard-content-title">Finvise</div>
                                <AdminUsersGrid update={updateShowNewUser.bind(this)}/>
                            </>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default AdminDashboard