import {useEffect, useState} from "react";
const AdminDropDownList = (props) => {
    const placeholder = props.placeholder;
    const handleSelectedItem = props.handleSelectedItem;
    const hasSearch = props.hasSearch;
    const [searchString, setSearchString] = useState();
    const [items, setItems] = useState();
    const [showList, setShowList] = useState(false);

    function handleDropdownClick() {
        setShowList(!showList);
    }

    function handleDropdownItemClick(ev) {
        if (!ev.target.id) return;
        setShowList(false);
        handleSelectedItem(ev.target.id);
    }

    useEffect(() => {
        if (searchString === undefined) return;
        let newItems = [];
        if (searchString && searchString != "") {
            for (let i = 0; i < items.length; i++) {
                if (items[i].name.toLowerCase().includes(searchString.toLowerCase())){
                    newItems.push(items[i]);
                }
            }
            setItems(newItems);
        }
        else {
            setItems(props.items);
        }
    }, [searchString])

    useEffect(() => {
        if (!props.items) return;
        setItems(props.items);
    }, [props.items])

    return (
        <>
            <div className="dropdown-list-container">
                <div className="dropdown-list-selector link" onClick={handleDropdownClick}>
                    <div className="dropdown-list-placeholder">
                        {placeholder}
                    </div>
                    <div className="dropdown-list-selector-arrow">
                        >
                    </div>
                </div>
                {showList ?
                    <div className="dropdown-list-items-container">
                        {hasSearch ?
                            <>
                                <div className="dropdown-list-item-search link">
                                    <input type="text" value={searchString} placeholder={props.searchPlaceholder}
                                           onChange={(ev) => setSearchString(ev.target.value)}/>
                                </div>
                            </>
                            : null }
                        {items && items.map((item, idx) => {
                            return (
                                <>
                                    <div className="dropdown-list-item link" id={item.id} onClick={handleDropdownItemClick}>
                                        {item.name}
                                    </div>
                                </>
                            )
                        })}
                    </div>
                : null }
            </div>
        </>
    )
}

export default AdminDropDownList