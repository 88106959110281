import add_logo from "../assets/group_add.png";
import close_logo from "../assets/Close.png";
const RequestAdminPopup = (props) => {
    const update = props.update;

    const handleCloseClick = (ev) => {
        if (!ev) return;
        update(false);
    }

    return (
        <>
            <div className="request-credit-popup-container">
                <div className="request-admin-popup-header">
                    <img src={add_logo} alt="Add admin icon" className="add"/>
                    <img src={close_logo} alt="Close icon" className="close link" onClick={handleCloseClick}/>
                </div>
                <div className="request-admin-popup-text">
                    Add credits
                </div>
                <div className="request-admin-popup-text">
                    To add credits to this account, please contact
                    <a href="mailto:support@finvise.com">support@finvise.com</a>
                </div>
            </div>
        </>
    )
}

export default RequestAdminPopup