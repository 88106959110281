import {useEffect, useState} from "react";
import profile_api from "../../api/profile";
import GenericDialog from "../GenericDialog";
import cloud from "../../assets/upload_cloud.png";
import ImageUtils from "../../utils/ImageUtils";
import cancel from "../../assets/cancel_white.png";
import {useNavigate} from "react-router-dom";

const SignaturePanel = (props) => {
    const profile = props.profile;
    const navigate = useNavigate();

    const [sign, setSign] = useState([])
    const [base64, setBase64] = useState("")
    const [updated, setUpdated] = useState(false)

    // Error Handling
    const [showDialog, setShowDialog] = useState(false);
    const [dialog, setDialog] = useState("");
    const okButton = [{"text":"OK", "id": "1"}];

    const handleSaveClick = () => {
        if (!updated || sign === []) return;
        let _body = {
            "signature": sign,
        }
        let response = profile_api.postSignature(_body)
        response.then(
            response => {
                setDialog("Upload successful");
                setShowDialog(true);
                getValuerSignature()
            }
        ).catch(
            err => {
                setDialog(err.response.data.description);
                setShowDialog(true);
            }
        )
    }

    const getValuerSignature = () => {
        let response = profile_api.getSignature()
        response.then(
            response => {
                let _file = {name: "signature.png", base64: response.data.data, caption: ""}
                setSign([_file])
                setBase64(_file.base64)
            }
        ).catch(
            err => {
                console.log("Couldn't find users signature")
            }
        )
    }

    const handleClearClick = () => {
        setSign([])
        setBase64("")
    }

    const handleDragOver = (ev) => {
        ev.preventDefault()
    }

    const handleOKDialogResult = (res) => {
        setShowDialog(false);
    }

    useEffect(() => {
        getValuerSignature()
    },[])

    const handleDrop = async (ev) => {
        ev.preventDefault()
        let _files = ev.dataTransfer.files
        let _temp_sign = []
        let _temp_base64 = ""
        if (_files[0].type === "image/png" || _files[0].type === "image/jpeg" && _files[0].size < 400000) {
            setUpdated(true)
            let base64_image = await ImageUtils.convertBase64(_files[0]);
            let _file = {name: _files[0].name, base64: base64_image, caption: ""}
            _temp_sign.push(_file)
            let _temp_base64_array = base64_image.split(",")
            _temp_base64 = _temp_base64_array[1]
        }
        setSign(_temp_sign)
        setBase64(_temp_base64)
    }

    return (
        <div className="postnominals-container">
            <div className="valuer-appendix-title">Valuer’s Signature* </div>
            <div className="valuer-appendix-description">Your image must be less than 400kb.</div>
            <div className="valuer-appendix-description">Images must be png or jpg format only.</div>
            <div className="valuer-dropzone" onDragOver={handleDragOver} onDrop={handleDrop}>
                <img src={cloud} alt="upload cloud"/>
                <div>Drag and Drop files to Upload</div>
            </div>
            {sign && sign.map((file, idx) => {
                return(
                    <>
                        <div className="valuer-appendix-file-row static">
                            <div className="valuer-appendix-file-name">
                                {file.name}
                            </div>
                            <div id={idx.toString()} className="valuer-appendix-file-delete link"
                                 onClick={(ev) => handleClearClick(ev)}/>
                        </div>
                    </>

                )
            })}
            {base64 !== "" ?
                <div className="valuer-signature-container">
                    <img src={`data:image/png;base64,${base64}`} alt="signature"/>
                </div>
                :null}
            <div className="signature-buttons-container">
                <div className="field-input">
                    <button className="postnominals-save-button" onClick={handleClearClick}>
                        Clear
                    </button>
                </div>
                <div className="field-input">
                    <button className="postnominals-save-button" onClick={handleSaveClick}>
                        Save
                    </button>
                </div>
            </div>
            {showDialog
                ? <GenericDialog title="Signature Upload" text={dialog} buttons={okButton}
                                 result={handleOKDialogResult.bind(this)} />
                : null
            }
        </div>
    )
}

export default SignaturePanel