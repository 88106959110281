import React, {useEffect, useState} from "react";
import "chart.js/auto";
import { Line } from "react-chartjs-2";

const InsightHistoryLineChart = (props) => {

    const propsData = props.data;

    const [yearLabels, setYearLabels] = useState([]);
    const [propertyValues, setPropertyValues] = useState([]);
    const [areaValues, setAreaValues] = useState([]);

    const GetYearLabels = () => {
        let _d = []
        for (let i=0; i<propsData.length;i++) {
            _d.push(propsData[i].year)
        }
        setYearLabels(_d)
    }

    const GetPropertyValues = () => {
        let _d = []
        for (let i=0; i<propsData.length;i++) {
            _d.push(propsData[i].property_value)
        }
        setPropertyValues(_d)
    }

    const GetAreaValues = () => {
        let _d = []
        for (let i=0; i<propsData.length;i++) {
            _d.push(propsData[i].area_value)
        }
        setAreaValues(_d)
    }

    useEffect(() => {
        if (propsData === undefined) return
        GetYearLabels()
        GetPropertyValues()
        GetAreaValues()
    }, [propsData]);

    const data ={
        labels: yearLabels,
        datasets: [{
            label: "Property values",
            backgroundColor: "#9B3D8C",
            borderColor: "#9B3D8C",
            data: propertyValues,
        },
            {
                label: "Average value for similar properties in the area",
                backgroundColor: "#4975A1",
                borderColor: "#4975A1",
                data: areaValues,
            }]
    }

    const options = {
        elements: {
          point: {
            pointStyle: false,
          },
          line: {
            borderWidth: 6,
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                grid: {
                    display: false,
                    color: "#4B6075",
                },
            },
            xAxes: {
                gridLines: {color: "#4B6075"},
                display: false
            },
            y: {
                grid: {
                    display: true,
                    color: "#31588A",
                    borderColor: "#31588A",
                    lineWidth: 2,
                },
                border: {
                    display: false,
                },
                ticks: {
                    callback: function(value) {
                        if (value < 1000) {
                            return "£" + value;
                        } else if (value >= 1000 && value < 1000000) {
                            return "£" + value/1000 + "k";
                        } else if (value >= 1000000) {
                            return "£" + value/1000000 + "m";
                        }
                    }
                }
            },
            yAxes: {
                gridLines: {color: "#4B6075"},
                display: false,
            },
        },
        plugins: {
            legend: {
                display: false,
                position: "bottom",
                labels: {
                    color: "#ffffff",
                    padding: 10
                }
            }
        },
        layout: {
            padding: {
                left: 50
            }
        }
    }

    return (
        <div className="insight-history-graph-container">
            <Line data={data} options={options}/>
        </div>
    );
};

export default InsightHistoryLineChart;