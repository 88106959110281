import axiosInstance from "../http/axios_instance"
const http = axiosInstance("/protected/admin",  null, "/dashboard")

const getValuers = () => http.get("valuer");
const changeValuerStatus = (valuer_id, status_id) => http.put("valuer/" + valuer_id + "/" + status_id);
const getValuerReports = (body) => http.post("valuer_reports", body);

export default {
    getValuers,
    changeValuerStatus,
    getValuerReports
}