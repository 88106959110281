import {useEffect, useState} from "react";
import admin_api from "../../api/admin/admin_valuer";

const RICSHubValuersGrid = (props) => {
    const [valuers, setValuers] = useState();
    const showValuer = props.showValuer;

    const getValuers = () => {
        let response = admin_api.getValuers();
        response.then(
            response => {
                setValuers(response.data.valuers);
            }
        ).catch(err => {
            console.log(err)
        })
    }

    useEffect(() => {
        getValuers()
    }, [])

    const handleValuerClick = (ev) => {
        if (!ev.target.id) return;
        showValuer(valuers[parseInt(ev.target.id)]);
    }

    return (
        <>
            <div className="rics-hub-valuer-grid-container">
                <div className="rics-valuer-grid-headings">
                    <div className="rics-valuer-grid-col col">
                        VALUER
                    </div>
                    <div className="rics-valuer-grid-col valuer col">
                        STATUS
                    </div>
                </div>
                <div className="rics-valuer-grid-rows">
                    {valuers && valuers.map((valuer, idx) => {
                        return (
                            <>
                            {valuer.firstname ?
                                <div className="rics-valuer-grid-row link" onClick={handleValuerClick} id={idx}>
                                    <div className="rics-valuer-grid-col col" id={idx}>{valuer.firstname.substring(0,1)}. {valuer.lastname}</div>
                                    <div className="rics-valuer-grid-col valuer col" id={idx}>{valuer.status === 1 ? "Active" : "Inactive"}</div>
                                </div>
                                : null }
                            </>
                        )
                    })}
                </div>
            </div>
        </>
    )
}

export default RICSHubValuersGrid