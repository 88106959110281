import axiosInstance from "./http/axios_instance"
const http = axiosInstance("/protected",  null, "/forbidden")

const getPropertyCoords = (property_id) => http.get("/property_coordinates/" + property_id);
const getPropertyID = (postcode, address) => http.get("/have_property_data/" + postcode + "/" + address);
const validatePostcode = (postcode) => http.get("/validate_postcode/" + postcode);

export default {
    getPropertyCoords,
    getPropertyID,
    validatePostcode
}