import React, {useEffect, useState} from "react";
import arrow from "../assets/arrow_forwards.png";
import InsightAreaBarChart from "./InsightAreaBarChart";
import ReactActivityCard from "./RecentActivityCard";




const InsightRecentActivityScroller = (props) => {

    const title = props.title;
    const salesData = props.salesData;
    const rentData = props.rentData;
    const salesAvgData = props.salesAvgData;
    const predictedValue = props.predictedValue;

    const [sectionName, setSectionName] = useState();
    const [tabMenus, setTabMenus] = useState()
    const [avgTitle, setAvgTitle] = useState();
    const [avgData, setAvgData] = useState();
    const [avgRadius, setAvgRadius] = useState(0)

    const [items, setItems] = useState("");
    const [page, setPage] =useState(1);
    const [pageNums, setPageNums] = useState([]);
    const [disableScrollLeft, setDisableScrollLeft] = useState(true);
    const [disableScrollRight, setDisableScrollRight] = useState(false);
    const currency = "£";

    const GetRecentSold = () => {
        setItems(salesData)
    }

    const GetAvgPriceByArea = () => {
        setAvgData(salesAvgData)
    }

    const GetAvgRentYield = () => {
        setAvgData(rentData)
    }

    const decrementPage = () => {
        if (page===1) return;
        let dec = page-1;
        setPage(dec);
        setDisableScrollRight(false)
    }

    // needed
    const incrementPage = () => {
        if (page === pageNums.length) return;
        if (page === (items.length - 2)) return;
        let inc = page+1;
        setPage(inc);
        setDisableScrollLeft(false)
    }

    const PageReset = () => {
        setPage(1)
        if (items.length >= 4) {
            setDisableScrollRight(false)
        } else {
            setDisableScrollRight(true)
        }
        setDisableScrollLeft(true)
    }

    useEffect(() => {
        GetAvgPriceByArea()
    },[salesAvgData])

    useEffect(() => {
        GetAvgRentYield()
    },[rentData])

    useEffect(() => {
        if (!props.setSelection) return;
        setSectionName(props.setSelection);

    }, [props.setSelection]);

    useEffect(() => {
        if (!props.tabs) return;
        let menus = props.tabs;

        setTabMenus(menus);
    }, [props.tabs]);

    useEffect(() => {
        if (!tabMenus) return;
        if (!sectionName) return;

        switch(sectionName){
            case "Recently sold":
                GetRecentSold()
                PageReset()
                break;
            case "Average prices by area":
                GetAvgPriceByArea()
                PageReset()
                break;
            case "Average rental income":
                GetAvgRentYield()
                PageReset()
                break;
        }
    },[sectionName,title])

    useEffect(() => {
        if(!sectionName) return;
        if (sectionName === "Average prices by area") {
            setAvgTitle("Average price of similar properties in nearby areas.")
        } else if (sectionName === "Average rental income") {
            setAvgTitle("Average rental income of similar properties in nearby areas.")
        }
    },[sectionName])

    // needed
    useEffect(() => {
        if (page === (items.length - 2) || items.length <= 3) setDisableScrollRight(true);
        if (page===1) {
            setDisableScrollLeft(true);
            setDisableScrollRight(false);
        }
    },[page, items])

    useEffect(() => {
        if (!avgData) return;
        let radius = 0;
        let n = 0;
        for (let i=0; i< avgData.length; i++) {
            if (avgData[i].radius) {
                radius = radius + (avgData[i].radius) * 10
                n = n + 1;
            }
        }
        setAvgRadius(radius/n)
    },[avgData])

    return (
        <>
            {sectionName === "Average prices by area" || sectionName === "Average rental income" ?
                <div className="insight-recent-cards-container-background">
                    {avgData && avgData.length > 0 ?
                        <div className="insight-recent-area-average-container">
                            <div className="insight-recent-area-details-container">
                                <div className="insight-recent-area-title-container">
                                    <div className="insight-recent-area-title">
                                        {avgTitle}
                                    </div>
                                </div>
                                <div className="insight-recent-area-cards-legend-container">
                                    <div className="insight-recent-area-cards-container">
                                        {avgData && avgData.map((card, idx) => {
                                            return(
                                                <div className="insight-recent-area-card" key={idx}>
                                                    <div className="insight-recent-area-card-name">
                                                        {card.name}
                                                    </div>
                                                    <div className="insight-recent-area-card-value">
                                                        £{parseInt(card.similar_properties_avg).toLocaleString()}
                                                    </div>
                                                    {sectionName === "Average prices by area" && idx !== 0 ?
                                                        <div className="insight-recent-area-card-difference">
                                                            {-(((avgData[0].similar_properties_avg / parseInt(card.similar_properties_avg))*100)-100).toFixed(2)}%
                                                        </div>
                                                        :null}
                                                </div>
                                            )
                                        })}
                                    </div>
                                    {sectionName === "Average prices by area" ?
                                        <div className="insight-recent-area-legend-container">
                                            <div className="insight-recent-area-legend-item-container">
                                                <div className="insight-recent-area-legend-color blue"/>
                                                <div className="insight-recent-area-legend-text">Similar properties</div>
                                            </div>
                                            <div className="insight-recent-area-legend-item-container">
                                                <div className="insight-recent-area-legend-color pink"/>
                                                <div className="insight-recent-area-legend-text">All properties</div>
                                            </div>
                                        </div>
                                        :null}
                                </div>
                                <div className="insight-recent-area-text-container">
                                    This data is based on a sample of 100 similar* properties within {avgRadius.toFixed(2)} miles
                                    radius of the neighbourhood.
                                </div>
                                <div className="insight-recent-area-text-container small">
                                    <i>*We have considered the location, total floor area, property type, tenure type, built
                                        year and many other attributes while identifying similar properties.</i>
                                </div>
                            </div>
                            <div className="insight-recent-area-graph-card">
                                <InsightAreaBarChart data={avgData} name={sectionName}/>
                            </div>
                        </div>
                        :
                        <div className="insight-recent-area-average-container empty">
                            <div className="insight-recent-area-empty-container">
                                <div className="insight-recent-area-empty-text">
                                    Our data providers currently do not have any information for this property.
                                </div>
                            </div>
                        </div>
                    }
                </div>
                :
                <div className="insight-recent-cards-container-background">
                    {items.length > 0 ?
                        <>
                            <div className="insight-recent-buttons-container">
                                <div className="insight-recent-button-container left">
                                    <button className={disableScrollLeft ? "insight-recent-cards-scroll-button disable" :
                                        "insight-recent-cards-scroll-button left"} onClick={decrementPage}>
                                        <img src={arrow} alt={">"} style={{rotate: 180 + 'deg'}}/>
                                    </button>
                                </div>
                                <div className="insight-recent-button-container right">
                                    <button className={disableScrollRight ? "insight-recent-cards-scroll-button disable" :
                                        "insight-recent-cards-scroll-button right"} onClick={incrementPage}>
                                        <img src={arrow} alt={">"}/>
                                    </button>
                                </div>
                            </div>
                            <div className="insight-recent-cards-width-container">
                                <div className="insight-recent-cards-container" style={{marginLeft: ((page -1) * -449) + "px"}}>
                                    {items && items.map((card, idx) => {
                                        return (
                                            <ReactActivityCard key={"insight-recent-card-" + idx}
                                                               card={card}
                                                               currency={currency}/>
                                        )
                                    })}
                                </div>
                            </div>
                        </>
                        :
                        <div className="insight-recent-area-average-container empty">
                            <div className="insight-recent-area-empty-container">
                                <div className="insight-recent-area-empty-text">
                                    Our data providers currently do not have any information for this property.
                                </div>
                            </div>
                        </div>
                    }
                </div>
            }

        </>
    )
}

export default InsightRecentActivityScroller