import val_api from "../../api/valuation";
import {useEffect, useState} from "react";

const TabTypeSelector = (props) => {

    const [selectedType, setSelectedType] = useState(null);
    const [evaluationTypes, setEvaluationTypes] = useState([])

    const setValuationType = props.setType

    const getValuationTypes = () => {
        let response = val_api.getValuationTypes();
        response.then(response => {
            setEvaluationTypes(response.data.types);
            if (response.data.types && response.data.types.length === 3) {
                setEvaluationTypes([response.data.types[1], response.data.types[0], response.data.types[2]]);
                UpdateType(response.data.types[1]);
            }
        })
    }

    const UpdateType = (_type) => {
        setSelectedType(_type)
        setValuationType(_type)
    }

    useEffect(() => {
        getValuationTypes();
    }, [])

    return (
        <div className="tab-type-selector">
            {evaluationTypes && evaluationTypes.map((type, idx) => {
                return (
                    <div className={selectedType === type ? "tab-type active link" : "tab-type link"}
                         onClick={() => UpdateType(type)}>
                        {type.name}
                    </div>
                )
            })}
        </div>
    )
}

export default TabTypeSelector;