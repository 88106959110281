import axiosInstance from "../http/axios_instance"
const http = axiosInstance("/protected/admin",  null, "/dashboard")

const getPeople = (org_id) => http.get("/people/" + org_id);

const getAllTeamMembers = (org) => org ?
    http.get("/team_members/" + org.id) :
    http.get("/team_members");

export default {
    getPeople,
    getAllTeamMembers
}