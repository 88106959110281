import {useEffect, useState} from "react";
import bin_logo from "../assets/bin.png";
import resurrect_logo from "../assets/ressurect.png";
import people_api from "../api/admin/admin_people";
import orgadmin_people_api from "../api/orgadmin/orgadmin_people";
import team_api from "../api/admin/admin_teams";
import orgadmin_team_api from "../api/orgadmin/orgadmin_teams";
import orgadmin_user_status_api from "../api/orgadmin/orgadmin_user";
import OrganisationAddMembers from "./OrganisationAddMembers";
import RequestAdminPopup from "./RequestAdminPopup";
import roles from "../models/Roles";
import teams from "../models/Teams";

const OrganisationPeople = (props) => {
    const profile = props.profile;
    const organisation = props.organisation;
    const [admins, setAdmins] = useState();
    const [people, setPeople] = useState();
    const [teamName, setTeamName] = useState();
    const [showGrids, setShowGrids] = useState(true);
    const [showAddMembers, setShowAddMembers] = useState(false);
    const [showRequestPopup, setShowRequestPopup] = useState(false);
    const [role, setRole] = useState();
    const [team, setTeam] = useState();

    const getPeople = () => {
        if (!profile) return;
        let response = null;
        if (profile.role_id === roles.ROLE_ADMIN) {
            response = people_api.getPeople(organisation.id);
        }
        if (profile.role_id === roles.ROLE_ORGANISATION_ADMIN) {
            response = orgadmin_people_api.getPeople();
        }
        response.then(
            response => {
                if (response.data.people.teams.length > 0){
                    // Use first entry from list for admins
                    setAdmins(response.data.people.teams[0]);
                    // Use all other teams as normal
                    const rest = response.data.people.teams.slice(1,response.data.people.teams.length)
                    setPeople(rest);
                }

            }
        )
    }

    const hideAddMember = () => {
        getPeople();
        setShowAddMembers(false);
        setShowGrids(true);
    }

    const handleRemoveUser = (ev) => {
        if (!ev) return;
        const body = {
            "user_email": ev.target.id,
            "status": 2
        }
        let response = orgadmin_user_status_api.updateUserStatus(body)
        response.then(
            response => {
                getPeople()
            }
        )
    }
    const handleResurrectUser = (ev) => {
        if (!ev) return;
        const body = {
            "user_email": ev.target.id,
            "status": 1
        }
        let response = orgadmin_user_status_api.updateUserStatus(body)
        response.then(
            response => {
                getPeople()
            }
        )
    }

    const handleAddUserClick = (ev) => {
        if (!ev) return;
        if (!people) return;
        setTeam(ev.target.id);
        setRole(roles.ROLE_USER);
        setShowGrids(false);
        setShowAddMembers(true);
    }
    const handleAddAdminClick = (ev) => {
        if (!ev) return;
        if (!admins) return;
        setTeam(admins.id);
        setRole(roles.ROLE_ORGANISATION_ADMIN);
        setShowGrids(false);
        setShowAddMembers(true);
    }

    const handleRequestAdminClick = () => {
        setShowRequestPopup(true);
    }

    const updateShowPopup = (show) => {
        setShowRequestPopup(show);
    }

    const handleNewTeamClick = () => {
        if (!teamName) return;
        if (!profile) return;

        const body = {
            "name": teamName
        }
        let response = null;
        if (profile.role_id === roles.ROLE_ADMIN){
            response = team_api.createTeam(organisation.id, body);
        }
        if (profile.role_id === roles.ROLE_ORGANISATION_ADMIN){
            response = orgadmin_team_api.createTeam(body);
        }

        response.then(
            response => {
                setTeamName(undefined);
                getPeople();
            }
        )
    }

    useEffect(() => {
        getPeople()
    }, [])

    return (
        <>
            <div className="people-container">
                {showGrids ?
                    <>
                        <div className="people-title">
                            {organisation && organisation.name} People
                        </div>
                        <div className="people-admins-grid">
                            <div className="people-admins-grid-title-container">
                                <div className="people-admins-grid-title">Organisation Admins</div>
                                {profile && profile.role_id === roles.ROLE_ADMIN ?
                                    <>
                                        <div className="people-admins-grid-add-button link" onClick={handleAddAdminClick}>
                                            Add new admin
                                        </div>
                                    </>
                                    : null }
                                {profile && profile.role_id === roles.ROLE_ORGANISATION_ADMIN ?
                                    <>
                                        <div className="people-admins-grid-add-button link" onClick={handleRequestAdminClick}>
                                            Request new admin
                                        </div>
                                    </>
                                    : null }
                                {showRequestPopup ? <RequestAdminPopup update={updateShowPopup.bind(this)} />
                                    : null }

                            </div>
                            <div className="people-admins-grid-col-headers">
                                <div className="name col">Name</div>
                                <div className="email col">Email</div>
                                <div className="status col">Status</div>
                                <div className="valuations col">Valuations this month</div>
                                <div className="action col"></div>
                            </div>
                            <div className="people-admins-grid-rows">
                                {admins && admins.team_members && admins.team_members.map((person, idx) => {
                                    return (
                                        <>
                                            <div className="people-admins-grid-row">
                                                <div className="name col">
                                                    {!person.firstname ? "{pending}" : person.firstname + " " + person.lastname}
                                                </div>
                                                <div className="email col">{person.email}</div>
                                                <div className="status col">{person.status === 1 ? "Active" : "Inactive"}</div>
                                                <div className="valuations col">{person.valuations_this_month}</div>
                                                <div className="action col">
                                                    {person.status === 1 ?
                                                        <img src={bin_logo} alt="Bin icon" className="link"
                                                             onClick={handleRemoveUser} id={person.email}/>
                                                        :
                                                        <img src={resurrect_logo} alt="Resurrect icon" className="link"
                                                             onClick={handleResurrectUser} id={person.email}/>
                                                    }
                                                </div>
                                            </div>
                                        </>
                                    )
                                })}
                            </div>
                        </div>
                        <div className="people-teams-grid-container">
                            <div className="people-teams-grid-title">Organisation Teams</div>
                            {people && people.map((team, idx) => {
                                return (
                                    <>
                                        <div className="people-team-grid">
                                            <div className="people-team-grid-title-container">
                                                <div className="people-team-grid-title">{team.name}</div>
                                                {team.id !== teams.TEAM_RETAIL ? <div className="people-team-grid-add-button link" onClick={handleAddUserClick}
                                                        id={team.id}>
                                                    Add team member
                                                </div> : null }
                                            </div>
                                            <div className="people-team-grid-col-headers">
                                                <div className="name col">Name</div>
                                                <div className="email col">Email</div>
                                                <div className="status col">Status</div>
                                                <div className="valuations col">Valuations this month</div>
                                                <div className="action col"></div>
                                            </div>
                                            <div className="people-team-grid-rows">
                                                {team.team_members && team.team_members.map((person, idx) => {
                                                    return (
                                                        <>
                                                            <div className="people-admins-grid-row">
                                                                <div className="name col">
                                                                    {!person.firstname ? "{pending}" : person.firstname + " " + person.lastname}
                                                                </div>
                                                                <div className="email col">{person.email}</div>
                                                                <div className="status col">{person.status === 1 ? "Active" : "Inactive"}</div>
                                                                <div className="valuations col">{person.valuations_this_month}</div>
                                                                <div className="action col">
                                                                    {person.status === 1 ?
                                                                        <img src={bin_logo} alt="Bin icon" className="link"
                                                                             onClick={handleRemoveUser} id={person.email}/>
                                                                        :
                                                                        <img src={resurrect_logo} alt="Resurrect icon" className="link"
                                                                             onClick={handleResurrectUser} id={person.email}/>
                                                                    }                                                                </div>
                                                            </div>
                                                        </>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </>
                                )
                            })}
                            <div className="people-teams-new-container">
                                <div className="people-teams-new-label">Create new team</div>
                                <div className="people-teams-new-fields">
                                    <div className="name">
                                        <input type="text" value={teamName}
                                               onChange={(ev) => setTeamName(ev.target.value)}
                                               placeholder="Type new team name"/>
                                    </div>
                                    <div className="button link" onClick={handleNewTeamClick}>Save</div>
                                </div>
                            </div>
                        </div>
                    </>
                    : null }
                {showAddMembers ?
                    <>
                        <OrganisationAddMembers role={role} team={team} hide={hideAddMember.bind(this)} profile={profile}/>
                    </>
                : null}

            </div>
        </>
    )
}

export default OrganisationPeople