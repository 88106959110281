import address_api from "../api/address";
import format from "./Address";

const handlePostcodeLookup = (displayAddress, setShowListAddresses, setDisplayAddress,
                              setSelectedProperty, setListAddresses, setOnlyPostcodes) => {
    if (!displayAddress){
        setShowListAddresses(false);
        return;
    }
    if (displayAddress.length >= 11) {
        setDisplayAddress("")
    }
    setShowListAddresses(false);
    setSelectedProperty(null);
    SearchAddress(displayAddress, setShowListAddresses, setListAddresses, setOnlyPostcodes)
}

const isValidPostcode = (postcode)  => {
    if (!postcode) return
    let regexp = /^[A-Z]{1,2}[0-9RCHNQ][0-9A-Z]?\s?[0-9][ABD-HJLNP-UW-Z]{2}$|^[A-Z]{2}-?[0-9]{4}$/;
    return (regexp.test(postcode.replace(" ", "")))
}

const SearchAddress = (_address, setShowListAddresses, setListAddresses, setOnlyPostcodes) => {
    if (!_address) return
    setShowListAddresses(true);
    let response = address_api.searchAddress(_address);
    response.then(response => {
        setListAddresses(response.data.properties);
        if (response.data.properties.length > 0) setOnlyPostcodes(response.data.postcodes_only);
    })
}

const selectAddress = (id, listAddresses, setDisplayAddress, setSelectedProperty, setShowListAddresses, setListAddresses) => {
    if (!listAddresses) return;
    setDisplayAddress(format.formatSearchAddress(listAddresses[id]));
    setSelectedProperty(listAddresses[id]);
    setShowListAddresses(false);
    setListAddresses(null);

    console.log(listAddresses[id])
}

const selectPostcode = (id, listAddresses, setDisplayAddress, setListAddresses, setShowListAddresses, setOnlyPostcodes) => {
    if (!listAddresses) return;
    setDisplayAddress(listAddresses[id].postcode.toUpperCase());
    SearchAddress(listAddresses[id].postcode.toUpperCase(), setShowListAddresses, setListAddresses, setOnlyPostcodes)
    setListAddresses([]);
}


export default {
    handlePostcodeLookup,
    isValidPostcode,
    SearchAddress,
    selectAddress,
    selectPostcode
}