import roles from "../models/Roles";
import UserMembershipPanel from "./UserMembershipPanel";
import PostNominalsPanel from "./valuer/PostNominalsPanel";
import profapi from "../api/profile"
import auth from "../api/auth";
import SignaturePanel from "./valuer/SignaturePanel";

const ProfilePanel = (props) => {
    const profile = props.profile;

    const handleResetClick = () => {
        localStorage.setItem('forgotten-password', JSON.stringify(true));
        window.location.assign("/logout");
    }

    const handleDeleteClick = () => {
        alert("Deleting account");
        auth.logout()
        profapi.deleteProfile();
    }

    return (
        <>
            <div className="profile-panel">
                <div className="profile-panel-container left col">
                    <div className="field-input">
                        <div className="label">Full name</div>
                        <div className="field-input-entry">
                            <input placeholder="First name" name="firstname"
                                   type="text" value={profile && profile.firstname + " " + profile.lastname} disabled={true}/>
                        </div>
                        <div className="label">Email address</div>
                        <div className="field-input-entry">
                            <input placeholder="First name" name="firstname"
                                   type="text" value={profile && profile.email} disabled={true}/>
                        </div>
                        <div className="profile-form-text">
                            To change your details, please contact your admin representative.
                        </div>
                        <div className="label">Password</div>
                        <div className="field-input">
                            <button className="profile-reset-button" onClick={handleResetClick}>
                                Change password
                            </button>
                        </div>
                        {profile && profile.role_id === roles.ROLE_RETAIL_USER ?
                            <>
                                <div className="label">Account</div>
                                <div className="field-input">
                                    <button className="profile-delete-button" onClick={handleDeleteClick}>
                                        Delete
                                    </button>

                                </div>
                            </>
                            : null
                        }
                    </div>
                </div>

                {profile && profile.role_id === roles.ROLE_RETAIL_USER ?
                    <>
                        <div className="profile-panel-container right col">
                            <UserMembershipPanel profile={profile} />
                        </div>
                    </>
                    : null
                }
                {profile && profile.role_id === roles.ROLE_VALUER ?
                    <>
                        <div className="profile-panel-container right col">
                            <PostNominalsPanel profile={profile} />
                        </div>
                        <div className="profile-panel-container right col">
                            <SignaturePanel profile={profile} />
                        </div>
                    </>
                    : null
                }

            </div>

        </>
    )
}

export default ProfilePanel