import React, {useEffect, useState} from "react";
import logo from "../assets/logo-splash.png";
import profile_logo from "../assets/profile-logo.png";
import org_logo from "../assets/organisations.png";
import team_logo from "../assets/teams.png";
import HeaderProfileMenu from "./HeaderProfileMenu";
import profapi from "../api/profile";
import auth from "../api/auth";
import roles from "../models/Roles";

const MainHeader = (props) => {
    const updateProfile = props.updateProfile;
    const [profile, setProfile] = useState();
    const [showProfileMenu, setShowProfileMenu] = useState(false);
    const [notifications, setNotifications] = useState(false);

    const handleProfileClick = () => {
        setShowProfileMenu(!showProfileMenu);
    }

    const handleHide = () => {
        setShowProfileMenu(false);
    }

    const handleLogout = () => {
        handleHide();
        auth.logout();
    }

    const NotificationCheck = () => {
        let response = profapi.getNotifications();
        response.then(
            response => {
                if (response.data.notifications === 0) {
                    setNotifications(false)
                } else {
                    setNotifications(true)
                }
            }
        )
    }

    const handleIGVLogoClick = () => {
        if (profile && (profile.role_id === roles.ROLE_USER || profile.role_id === roles.ROLE_ORGANISATION_ADMIN ||
            profile.role_id === roles.ROLE_ADMIN || profile.role_id === roles.ROLE_RETAIL_USER)) {
            window.location.assign("/valuation");
        }
        else if (profile && profile.role_id === roles.ROLE_VALUER) {
            window.location.assign("/valuer");
        }
    }

    const handleOrganisationClick = () => {
        window.location.assign("/admin/organisations");
    }

    const handleTeamsClick = () => {
        window.location.assign("/admin/dashboard");
    }

    const getProfile = () => {
        let response = profapi.getProfile();
        response.then(
            response => {
                setProfile(response.data);
            }
        )
    }

    useEffect(() => {
        getProfile()
        NotificationCheck()
    }, [])

    useEffect(() => {
        if (updateProfile)
            updateProfile(profile);
    }, [profile])

    return (
        <>
            <div className="header-container">
                <div className="header-logo col">
                    {profile && (profile.role_id === roles.ROLE_USER ||
                        profile.role_id === roles.ROLE_ORGANISATION_ADMIN ||
                        profile.role_id === roles.ROLE_ADMIN ||
                        profile.role_id === roles.ROLE_RETAIL_USER)
                        ?
                        <div className="header-logo-new-valuation-container link" onClick={handleIGVLogoClick}>
                            <div className="header-logo-new-valuation-image">
                                <img src={logo} alt="IGV logo"/>
                            </div>
                            <div className="header-logo-new-valuation-text">
                                New valuation
                            </div>
                        </div>
                        :
                        <img src={logo} alt="IGV logo" onClick={handleIGVLogoClick} className="link"/>
                    }
                </div>
                <div className="header-profile-container col">
                    {profile && profile.role_id === roles.ROLE_ADMIN ?
                        <>
                            <div className="header-profile-icon-container org">
                                <img src={org_logo} alt="Organisations logo" onClick={handleOrganisationClick}
                                     className="link"/>
                            </div>
                            <div className="header-profile-icon-container team">
                                <img src={team_logo} alt="Teams logo" onClick={handleTeamsClick} className="link" />
                            </div>
                        </>
                        : null }
                    <div className="header-profile-icon-container">
                        <img src={profile_logo} alt="Profile Logo" onClick={handleProfileClick} className="link"></img>
                        {notifications === true ?
                            <div className="notification-container header"/>
                            :null}
                    </div>

                </div>
                { showProfileMenu ? <HeaderProfileMenu hide={handleHide.bind(this)}
                                                       logout={handleLogout.bind(this)}
                                                       profile={profile}
                                                       notifications={notifications}/>
                    : null }
            </div>
        </>
    )
}

export default MainHeader