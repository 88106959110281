import OrganisationConstants from "../models/OrganisationConstants";

const UsageBasedBillingCard = (props) => {
    const updateSection = props.updateSection;

    function handleBillingClick() {
        updateSection(OrganisationConstants.CREDIT_SECTION);
    }

    return (
        <>
            <div className="credit-billing-container">
                <div className="credit-billing-title">
                    Billing information
                </div>
                <div className="credit-billing-details">
                    <div className="billing-usage">Usage-based billing</div>
                </div>
                <div className="credit-billing-button link" onClick={handleBillingClick}>Change billing method</div>
            </div>
        </>
    )
}

export default UsageBasedBillingCard