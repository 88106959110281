import {useEffect, useState} from "react";


const ValuerHeaderTab = (props) => {
    const header = props.header
    const idx = props.idx
    const handleHeadingRicsClick = props.handleHeadingRicsClick
    const allNotifications = props.allNotifications
    const valuerNotifications = props.valuerNotifications

    const [notifications, setNotifcations] = useState(false)

    useEffect(() => {
        if (header.name === "All RICS Requests") {
            setNotifcations(allNotifications)
        } else {
            setNotifcations(valuerNotifications)
        }
    }, [header, allNotifications, valuerNotifications]);

    return (
        <div className={header.active ?
            "valuer-page-content-head rics active link" :
            "valuer-page-content-head rics link"} id={idx}
             onClick={handleHeadingRicsClick}>{header.name}
            {notifications ?
                <div className="notification-container small"/>
                : null}
        </div>
    )
}

export default ValuerHeaderTab