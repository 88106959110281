import ValuerAmendStrCard from "./ValuerAmendStrCard";
import ValuerAmendDropdownCard from "./ValuerAmendDropdownCard";
import ValuerAmendIntCard from "./ValuerAmendIntCard";
import ValuerReviewAppendixRow from "./ValuerReviewAppendixRow";
import cloud from "../../assets/upload_cloud.png"
import cancel from "../../assets/cancel_white.png"
import ImageUtils from "../../utils/ImageUtils";


const ValuerReviewAppendix = (props) => {

    const floodRisk = props.floodRisk
    const titleNumber = props.titleNumber
    const kitchen = props.kitchen
    const tenure = props.tenure
    const type = props.type
    const changeModification = props.changeModification
    const graph = props.graph
    const addGraph = props.addGrapgh
    const rental_info = props.rental_info
    const sales_info = props.sales_info
    const additional_info = props.additional_info
    const reinstatement_info = props.reinstatement_info
    const generalComment = props.generalComment
    const files = props.files
    const update = props.update
    const updateFiles = props.updateFiles
    const updateGraph = props.updateGraph
    const reportGeneration = props.reportGeneration

    const generalCommentItem = {id:1, type:5, title:"Additional comments:", value:generalComment}

    const flood_options = [{id: 1, name:"Very Low"},{id: 2, name:"Low"},
        {id: 3, name:"Medium"},{id: 4, name:"High"}]
    const type_options = [{id: 1, name:"Detached"},{id: 2, name:"Semi-Detached"},{id: 3, name:"Terraced"},
        {id: 4, name:"Flat/Maisonette"},{id: 5, name:"Other"}]

    const handleValueChange = (value, item) => {
        if (!item) return;
        if (value === null || value === undefined) {
            value = ""
        }
        update(value, item)
    }

    const handleDragOver = (ev) => {
        ev.preventDefault()
    }

    const handleDrop = async (ev) => {
        ev.preventDefault()
        let _files = ev.dataTransfer.files
        let _temp_files = []
        if (reportGeneration) {
            _temp_files = [...files]
            for (let i=0;i<_files.length;i++) {
                if (_files[i].type === "image/png" || _files[i].type === "image/jpeg" && _files[i].size < 400000) {
                    let base64_image = await ImageUtils.convertBase64(_files[i]);
                    let _file = {name: _files[i].name, base64: base64_image, caption: ""}
                    _temp_files.push(_file)
                }
            }
        } else {
            if (_files[0].type === "application/pdf" && _files[0].size < 10000000) {
                let base64_image = await ImageUtils.convertBase64(_files[0]);
                let _file = {name: _files[0].name, base64: base64_image, caption: ""}
                _temp_files.push(_file)
            }
        }
        updateFiles(_temp_files)
    }

    const handleCaptionChange = (ev) => {
        if (!ev) return;
        let _files = [...files]
        _files[ev.target.id].caption = ev.target.value
        updateFiles(_files)
    }

    const handleRemoveFile = (ev) => {
        if (!ev) return;
        let _files = [...files]
        _files.splice(ev.target.id, 1)
        updateFiles(_files)
    }

    return(
        <>
            <div className="valuer-appendix-container">
                <div className="valuer-appendix-title">Appendix</div>
                <div className="valuer-appendix-title">Property Basics</div>
                <div className="valuer-amend-property-row">
                    <ValuerAmendStrCard title={"Title number:"} data={titleNumber}
                                        changeValue={changeModification.bind(this)}/>
                    <ValuerAmendDropdownCard title={"Property Type:"} data={type}
                                             options={type_options}
                                             changeValue={changeModification.bind(this)}/>
                    <ValuerAmendStrCard title={"Tenure:"} data={tenure}
                                        changeValue={changeModification.bind(this)}/>
                    <ValuerAmendIntCard title={"Kitchen:"} data={kitchen}
                                        changeValue={changeModification.bind(this)}/>
                </div>
                <div className="valuer-appendix-title">Rental information</div>
                {graph !== "" ?
                    <div className="valuer-appendix-graph-container">
                        <img src={`data:image/png;base64,${graph}`} alt="rental-graph"/>
                        <div className="valuer-radio-container">
                            <div className="valuer-radio-button-container link" onClick={updateGraph}>
                                {addGraph ?
                                    <div className="valuer-radio-point link"/>
                                    :null}
                            </div>
                            <div className="valuer-asterisk-text">Add bar chart to report</div>
                        </div>
                    </div>
                    :null}
                {rental_info && rental_info.map((item, idx) => {
                    return (
                        <ValuerReviewAppendixRow item={item} update={handleValueChange.bind(this)}/>
                        )
                })}
                <div className="valuer-appendix-title">Sales information</div>
                {sales_info && sales_info.map((item, idx) => {
                    return (
                        <ValuerReviewAppendixRow item={item} update={handleValueChange.bind(this)}/>
                    )
                })}
                <div className="valuer-appendix-title">Additional information</div>
                {additional_info && additional_info.map((item, idx) => {
                    return (
                        <ValuerReviewAppendixRow item={item} update={handleValueChange.bind(this)}/>
                    )
                })}
                <ValuerAmendDropdownCard title={"Flood Risk:"} data={floodRisk}
                                         options={flood_options}
                                         changeValue={changeModification.bind(this)}/>
                <div className="valuer-appendix-title">Reinstatement information</div>
                {reinstatement_info && reinstatement_info.slice(0, 6).map((item, idx) => {
                    return (
                        <ValuerReviewAppendixRow item={item} update={handleValueChange.bind(this)}/>
                    )
                })}
                <div className="valuer-appendix-row-container">
                    <div className="valuer-appendix-row-title">{reinstatement_info[6].title}</div>
                    <div className="valuer-appendix-reinstatement-total">
                        {reinstatement_info[6].value && "£" + reinstatement_info[6].value.toLocaleString()}
                    </div>
                </div>
                <ValuerReviewAppendixRow item={generalCommentItem} update={handleValueChange.bind(this)}/>
                <div className="valuer-appendix-divider"/>
                {reportGeneration ?
                    <>
                        <div className="valuer-appendix-title">Photographs* </div>
                        <div className="valuer-appendix-description">Your image must be less than 400kb.</div>
                        <div className="valuer-appendix-description">Images must be png or jpg format only.</div>
                        <div className="valuer-dropzone" onDragOver={handleDragOver} onDrop={handleDrop}>
                            <img src={cloud} alt="upload cloud"/>
                            <div>Drag and Drop files to Upload</div>
                        </div>
                    </>
                    :
                    <>
                        <div className="valuer-appendix-title">Additional document upload </div>
                        <div className="valuer-dropzone" onDragOver={handleDragOver} onDrop={handleDrop}>
                            <img src={cloud} alt="upload cloud"/>
                            <div>Drag and Drop PDF to Upload</div>
                        </div>
                    </>
                }
                {files && files.map((file, idx) => {
                    return(
                        <>
                            <div className={reportGeneration ? "valuer-appendix-file-row" : "valuer-appendix-file-row static"}>
                                <div className="valuer-appendix-file-name">
                                    {file.name}
                                </div>
                                {reportGeneration ?
                                    <div className="valuer-appendix-file-caption-container">
                                        <input id={idx.toString()} value={file.caption} onChange={(ev) => handleCaptionChange(ev)}/>
                                    </div>
                                    :
                                    null
                                }
                                <div id={idx.toString()} className="valuer-appendix-file-delete link" onClick={(ev) => handleRemoveFile(ev)}>
                                    <img id={idx.toString()} src={cancel} alt="delete"/>
                                </div>
                            </div>
                        </>

                    )
                })}
                <div className="valuer-appendix-divider"/>
            </div>
        </>
    )
}

export default ValuerReviewAppendix