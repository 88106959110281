import React from "react";
import rics from "../assets/regulated-rics-logo.png";

const FooterContainer = () => {


    return (
        <>
            <div className="footer-container">
                <div className="footer-copyright col">
                    <img src={rics} alt="RICS Logo" />
                    <div className="text">Copyright 2023 by Imperium Global Ventures Ltd. All rights reserved.</div>
                </div>
                <div className="footer-links col">
                    <div className="policy"><a href="https://finvise.com/privacy-policy">Privacy Policy</a></div>
                    <div className="policy>"><a href="/terms-and-conditions">Terms and conditions</a></div>
                </div>
                <div className="footer-support-mail col">Support: <a href="mailto:support@finvise.com">support@finvise.com</a></div>
            </div>
        </>
    )
}

export default FooterContainer;