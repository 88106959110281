import {useEffect, useState} from "react";
import profapi from "../api/profile";
import MainHeader from "../components/MainHeader";
import roles from "../models/Roles";
import ValuationHistory from "../components/ValuationHistory";
import RetailValuationHistory from "../components/RetailValuationHistory";

const UserProfileReports = () => {
    const [profile, setProfile] = useState();

    const getProfile = () => {
        let response = profapi.getProfile();
        response.then(
            response => {
                setProfile(response.data);
            }
        )
    }

    useEffect(() => {
        getProfile()
    }, [])

    return (
        <>
            <MainHeader />

                {profile && profile.role_id !== roles.ROLE_VALUER &&
                profile.role_id !== roles.ROLE_RETAIL_USER ?
                    <>
                        <div className="user-profile-reports-container">
                            <div className="user-profile-title">Latest Reports</div>
                            <ValuationHistory profile={profile}/>
                        </div>
                    </> : null }
                {profile && profile.role_id === roles.ROLE_RETAIL_USER ?
                    <>
                        <div className="user-profile-reports-retail-container">
                            <div className="user-profile-title-retail">Your recent searches</div>
                            <RetailValuationHistory profile={profile}/>
                        </div>
                    </> : null }
        </>
    )
}

export default UserProfileReports