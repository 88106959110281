import {useEffect, useState} from "react";

import background from "../assets/selected_property_background.png"
import closeimg from "../assets/Close.png";
import download from "../assets/download.svg";
import pdf_api from "../api/pdf";
import address_formatter from "../utils/Address";
import GenericDialog from "./GenericDialog";
import ButtonLoadingWheel from "./ButtonLoadingWheel";

const AddressCurrentValuationHeader = (props) => {

    const close = props.close
    const reportType = props.reportType;
    const summaryId = props.summaryId;
    const [addressString, setAddressString] = useState()

    const [processingReport, setProcessingReport] = useState(false);
    const [enableReport, setEnableReport] = useState();

    const [showDownloading, setShowDownloading] = useState(false);
    const [showErrorDialog, setShowErrorDialog] = useState(false);
    const [error, setError] = useState();
    const okButton = [{"text":"OK", "id": "1"}];

    useEffect(() => {
        setAddressString(props.addressString)
    }, [props.addressString]);

    useEffect(() => {
        setEnableReport(props.enableReport)
    }, [props.enableReport])

    useEffect(() => {
        setProcessingReport(props.processingReport)
    }, [props.processingReport])

    const handleOKDialogResult = (res) => {
        setShowErrorDialog(false);
    }

    const handleDownloadPDFClick = () => {
        if (!summaryId) return;
        setShowDownloading(true);
        let response = pdf_api.getReport(summaryId);
        response.then(
            response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                let detail = {}
                detail.address = addressString.replace(",", " ");
                let filename = address_formatter.getPdfFilename(detail);
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
            }
        ).catch((err) => {
            setError("Failed to download report");
            setShowErrorDialog(true);
        }).finally(() => {
                setShowDownloading(false);
            }
        )
    }

    return (
        <div className="current-valuation-header-container">
            <img src={background} alt={"address banner background"} className="valuation-content-container-background" />
            <div className="current-valuation-header-content-container">
                <div className="current-valuation-address">{addressString}</div>
                {reportType === 3 && (processingReport || enableReport) ?
                    <div className="current-valuation-download-container">
                        <div className={processingReport ? "current-valuation-download-button disabled link"
                            : "current-valuation-download-button link"} onClick={handleDownloadPDFClick}>
                            <div className="current-valuation-download-text">{enableReport ? "Download insights (PDF)" : "Preparing PDF report"}</div>
                            <div className="current-valuation-download-img-container">
                                {(processingReport && processingReport) || (showDownloading && showDownloading)
                                    ? <ButtonLoadingWheel show={processingReport}/>
                                    :
                                    <img className="current-valuation-download-img" src={download} alt="download image"/>
                                }
                            </div>
                        </div>
                    </div>
                    : null
                }
                <div className="valuation-result-close link" onClick={close}>
                    <img src={closeimg} alt={"Close Valuation"}/>
                </div>
            </div>
            {showErrorDialog ?
                <GenericDialog title="Report - Error" text={error} buttons={okButton}
                               result={handleOKDialogResult.bind(this)} /> : null }

        </div>
    )

}

export default AddressCurrentValuationHeader