const AdminOrganisationGrid = (props) => {
    const update = props.update;
    const organisations = props.organisations;

    const handleAddOrgClick = () => {
        update(true);
    }

    const handleViewOrgClick = (ev) => {
        if (!ev) return;
        window.location.assign("/admin/organisation?id=" + ev.target.id);
    }

    return (
        <>
            <div className="admin-orgs-grid-container">
                <div className="admin-orgs-grid-title-container">
                    <div className="admin-orgs-grid-title">Organisations</div>
                    <div className="admin-orgs-grid-add-button link" onClick={handleAddOrgClick}>
                        Add new organisation
                    </div>
                </div>
                <div className="admin-orgs-grid-headers">
                    <div className="admin-orgs-grid-name">Name</div>
                    <div className="admin-orgs-grid-monthly">Reports this month</div>
                    <div className="admin-orgs-grid-annual">Reports this year</div>
                    <div className="admin-orgs-grid-billing">Billing method</div>
                    <div className="admin-orgs-grid-credit">FINVISE allowance p/m</div>
                    <div className="admin-orgs-grid-credit">RICS allowance p/m</div>
                    <div className="admin-orgs-grid-credit">Market Insight allowance p/m</div>
                </div>

                {organisations && organisations.map((org, idx) => {
                    return (
                        <div className="admin-orgs-grid-row link" onClick={handleViewOrgClick} id={org.id}>
                            <div className="name" id={org.id}>{org.name}</div>
                            <div className="monthly" id={org.id}>{org.reports_month}</div>
                            <div className="annual" id={org.id}>{org.reports_year}</div>
                            <div className="billing" id={org.id}>{org.billing_method === 1 ? "Credit-based" : "Usage-based"}</div>
                            <div className="credit" id={org.id}>{org.billing_method === 1 ? org.finvise_allowance : "n/a"}</div>
                            <div className="credit" id={org.id}>{org.billing_method === 1 ? org.rics_allowance : "n/a"}</div>
                            <div className="credit" id={org.id}>{org.billing_method === 1 ? org.mi_allowance : "n/a"}</div>
                        </div>
                    )
                })}
            </div>
        </>
    )
}

export default AdminOrganisationGrid