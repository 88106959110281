import navigation_arrow from "../assets/navigation_arrow.png";
import PageNavigationCenterButtons from "./PageNavigationCenterButtons";
import {useEffect, useState} from "react";

const PageNavigation = (props) => {

    // Page values
    const [page, setPage] = useState(1)
    const [maxPage, setMaxPage] = useState(1)

    // Passed in functions for buttons
    const previousPage = props.previousPage
    const numberPageClick = props.numberPageClick
    const nextPage = props.nextPage

    useEffect(() => {
        setPage(props.page)
        setMaxPage(props.maxPage)
    }, [props.page, props.maxPage]);

    return (
        <>
            <div className="navigation-button-previous link" onClick={previousPage}>
                <img src={navigation_arrow} alt="Back Arrow" />
                <div className="navigation-button-text"> Previous </div>
            </div>
            <div className="navigation-numbers-container">
                <PageNavigationCenterButtons page={page} maxPage={maxPage} onClick={numberPageClick.bind(this)} />
            </div>
            <div className="navigation-button-next link" onClick={nextPage}>
                <div className="navigation-button-text"> Next </div>
                <img src={navigation_arrow} alt="Next Arrow" style={{transform: "rotate(180deg)"}}/>
            </div>

        </>
    )
}

export default PageNavigation