import AdminOrganisation from "../pages/AdminOrganisation";
import OrganisationConstants from "../models/OrganisationConstants";

const OrganisationReportSummary = (props) => {
    const organisation = props.organisation;
    const updateSection = props.updateSection;

    const handleSeeAllClick = () => {
        updateSection(OrganisationConstants.REPORTS_SECTION);
    }

    return (
        <>
            <div className="organisation-report-summary-container">
                <div className="report-summary-grid-headings">
                    <div className="report-summary-grid-col col">
                        MONTH/ YEAR
                    </div>
                    <div className="report-summary-grid-col reports col">
                        REPORTS
                    </div>
                </div>
                <div className="report-summary-grid-rows">
                    {organisation && organisation.months && organisation.months.map((month, idx) => {
                        return (
                            <>
                                <div className="report-summary-grid-row">
                                    <div className="report-summary-grid-col col">{month.month}</div>
                                    <div className="report-summary-grid-col reports col">{month.reports}</div>
                                </div>
                            </>
                        )
                    })}
                </div>
                <div className="report-summary-buttons">
                    <div className="report-summary-all link" onClick={handleSeeAllClick}>See all reports</div>
                </div>
            </div>
        </>
    )
}

export default OrganisationReportSummary