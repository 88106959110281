

const GetFileName = (comment) => {
    let name = comment.split("File Uploaded: ")[1]
    if (name.includes(".") === false) {
        console.log(name + " has no .")
        return false
    }
    return name
}

export default {
    GetFileName
}