import {useEffect, useState} from "react";


const ValuerAmendIntCard = (props) => {

    const title = props.title
    const data = props.data
    const changeValue = props.changeValue

    const [newValue, setNewValue] = useState()

    const handleChange = (ev) => {
        let _value = Number(ev.target.value.replace(/[^0-9.-]+/g,""));
        if (_value > 1000000 && title !== "Property Size (sq m):") {
            _value = 999999
        }
        if (_value === "") {
            _value = null
        }
        setNewValue(_value)
        changeValue(title, _value)
    }

    useEffect(() => {
        if (data === null) {
            setNewValue("")
        } else {
            setNewValue(data)
        }

    },[data])

    return(
        <>
            <div className="valuer-amend-card-container" >
                <div className="valuer-amend-card-header-container">
                    <div className="valuer-amend-card-header">
                        {title}
                    </div>
                </div>
                <div className="valuer-amend-valuation-text">
                    <input type="text" pattern="[0-9]*" value={newValue && newValue.toLocaleString()} placeholder={"No Data"} onChange={handleChange}/>
                </div>
            </div>
        </>
    )
}

export default ValuerAmendIntCard