import OrganisationConstants from "../models/OrganisationConstants";
import {useState} from "react";
import RequestCreditPopup from "./RequestCreditPopup";

const CreditBasedBillingCard = (props) => {
    const organisation = props.organisation;
    const updateSection = props.updateSection;
    const role_id = props.role_id

    const [showCreditRequestPopup, setShowCreditRequestPopup] = useState(false);

    function handleBillingClick() {
        updateSection(OrganisationConstants.CREDIT_SECTION);
    }

    const handleCreditRequestClick = () => {
        setShowCreditRequestPopup(true);
    }

    const updateShow = (show) => {
        setShowCreditRequestPopup(show);
    }

    return (
        <>
            <div className="credit-billing-container">
                <div className="credit-billing-title">
                    Company credits
                </div>
                <div className="credit-billing-details">
                    <div className="credit-billing-balance">
                        <div className="credit-billing-balance-title">
                            FINVISE Valuation
                        </div>
                        <div className="credit-billing-balance-value">
                            {organisation && organisation.finvise_balance}/{organisation && organisation.finvise_allowance}
                        </div>
                    </div>
                    <div className="credit-billing-balance">
                        <div className="credit-billing-balance-title">
                            RICS Valuation
                        </div>
                        <div className="credit-billing-balance-value">
                            {organisation && organisation.rics_balance}/{organisation && organisation.rics_allowance}
                        </div>
                    </div>
                    <div className="credit-billing-balance">
                        <div className="credit-billing-balance-title">
                            Market Insight
                        </div>
                        <div className="credit-billing-balance-value">
                            {organisation && organisation.mi_balance}/{organisation && organisation.mi_allowance}
                        </div>
                    </div>
                </div>
                {role_id === 3 ?
                    <div className="credit-billing-button link" onClick={handleBillingClick}>Change billing method</div>
                    :
                    <div className="credit-summary-button link" onClick={handleCreditRequestClick}>Add more credits</div>
                }
                {showCreditRequestPopup ? <RequestCreditPopup update={updateShow.bind(this)} /> : null }
            </div>
        </>
    )
}

export default CreditBasedBillingCard