const SAFE_STRING_PATTERN = "[a-zA-Z0-9 '-]+"
const SAFE_STRING_REGEX = new RegExp("^([a-zA-Z0-9 '-]+)$")
const SAFE_STRING_TEXT = "Please enter an alpha numeric value less than 64 characters"
const UK_POSTCODE_REGEX = new RegExp("([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\\s?[0-9][A-Za-z]{2})")
const UK_POSTCODE_PATTERN = "([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\\s?[0-9][A-Za-z]{2})"

const safeStringCheck = (value) => {
    let result = false;
    if (SAFE_STRING_REGEX.test(value))
        result = true;
    return result;
}

const validUkPostcodeCheck = (value) => {
    let result = false;
    if (UK_POSTCODE_REGEX.test(value))
        result = true;
    return result;
}

export default {
    SAFE_STRING_PATTERN,
    SAFE_STRING_TEXT,
    SAFE_STRING_REGEX,
    UK_POSTCODE_PATTERN,
    UK_POSTCODE_REGEX,
    safeStringCheck,
    validUkPostcodeCheck
}