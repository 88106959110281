import {useState} from "react";
import ValuationAddressList from "./ValuationAddressList";
import AddressSearchFunctions from "../../utils/AddressSearchFunctions";


const MarketInsightInputBar = (props) => {

    const evaluate = props.evaluate;

    // Values
    const [selectedProperty, setSelectedProperty] = useState(null);
    const selectedType = props.selectedType

    // Address Selector
    const [displayAddress, setDisplayAddress] = useState("");
    const [listAddresses, setListAddresses] = useState([]);
    const [showListAddresses, setShowListAddresses] = useState(false);
    const [onlyPostcodes, setOnlyPostcodes] = useState(false)

    const validateFields = () => {
        return (selectedProperty && selectedProperty.property_id) && (selectedType && selectedType.id)
    }

    const handleRequestValuation = async () => {
        if (!validateFields()) return
        let _body = {
            property_id: selectedProperty.property_id,
            type: selectedType.id,
        }
        evaluate(_body, displayAddress);
    }

    const hideListAddresses = () => {
        setShowListAddresses(false)
    }

    const selectAddress = (event) => AddressSearchFunctions.selectAddress(event.target.id, listAddresses,
        setDisplayAddress, setSelectedProperty, setShowListAddresses, setListAddresses)

    const selectPostcode = (event) => AddressSearchFunctions.selectPostcode(event.target.id, listAddresses,
        setDisplayAddress, setListAddresses, setShowListAddresses, setOnlyPostcodes)

    return (
        <>
            <div className="field-input">
                <div className="valuation-address-container">
                    <div className="valuation-address-bar">
                        <div className="valuation-address-entry 100">
                            <div className="valuation-field-container borderless">
                                <div className="valuation-type-title">
                                    Address
                                </div>
                                <input type="text"
                                       className="valuation-address-text"
                                       value={displayAddress}
                                       id="address-search"
                                       onChange={(ev) => setDisplayAddress(ev.target.value.toUpperCase())}
                                       placeholder="Type postcode"
                                       maxLength="10"
                                       onKeyUp={() => AddressSearchFunctions.handlePostcodeLookup(displayAddress,
                                           setShowListAddresses, setDisplayAddress, setSelectedProperty,
                                           setListAddresses, setOnlyPostcodes)}
                                       onClick={() => setShowListAddresses(true)}
                                       aria-label="Address search by postcode"
                                       data-1p-ignore
                                       data-lpignore
                                />
                            </div>
                            <input type="button" className={!validateFields() ? "valuation-request-button" : "valuation-request-button disabled"}
                                   value={selectedType && "+ " + selectedType.description}
                                   onClick={() => handleRequestValuation()}
                                   disabled={!validateFields()}
                            />
                        </div>
                    </div>
                    <ValuationAddressList show={showListAddresses}
                                          displayAddress={displayAddress}
                                          hide={hideListAddresses.bind(this)}
                                          listAddresses={listAddresses}
                                          onlyPostcodes={onlyPostcodes}
                                          selectAddress={selectAddress.bind(this)}
                                          selectPostcode={selectPostcode.bind(this)}
                    />
                </div>
            </div>
        </>
    )
}

export default MarketInsightInputBar