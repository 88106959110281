import axiosInstance from "./http/axios_instance"
const http = axiosInstance("/protected",  null, "/forbidden")

const getProfile = () => http.get("profile");

const getNotifications = () => http.get("/profile/notifications");

const setPostnominals = (body) => http.put("/profile/postnominals", body);

const deleteProfile = () => http.put("profile");

const postSignature = (body) => http.post("/profile/signature", body)

const getSignature = () => http.get("/profile/signature")

export default {
    getProfile,
    getNotifications,
    setPostnominals,
    deleteProfile,
    postSignature,
    getSignature
}