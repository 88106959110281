import {useEffect, useRef, useState} from "react";
import PageSubMenu from "../components/PageSubMenu";
import InsightLocalAreaDetails from "./InsightLocalAreaDetails";

const InsightLocalArea = (props) => {

    const title = props.title;
    const params = props.params;
    const valuation = props.valuation;
    const propertyId = props.propertyId;
    const postcode = props.postcode;
    const summaryId = props.summaryId;
    const updateDateReady = props.updateLocalDataReady;
    const [tabMenus, setTabMenus] = useState()
    const [sectionName, setSectionName] = useState('The local area');

    useEffect(() => {
        if (!props.tabs) return;
        let menus = props.tabs;

        setTabMenus(menus);
        setSectionName(menus[0].name);
    }, [props.tabs]);

    const UpdateActiveMenu = (idx) => {
        if(!tabMenus) return;
        setSectionName(tabMenus[idx].name);
    }

    return (
        <>
            <div className="insight-local-padded-container">
                <div className="insight-recent-title-container">
                    <div className="insight-recent-title">
                        {title}
                    </div>
                </div>
                <div className="insight-local-menu-container">
                    <div className="insight-local-container">
                        <PageSubMenu items={tabMenus}
                                     title={title}
                                     setActiveMenu={UpdateActiveMenu.bind(this)}
                                     spacing="0"
                                     setSelection={sectionName}
                                     indicator="40"/>
                        <InsightLocalAreaDetails setSelection={sectionName && sectionName}
                                                 title={title}
                                                 tabs={tabMenus && tabMenus}
                                                 summaryId={summaryId}
                                                 postcode={postcode}
                                                 params={params}
                                                 valuation={valuation}
                                                 propertyId={propertyId}
                                                 updateDataReady={updateDateReady}/>
                    </div>
                </div>
            </div>
        </>
    )
}

export default InsightLocalArea