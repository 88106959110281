import {useEffect, useState} from "react";
import downarrow from "../../assets/arrow_down.png";


const ValuerFilterDropdown = (props) => {
    const data = props.data
    const changeValue = props.changeValue
    const options = props.options
    const type = props.type
    const clearFilter = props.clearFilter

    const [showDrop, setShowDrop] = useState(false)
    const [showDelete, setShowDelete] = useState(false)

    const handleHeader = () => {
        setShowDrop(!showDrop)
    }

    const handleRowClick = (item) => {
        if (!item) return;
        changeValue(item)
        setShowDrop(false)
    }


    useEffect(() => {
        if (type === "Client") {
            if (data !== "Client") {
                setShowDelete(true)
            } else {
                setShowDelete(false)
            }
        } else {
            if (data !== "Valuer ") {
                setShowDelete(true)
            } else {
                setShowDelete(false)
            }
        }
    }, [type, data]);

    return(
        <>
            <div className="report-filter-container">
                {showDelete ?
                    <div className="report-filter-delete-container link" onClick={() => clearFilter(type)}>X</div>
                    :null}
                <div className={showDrop ? "report-filter-header dropdown open link" : "report-filter-header dropdown link" } onClick={handleHeader}>
                    <div className="report-filter-header-text" onClick={handleHeader}>{data}</div>
                    <div className="report-filter-header-arrow" onClick={handleHeader}>
                        <img src={downarrow} style={showDrop ? {rotate:"180deg"} : null} alt={"arrow"} onClick={handleHeader}/>
                    </div>
                </div>
                {showDrop ?
                    <div className="report-filter-dropdown-container">
                        {options && options.map((item, idx) => {
                            return(
                                <div className="report-filter-dropdown-row link" onClick={() => handleRowClick(item)}>
                                    {type === "Client" ? item.name : item.firstname + " " + item.lastname}
                                </div>
                            )
                        })}
                    </div>
                    :null}
            </div>
        </>
    )
}

export default ValuerFilterDropdown