import logo from "../assets/logo-splash.png";
import background from "../assets/splash-back.png";
import screenshot from "../assets/splash-screenshot.png";

const SplashScreen = () => {

    return (
        <>
            <div className="splashscreen-container" style={{background: "#0B0E59 url(" + background + ") bottom right no-repeat"}}>
                <div className="splashscreen-logo">
                    <img src={logo} alt="IGV logo"/>
                </div>
                <div className="splashscreen-text-block">
                    <div className="splashscreen-heading">The future of valuation</div>
                    <div className="splashscreen-sub-title">Leverage the power of AI for your asset valuations.</div>
                </div>
                <div className="splashscreen-screenshot">
                    <img src={screenshot} alt="splash screenshot"/>
                </div>
            </div>
        </>
    )
}

export default SplashScreen