import {useEffect, useState} from "react";
import RICSHubRequestsGrid from "./RICSHubRequestsGrid";
import arrow from "../../assets/arrow_back.png";
import ValuerReportsGrid from "../valuer/ValuerReportsGrid";

const RICSHubValuerRequests = (props) => {
    const [activeSection, setActiveSection] = useState(1);
    const [headings, setHeadings] = useState();
    const close = props.close;

    const getHeadings = () => {
        let items = [];
        let item = {};
        item.id = 1;
        item.name= "Open";
        item.active = true;
        items.push(item);

        item = {};
        item.id = 2;
        item.name = "Closed";
        item.active = false;
        items.push(item);

        setHeadings(items);
    }

    useEffect(() => {
        getHeadings()
    }, [])

    function handleHeadingClick(ev) {
        if (!ev.target.id) return;
        let newHeads = [...headings];
        for(let i=0;i<newHeads.length;i++){
            if (i === parseInt(ev.target.id)){
                newHeads[i].active = true;
                setActiveSection(i+1);
            }
            else {
                newHeads[i].active = false;
            }
        }
        setHeadings(newHeads);
    }

    const handleBackClick = () => {
        close();
    }

    return (
        <>
            <div className="rics-hub-requests-grid-container">
                <div className="rics-hub-requests-title">
                    <img className="link" src={arrow} alt="arrow icon" onClick={handleBackClick}/>
                    RICS requests
                </div>
                <div className="valuer-page-content-container">
                    <div className="valuer-page-content-header">
                        {headings && headings.map((heading, idx) => {
                            return (
                                <div className={heading.active ?
                                    "valuer-page-content-head active link" :
                                    "valuer-page-content-head link"} id={idx}
                                     onClick={handleHeadingClick}>{heading.name}
                                </div>
                            )
                        })}
                    </div>
                    <div className="rics-hub-requests-grid-container">
                        {activeSection === 1 ?
                            <ValuerReportsGrid status={[6, 8, 10, 11, 12]} showOwn={1}/> :
                            null
                        }
                        {activeSection === 2 ?
                            <ValuerReportsGrid status={[7, 9]} showOwn={1}/> :
                            null
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default RICSHubValuerRequests