import {useEffect, useState} from "react";
import downarrow from "../../assets/arrow_down.png"


const ValuerAmendDropdownCard = (props) => {

    const title = props.title
    const data = props.data
    const changeValue = props.changeValue
    const options = props.options

    const [newValue, setNewValue] = useState()
    const [showDrop, setShowDrop] = useState(false)

    const handleHeader = () => {
        setShowDrop(!showDrop)
    }

    const handleRowClick = (item) => {
        if (!item) return;
        changeValue(title, item)
        setShowDrop(false)
    }

    useEffect(() => {
        setNewValue(data)
    },[data])

    return(
        <>
            <div className="valuer-amend-card-container" >
                <div className="valuer-amend-card-header-container">
                    <div className="valuer-amend-card-header">
                        {title}
                    </div>
                </div>
                <div className={showDrop ? "valuer-amend-valuation-text dropdown open" : "valuer-amend-valuation-text dropdown" } onClick={handleHeader}>
                    {newValue && newValue ?
                        <>
                            <div className="valuer-amend-valuation-selected-text" onClick={handleHeader}>{newValue.name}</div>
                            <div className="valuer-amend-valuation-selected-arrow" onClick={handleHeader}>
                                <img src={downarrow} style={showDrop ? {rotate:"180deg"} : null} alt={"arrow"} onClick={handleHeader}/>
                            </div>
                        </>
                        :null}
                </div>
                {showDrop ?
                    <div className="valuer-amend-dropdown-container">
                        {options && options.map((item, idx) => {
                            return(
                                <div className="valuer-amend-dropdown-row link" onClick={() => handleRowClick(item)}>
                                    {item.name}
                                </div>
                            )
                        })}
                    </div>
                    :null}
            </div>
        </>
    )
}

export default ValuerAmendDropdownCard