const valuation_data = (_address) => {
    let result = {}
    result.valuation = {
        "address": _address
    };
    result.valuation.id = 1;
    result.valuation.further_details = [
        {
            "position": 1,
            "icon_filename": "property_type_icon.png",
            "background_filename": "property_type_bg.png",
            "title": "Property Type",
            "value": "Detached"
        },
        {
            "position": 2,
            "icon_filename": "tenure_icon.png",
            "background_filename": "tenure_bg.png",
            "title": "Tenure",
            "value": "Freehold"
        },
        {
            "position": 3,
            "icon_filename": "property_size_icon.png",
            "background_filename": "property_size_bg.png",
            "title": "Property Size",
            "value": "5 sq m"
        },
        {
            "position": 4,
            "icon_filename": "bedrooms_icon.png",
            "background_filename": "bedrooms_bg.png",
            "title": "No. of bedrooms",
            "value": "5+"
        },
        {
            "position": 5,
            "icon_filename": "bathrooms_icon.png",
            "background_filename": "bathrooms_bg.png",
            "title": "No. of bathrooms",
            "value": "3"
        },
        {
            "position": 7,
            "icon_filename": "epc_rating_icon.png",
            "background_filename": "epc_rating_bg.png",
            "title": "EPC Rating",
            "value": "1 (8)"
        },
        {
            "position": 8,
            "icon_filename": "new_build_icon.png",
            "background_filename": "new_build_bg.png",
            "title": "New Build status",
            "value": "Not new build"
        },
        {
            "position": 9,
            "icon_filename": "floodrisk_icon.png",
            "background_filename": "floodrisk_bg.png",
            "title": "Flood risk score",
            "value": "No risk"
        },
        {
            "position": 11,
            "icon_filename": "jpknotweed_icon.png",
            "background_filename": "jpknotweed_bg.png",
            "title": "Japanese Knotweed",
            "value": "Category C: Manage"
        }
    ];
    result.valuation.historic_values = {
        "pred_price": 723000,
        "pred_price_1y": 713000,
        "pred_price_2y": 633000,
        "pred_price_3y": 623000,
        "pred_price_4y": 583000,
        "pred_price_5y": 483000,
        "nei_avg": 843100,
        "nei_avg_1y": 803100,
        "nei_avg_2y": 703100,
        "nei_avg_3y": 683100,
        "nei_avg_4y": 603100,
        "nei_avg_5y": 543100
    }
    result.valuation.predicted_value = "1000000";
    result.processed_date = new Date();
    return result;
}

export default {
    valuation_data
}