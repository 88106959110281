import React, {useEffect, useState} from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import profapi from "../api/profile";
import roles from "../models/Roles";

import FooterContainer from "./FooterContainer";
import Valuation from "../pages/Valuation";
import Login from "../pages/Login";
import ForgotPassword from "../pages/ForgotPassword";
import Register from "../pages/Register";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import Logout from "../pages/Logout";
import ResetPassword from "../pages/ResetPassword";
import AdminDashboard from "../pages/AdminDashboard";
import UserProfile from "../pages/UserProfile";
import AdminOrganisations from "../pages/AdminOrganisations";
import AdminOrganisation from "../pages/AdminOrganisation";
import Terms from "../pages/Terms";
import Valuer from "../pages/Valuer";
import Forbidden from "../pages/Forbidden";
import ValuerReview from "../pages/ValuerReview";
import UserReview from "../pages/UserReview";
import UserProfileReports from "../pages/UserProfileReports";
import Unknown from "../pages/Unknown";
import SelectPricing from "../pages/SelectPricing";
import UserProfileChangeMembership from "../pages/UserProfileChangeMembership";
import PropertyComparison from "../pages/PropertyComparison";
import Success from "../pages/Success";

const AppLayout = () => {
    const [role, setRole] = useState();

    useEffect(() => {
        async function getProfile() {
            try{
                if (!window.location.pathname.includes("login") &&
                    (!window.location.pathname.includes("register")) &&
                    (!window.location.pathname.includes("forgot-password")) &&
                    (!window.location.pathname.includes("terms-and-conditions"))){
                    let response = profapi.getProfile();
                    response.then(
                        response => {
                            setRole(response.data.role_id);
                        }
                    )

                }
            }
            catch (err) {
                console.log("Profile retrieval error: " + err);
            }
        }

        getProfile();
    }, [])

    return (
        <>
            <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet"
                  integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC"
                  crossOrigin="anonymous"/>
            <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js"
                    integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM"
                    crossOrigin="anonymous" />

            <>
                <Routes>
                    <Route exact path="/"
                           element={role &&
                                role === roles.ROLE_USER ? <Navigate to="/valuation" /> :
                                role === roles.ROLE_ORGANISATION_ADMIN ? <Navigate to="/admin/organisation" /> :
                                role === roles.ROLE_ADMIN ? <Navigate to="/admin/organisations" /> :
                                role === roles.ROLE_VALUER ? <Navigate to="/valuer" /> :
                                role === roles.ROLE_RETAIL_USER ? <Navigate to="/valuation" /> :
                                <Unknown />
                    }/>
                    <Route path="/valuation" element={
                        role === roles.ROLE_USER ? <Valuation /> :
                        role === roles.ROLE_ORGANISATION_ADMIN ? <Valuation /> :
                        role === roles.ROLE_ADMIN ? <Valuation /> :
                        role === roles.ROLE_VALUER ? <Navigate to="/" /> :
                        role === roles.ROLE_RETAIL_USER ? <Valuation /> :
                            <Unknown />
                    }/>
                    <Route path="/comparison" element={
                        <PropertyComparison />
                    }/>
                    <Route path="/login" element={
                        <Login />
                    }/>
                    <Route path="/logout" element={
                        <Logout />
                    }/>
                    <Route path="/forgot-password" element={
                        <ForgotPassword />
                    }/>
                    <Route path="/register" element={
                        <Register />
                    }/>
                    <Route path="/reset-password" element={
                        <ResetPassword />
                    }/>
                    <Route path="/pricing" element={
                        <SelectPricing />
                    }/>
                    <Route path="/privacy-policy" element={
                        <PrivacyPolicy />
                    }/>
                    <Route path="/terms-and-conditions" element={
                        <Terms />
                    }/>
                    <Route path="/admin/dashboard" element={
                        <AdminDashboard />
                    }/>
                    <Route path="/admin/organisations" element={
                        <AdminOrganisations />
                    }/>
                    <Route path="/admin/organisation" element={
                        <AdminOrganisation />
                    }/>
                    <Route path="/valuer" element={
                        role === roles.ROLE_USER ? <Navigate to="/" /> :
                        role === roles.ROLE_ORGANISATION_ADMIN ? <Navigate to="/" /> :
                        role === roles.ROLE_ADMIN ? <Navigate to="/" /> :
                        role === roles.ROLE_VALUER ? <Valuer /> :
                            <Unknown />
                    }/>
                    <Route path="/valuer/review">
                        <Route path=":id" element={
                            <ValuerReview />
                        }/>
                    </Route>
                    <Route path="/success" element={
                        <Success />
                    }/>
                    <Route path="/profile/details" element={
                        <UserProfile />
                    }/>
                    <Route path="/profile/reports" element={
                        <UserProfileReports />
                    }/>
                    <Route path="/profile/change-membership" element={
                        <UserProfileChangeMembership />
                    }/>
                    <Route path={"/profile/review"}>
                        <Route path=":id" element={
                            <UserReview />
                        }/>
                    </Route>
                    <Route path="/forbidden" element={
                        <Forbidden />
                    }/>
                    <Route path="*" element={
                        <Unknown />
                    }/>
                </Routes>
            </>

            <FooterContainer />

        </>
    )
}

export default AppLayout;