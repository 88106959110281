import {useState} from "react";
import org_api from "../api/admin/admin_orgs";

const AdminNewOrganisation = (props) => {
    const update = props.update
    const [name, setName] = useState();
    const [finviseCredit, setFinviseCredit] = useState(500);
    const [ricsCredit, setRicsCredit] = useState(500);
    const [insightCredit, setInsightCredit] = useState(500);
    const [creditBased, setCreditBased] = useState(true);
    const [usageBased, setUsageBased] = useState(false);
    const [freeTrial, setFreeTrial] = useState(false)
    const handleSaveClick = () => {
        let billing_method = 1;
        let _finvise_amount = finviseCredit;
        let _rics_amount = ricsCredit;
        let _insight_amount = insightCredit;
        if (usageBased) {
            billing_method = 2;
            _finvise_amount = 0;
            _rics_amount = 0;
            _insight_amount = 0;
        }
        let _credits = {finvise: _finvise_amount, rics: _rics_amount, insight: _insight_amount}
        let body = {
            "name": name,
            "billing_method": billing_method,
            "credits": _credits,
            "free_trial": freeTrial
        }
        let response = org_api.newOrganisation(body);
        response.then(
            response => {
                update(false);
            }
        )
    }

    const CheckValue = (value) => {
        if (!value) return;
        let _value = value
        if (_value < 0) {
            _value = 0
        }
        return _value;
    }

    const handleCancelClick = () => {
        update(false);
    }

    function handleFreeTrialClick() {
        setFreeTrial(!freeTrial)
    }

    return (
        <>
            <div className="admin-neworg-title">
                Create a new Organisation
            </div>
            <div className="admin-neworg-content">
                <div className="admin-neworg-name-container">
                    <div className="admin-neworg-field-label">Organisation name</div>
                    <input type="text" value={name} onChange={(ev) => setName(ev.target.value)}
                    placeholder="Company name"/>
                </div>
                <div className="admin-neworg-billing-container">
                    <div className="admin-neworg-field-label">Billing method</div>
                    <div className="admin-neworg-billing-cards-container">
                        <div className="admin-neworg-billing-card">
                            <label className="billing-check-container">
                                <input type="checkbox" checked={creditBased} onChange={() => {
                                    setCreditBased(!creditBased);
                                    setUsageBased(!usageBased)
                                }}/>
                                <span className="checkmark"></span>
                                <div className="billing-check-label">Credit-based</div>
                            </label>
                            <div className="admin-neworg-credit-container">
                                <div className="admin-neworg-field-label">Credit allowance per month</div>
                                <div className="admin-neworg-field-label">FINVISE credits</div>
                                <input type="number" value={finviseCredit} onChange={(ev) => setFinviseCredit(CheckValue(ev.target.value))}/>
                                <div className="admin-neworg-field-label">RICS credits</div>
                                <input type="number" value={ricsCredit} onChange={(ev) => setRicsCredit(CheckValue(ev.target.value))}/>
                                <div className="admin-neworg-field-label">Market Insight credits</div>
                                <input type="number" value={insightCredit} onChange={(ev) => setInsightCredit(CheckValue(ev.target.value))}/>
                            </div>
                        </div>
                        <div className="admin-neworg-billing-card">
                            <label className="billing-check-container">
                                <input type="checkbox" checked={usageBased} onChange={() => {
                                    setCreditBased(!creditBased);
                                    setUsageBased(!usageBased)
                                }}/>
                                <span className="checkmark"></span>
                                <div className="billing-check-label">Usage-based</div>
                            </label>
                        </div>
                    </div>
                    <div className="admin-neworg-field-label free-trial">Free trial</div>
                    <div className="admin-neworg-billing-cards-container">
                        <div className="admin-edit-billing-card short">
                            <label className="billing-check-container">
                                <input type="checkbox" checked={freeTrial} onChange={handleFreeTrialClick}/>
                                <span className="checkmark"></span>
                                <div className="billing-check-label">Free trial</div>
                            </label>
                        </div>
                    </div>
                </div>

            </div>
            <div className="admin-neworg-buttons">
                <div className="admin-neworg-save-button link" onClick={handleSaveClick}>Save organisation</div>
                <div className="admin-neworg-cancel-button link" onClick={handleCancelClick}>Cancel</div>
            </div>

        </>
    )
}

export default AdminNewOrganisation