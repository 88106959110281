import {useEffect, useState} from "react";
import DatePicker from "react-datepicker";

const DateRangePickerDialog = (props) => {
    const close = props.handleClose
    const [startDate, setStartDate] = useState(Date.now());
    const [endDate, setEndDate] = useState(Date.now())

    function handleButtonClick() {
        close(startDate, endDate);
    }

    return (
        <>
            <div className="page-mask"/>
            <div className="date-range-picker-container">
                <div className="date-range-picker-header-container">
                    <div className="date-range-picker-header">
                        Select a date range
                    </div>
                </div>
                <div className="date-range-picker-selector-container">
                    <div className="date-range-picker-selector">
                        <div className="date-range-picker-selector-title">Start</div>
                        <div className="date-range-picker-content">
                            <DatePicker selected={startDate} dateFormat="dd/MM/yyyy"
                                        onChange={(dt) => setStartDate(dt.getTime())}/>
                        </div>
                    </div>
                    <div className="date-range-picker-selector">
                        <div className="date-range-picker-selector-title">End</div>
                        <div className="date-range-picker-content">
                            <DatePicker selected={endDate} dateFormat="dd/MM/yyyy"
                                        onChange={(dt) => setEndDate(dt.getTime())}/>
                        </div>
                    </div>
                </div>
                <div className="date-range-picker-footer-container">
                    <div className="date-range-picker-button link" onClick={handleButtonClick} >
                        <div className="date-range-picker-button-text">OK</div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DateRangePickerDialog