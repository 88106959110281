import {useEffect, useState} from "react";
import downarrow from "../assets/arrow_down.png"


const GenericDropdownCard = (props) => {

    const title = props.title
    const value = props.value
    const setValue = props.setValue
    const dropdownDefault = props.default
    const options = props.options

    const [showDrop, setShowDrop] = useState(false)

    const handleHeader = () => {
        setShowDrop(!showDrop)
    }

    return(
        <>
            <div className="generic-dropdown-card-container" >
                <div className="generic-dropdown-card-header-container">
                    <div className={"generic-dropdown-card-header"}>
                        {title}
                    </div>
                </div>
                <div className={showDrop ? "generic-dropdown-text dropdown open link" : "generic-dropdown-text dropdown link" } onClick={handleHeader}>
                    <>
                        <div className="generic-dropdown-selected-text" onClick={handleHeader}>
                            {value && value.name ? value.name : dropdownDefault }
                        </div>
                        <div className="generic-dropdown-selected-arrow" onClick={handleHeader}>
                            <img src={downarrow} style={showDrop ? {rotate:"180deg"} : null} alt={"arrow"} onClick={handleHeader}/>
                        </div>
                    </>
                </div>
                {showDrop ?
                    <div className="generic-dropdown-container">
                        {options && options.map((item, idx) => {
                            return(
                                <div className="generic-dropdown-row link" onClick={() => {
                                    setValue(item)
                                    setShowDrop(false)
                                }}>
                                    {item.name}
                                </div>
                            )
                        })}
                    </div>
                    :null}
            </div>
        </>
    )
}

export default GenericDropdownCard