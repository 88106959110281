import axiosInstance from "../http/axios_instance"
const http = axiosInstance("/protected/orgadmin/",  null, "/dashboard")

const getPeople = () => http.get("/people");

const getAllTeamMembers = () => http.get("/team_members");

export default {
    getPeople,
    getAllTeamMembers
}