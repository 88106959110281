import {useEffect, useState} from "react";


const PageNavigationCenterButtons = (props) => {

    const numberPageClick = props.onClick

    const [page, setPage] = useState(1)
    const [maxPage, setMaxPage] = useState()
    const [pageNumbers, setPageNumbers] = useState([])

    const updatePageNumbers = () => {
        if (!props.page) return;
        if (!props.maxPage) return;
        let numbers = []
        if (props.maxPage < 7){
            // Less than 7 pages should be a simple line
            for (let i=0;i<props.maxPage;i++) {
                numbers.push(i+1);
            }
        }
        else{
            if (props.page === props.maxPage){
                // Selecting the last page will display the first 3
                numbers = [1,2,3,0,props.maxPage-2,props.maxPage-1,props.maxPage];
            }
            else {
                // All other pages will show the last 3 after the ellipsis
                // the first 3 will be the next prior pages if at the end of the number
                // or the prior 3 from the current page if in the middle
                // or 1,2 and 3 if at the beginning
                numbers = [
                    Math.max(Math.min(props.page-2,props.maxPage-5), 1),
                    Math.max(Math.min(props.page-1,props.maxPage-4), 2),
                    Math.max(Math.min(props.page,props.maxPage-3), 3),
                    0,
                    props.maxPage-2,
                    props.maxPage-1,
                    props.maxPage]
            }
        }

        setPageNumbers(numbers)
    }

    useEffect(() => {
        setPage(props.page)
        setMaxPage(props.maxPage)
        updatePageNumbers()
    }, [props.page, props.maxPage]);

    return (
        <>
            {pageNumbers && maxPage && page && pageNumbers.length > 6
                ? pageNumbers.map((item, idx) => {
                        return (
                            <div className={pageNumbers[idx]===page ? "navigation-number-button active"
                                : (idx===3 ? "navigation-number-button-center"
                                    : "navigation-number-button")}
                                 onClick={() => {
                                     numberPageClick(pageNumbers[idx]);
                                 }}>
                                {idx===3 ? "..."
                                    : pageNumbers[idx]}
                            </div>
                        )
                    }
                )
                : pageNumbers && pageNumbers.map((item, idx) => {
                        return (
                            <div className={pageNumbers[idx]===page ? "navigation-number-button active"
                                : "navigation-number-button"}
                                 onClick={() => {numberPageClick(pageNumbers[idx]);}}>
                                {pageNumbers[idx]}
                            </div>
                        )
                    }
                )
            }
        </>
    )
}


export default PageNavigationCenterButtons