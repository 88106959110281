import {useEffect, useState} from "react";


const ValuerReviewAppendixRow = (props) => {
    const item = props.item
    const update = props.update

    const [currency, setCurrency] = useState(false)
    const [count, setCount] = useState(0)

    const handleCurrencyChange = (ev) => {
        let _value = Number(ev.target.value.replace(/[^0-9.-]+/g,""));
        if (_value === "") {
            _value = null
        }
        update(_value, item)
    }

    useEffect(() => {
        if (!item) return;
        switch (item.title) {
            case "Clearance:":
                setCurrency(true)
                break;
            case "Rebuild cost:":
                setCurrency(true)
                break;
            case "Professional fees:":
                setCurrency(true)
                break;
            case "External works:":
                setCurrency(true)
                break;
        }
    }, [item]);

    useEffect(() => {
        if (currency) return;
        if (!item.value) {
            setCount(0)
        } else {
            setCount(item.value.length)
        }
    }, [item.value, currency]);

    return(
        <div className="valuer-appendix-row-container">
            <div className="valuer-appendix-row-title">
                {item.title}
                {currency ?
                    null
                    :
                    <div className="valuer-appendix-row-counter-container">
                        <div className="valuer-appendix-">
                            {"(" + count + "/300)"}
                        </div>
                    </div>
                }
            </div>
            {currency ?
                <input type="text" pattern="[0-9]*" value={item.value && "£" + item.value.toLocaleString()} placeholder={"No Data"} onChange={handleCurrencyChange}/>
                :
                <input type="text" value={item.value} placeholder={"No Data"} onChange={(ev) => update(ev.target.value, item)}/>
            }
        </div>
    )
}

export default ValuerReviewAppendixRow