import axiosInstance from "../http/axios_instance"
const http = axiosInstance("/protected/admin",  null, "/forbidden")

const getOrganisations = () => http.get("/organisations");
const getOrganisation = (id) => http.get("/organisations?organisation_id=" + id);

const newOrganisation = (body) => http.post("/organisations", body);

export default {
    getOrganisations,
    getOrganisation,
    newOrganisation
}