import {useEffect, useState} from "react";
import ValuationAddressList from "./ValuationAddressList";
import AddressSearchFunctions from "../../utils/AddressSearchFunctions";
import val_api from "../../api/valuation";
import InputBarDropdown from "./InputBarDropdown";
import PropertyDetails from "./PropertyDetails";
import CustomAddressPopup from "./CustomAddressPopup";


const RICSInputBar = (props) => {

    const selectedType = props.selectedType
    const evaluate = props.evaluate;
    const manual_evaluate = props.manual_evaluate

    // Required Values
    const [reportCategory, setReportCategory] = useState(null);
    const [reportType, setReportType] = useState(null);
    const [reportPurpose, setReportPurpose] = useState(null)

    // With an optional or custom address
    const [selectedProperty, setSelectedProperty] = useState(null);
    const [customAddress, setCustomAddress] = useState({})

    // Residential Optional
    const [bedrooms, setBedrooms] = useState(0)
    const [bathrooms, setBathrooms] = useState(0)
    const [livingRooms, setLivingRooms] = useState(0)
    const [size, setSize] = useState("")

    // Commercial Optional
    const [propertySummary, setPropertySummary] = useState("")

    // Dropdown Options
    const [reportCategories, setReportCategories] = useState([]);
    const [reportTypes, setReportTypes] = useState([])
    const [reportPurposes, setReportPurposes] = useState([])

    // Address Selector
    const [displayAddress, setDisplayAddress] = useState("");
    const [listAddresses, setListAddresses] = useState([]);
    const [showListAddresses, setShowListAddresses] = useState(false);
    const [onlyPostcodes, setOnlyPostcodes] = useState(false)
    const [noData, setNoData] = useState(false)

    // Manual Commercial Address
    const [showCustomAddressPopup, setShowCustomAddressPopup] = useState(false);

    // Manual Addresses
    const [showManualPopup, setShowManualPopup] = useState(false);

    // Property Details
    const [showPropertyDetails, setShowPropertyDetails] = useState(false);

    useEffect(() => {
        getValuationCategories()
        getValuationPurposes()
    }, [])

    useEffect(() => {
        getValuationPropertyTypes()
        // Reset values on category change
        setSelectedProperty(null)
        setCustomAddress({})
        setDisplayAddress("")
        setReportType(null)
        setReportPurpose(null)
        setBedrooms(0)
        setBathrooms(0)
        setLivingRooms(0)
        setLivingRooms("")
        setPropertySummary("")
    }, [reportCategory])

    useEffect(() => {
        // Reset values on address change
        setReportType(null)
        setReportPurpose(null)
        setBedrooms(0)
        setBathrooms(0)
        setLivingRooms(0)
        setPropertySummary("")
    }, [customAddress || selectedType])

    useEffect(() => {
        // Reset values on Type change
        setReportPurpose(null)
        setBedrooms(0)
        setBathrooms(0)
        setLivingRooms(0)
        setPropertySummary("")
    }, [reportType])

    useEffect(() => {
        // Reset values on purpose change
        setBedrooms(0)
        setBathrooms(0)
        setLivingRooms(0)
        setPropertySummary("")
    }, [reportPurpose])

    const validateFields = () => {
        return reportCategory && reportCategory.id && (selectedProperty || (customAddress && customAddress.postcode)) && reportType && reportType.id && reportPurpose && reportPurpose.id
    }

    const getValuationPurposes = () => {
        let response = val_api.getValuationPurpose();
        response.then(response => {
            setReportPurposes(response.data.types)
        }).catch(err => console.log(err))
    }

    const getValuationCategories = () => {
        let response = val_api.getValuationCategories();
        response.then(response => {
            setReportCategories(response.data.types)
        }).catch(err => console.log(err))
    }

    const getValuationPropertyTypes = () => {
        if (!reportCategory) return
        let response = val_api.getValuationPropertyTypes(reportCategory.id);
        response.then(response => {
            setReportTypes(response.data.types)
        }).catch(err => console.log(err))
    }

    const handleRequestValuation = async () => {
        // Residential Properties through the button at end of bar (manual properties are done through the popup)
        if (reportCategory && reportCategory.id === 1) {
            // Check values otherwise return
            if (!selectedProperty || !selectedProperty.property_id
                || !selectedType || !selectedType.id
                || !reportType || !reportType.id
                || !reportCategory || !reportCategory.id
                || !reportPurpose || !reportPurpose.id) {
                console.log("Invalid Fields")
                return
            }

            let _body = {
                property_id: selectedProperty && selectedProperty.property_id,
                type: selectedType && selectedType.id,
                property_type: reportType && reportType.id,
                report_category: reportCategory && reportCategory.id,
                purpose: reportPurpose && reportPurpose.id,
                details: {
                    "bedrooms": bedrooms && bedrooms ? bedrooms : "-",
                    "bathrooms": bathrooms && bathrooms ? bathrooms : "-",
                    "livingRooms": livingRooms && livingRooms ? livingRooms : "-",
                    "size": size && size ? parseInt(size.replace(",", "")) : 0,
                }
            }
            // Run Normal Evaluation with Data
            evaluate(_body, displayAddress);
        }
        // Run for Commercial Properties
        else if (reportCategory && reportCategory.id === 2) {
            if (!customAddress || !customAddress.postcode || !reportType || !reportType.id || !reportPurpose || !reportPurpose.id) return
            let _body = {
                saon: customAddress && customAddress.saon,
                street: customAddress && customAddress.street,
                town: customAddress && customAddress.town,
                county: customAddress && customAddress.county,
                postcode: customAddress && customAddress.postcode,
                type: selectedType && selectedType.id,
                report_category: reportCategory && reportCategory.id,
                property_type: reportType && reportType.id,
                purpose: reportPurpose && reportPurpose.id,
                manual_property_details: propertySummary && propertySummary ? propertySummary : ""
            }
            manual_evaluate(_body, `${customAddress.saon} ${customAddress.street}, ${customAddress.postcode}`)
        }
    }

    const hideListAddresses = () => {
        setShowListAddresses(false)
    }

    const selectAddress = (event) => AddressSearchFunctions.selectAddress(event.target.id, listAddresses,
        setDisplayAddress, setSelectedProperty, setShowListAddresses, setListAddresses)

    const selectPostcode = (event) => AddressSearchFunctions.selectPostcode(event.target.id, listAddresses,
        setDisplayAddress, setListAddresses, setShowListAddresses, setOnlyPostcodes)

    return (
        <>
            <div className="field-input">
                <div className="valuation-address-container">
                    <div className="valuation-address-bar">
                        <div className="valuation-address-entry">
                            <InputBarDropdown default="Select category"
                                              title="Category"
                                              values={reportCategories}
                                              selectedValue={reportCategory}
                                              setSelectedValue={setReportCategory}
                                              allowInput={reportCategories && reportCategories.length > 0}
                                              firstField={true}/>
                            {reportCategory && reportCategory.id === 1 ?
                                <div className="valuation-field-container address">
                                    <div className="valuation-type-title"> Address </div>
                                    <input type="text"
                                           className="valuation-address-text"
                                           disabled={!(reportCategory && reportCategory)}
                                           value={displayAddress}
                                           id="address-search"
                                           onChange={(ev) => setDisplayAddress(ev.target.value.toUpperCase())}
                                           placeholder="Type postcode"
                                           maxLength="10"
                                           onKeyUp={() => AddressSearchFunctions.handlePostcodeLookup(displayAddress,
                                               setShowListAddresses, setDisplayAddress, setSelectedProperty,
                                               setListAddresses, setOnlyPostcodes)}
                                           onClick={() => setShowListAddresses(true)}
                                           aria-label="Address search by postcode"
                                           data-1p-ignore
                                           data-lpignore
                                    />
                                </div>
                                :
                                <div className="valuation-field-container address">
                                    <div className={reportCategory && reportCategory ? "valuation-type-title" : "valuation-type-title disabled"}>
                                        Address
                                    </div>
                                    <div className={reportCategory && reportCategory ? "valuation-type" : "valuation-type disabled"}
                                        onClick={() => setShowCustomAddressPopup(true)}>
                                        {customAddress && customAddress.saon && customAddress.street && customAddress.postcode
                                            ? `${customAddress.saon} ${customAddress.street}, ${customAddress.postcode}`
                                            : "Input address"
                                        }
                                    </div>
                                    {showCustomAddressPopup && showCustomAddressPopup ?
                                        <>
                                            <CustomAddressPopup setShowCustomAddressPopup={setShowCustomAddressPopup}
                                                                setCustomAddress={setCustomAddress}
                                                                values={customAddress}/>
                                            <div className="invisible-page-overlay"
                                                 onClick={() => setShowCustomAddressPopup(false)}/>
                                        </>
                                        : null}
                                </div>
                            }
                            <InputBarDropdown default="Select type"
                                              title="Type"
                                              values={reportTypes}
                                              selectedValue={reportType}
                                              setSelectedValue={setReportType}
                                              allowInput={selectedProperty && selectedProperty.property_id || customAddress && customAddress.postcode}/>
                            <InputBarDropdown title="Report purpose"
                                              default="Select purpose"
                                              values={reportPurposes}
                                              selectedValue={reportPurpose}
                                              setSelectedValue={setReportPurpose}
                                              allowInput={reportType && reportType}/>
                            <div className="valuation-field-container">
                                <div className={reportPurpose && reportPurpose ? "valuation-type-title link" : "valuation-type-title disabled"}>
                                    Property Details
                                </div>
                                <div className={reportPurpose && reportPurpose ? "valuation-type" : "valuation-type disabled"}
                                     onClick={() => setShowPropertyDetails(true)}>
                                    Know the property?
                                </div>
                                {showPropertyDetails && showPropertyDetails ?
                                    <PropertyDetails category={reportCategory}
                                                     bedrooms={bedrooms}
                                                     setBedrooms={setBedrooms}
                                                     bathrooms={bathrooms}
                                                     setBathrooms={setBathrooms}
                                                     livingRooms={livingRooms}
                                                     setLivingRooms={setLivingRooms}
                                                     size={size}
                                                     setSize={setSize}
                                                     propertySummary={propertySummary}
                                                     setPropertySummary={setPropertySummary}
                                                     setShowPropertyDetails={setShowPropertyDetails}/>

                                    : null
                                }
                            </div>
                            <input type="button"
                                   className={validateFields() ? "valuation-request-button" : "valuation-request-button disabled"}
                                   value={selectedType && "+ " + selectedType.description}
                                       onClick={handleRequestValuation}
                                       disabled={!validateFields()}
                                />
                            </div>
                        </div>
                        <ValuationAddressList show={showListAddresses}
                                              manual_evaluate={manual_evaluate}
                                              selectedType={selectedType}
                                              allowManual={true}
                                              displayAddress={displayAddress}
                                              hide={hideListAddresses.bind(this)}
                                              listAddresses={listAddresses}
                                              onlyPostcodes={onlyPostcodes}
                                              selectAddress={selectAddress.bind(this)}
                                              selectPostcode={selectPostcode.bind(this)}
                                              showManualPopup={showManualPopup}
                                              setShowManualPopup={setShowManualPopup}
                                              reportCategory={reportCategory}
                    />
                </div>
            </div>
        </>
    )
}

export default RICSInputBar