const ValuationTypeMenuItem = (props) => {
    const type = props.type;
    const showNotifications = props.showNotifications

    return(
        <>
            <div className={props.activeType === type.id
                ? "valuation-type-navigation-button active"
                : "valuation-type-navigation-button"}
                 onClick={() => props.setActiveType(type.id)}>
                {type.name.replace("Report", "")}
            </div>
            {showNotifications && type.id === 2 ?
                <div className="notification-container menu"/>
                :null}
        </>
    )
}

export default ValuationTypeMenuItem