import {useEffect, useState} from "react";


const GenericStrCard = (props) => {

    const title = props.title
    const value = props.value
    const setValue = props.setValue

    return(
        <>
            <div className="generic-string-card-container">
                <div className="generic-string-card-header-container">
                    <div className="generic-string-card-header">
                        {title}
                    </div>
                </div>
                <div className="generic-string-text-container">
                    <input type="text" value={value} placeholder="" onChange={(ev) => setValue(ev.target.value)}/>
                </div>
            </div>
        </>
    )
}

export default GenericStrCard