import axios from "axios";

// API LOCATION
const baseUrl = process.env.REACT_APP_API_URL + "/protected/";


let api = axios.create({
    baseURL: baseUrl,
    withCredentials: true,
    responseType: "blob"
});

api.interceptors.response.use(function (response)  {
    return response;
}, function (error) {
    const statusCode = error.response ? error.response.status : null;
    // go to login if error is a 401
    if (statusCode === 401)
        localStorage.removeItem("user");
    if (statusCode === 403)
        window.location.assign("/dashboard")
    return Promise.reject(error);
})

export default api;