import axios from "axios";

const axiosInstance = (url_extension, location_400=null, location_403=null) => {

    // API LOCATION
    const baseUrl = process.env.REACT_APP_API_URL + url_extension;

    let api = axios.create({
        baseURL: baseUrl,
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json'
        }
    });

    api.interceptors.response.use(function (response)  {
        return response;
    }, function (error) {
        const statusCode = error.response ? error.response.status : null;
        if (location_400) {
            console.log("400")
            if (statusCode === 400)
                window.location.assign(location_400)
        }
        // go to login page if error is a 401
        if (statusCode === 401) {
            localStorage.removeItem("user");
            window.location.assign("/login");
        }
        // redirect to page they can view if 403
        if (location_403) {
            if (statusCode === 403)
                window.location.assign(location_403)
        }
        return Promise.reject(error);
    })

    return api
}

export default axiosInstance