import React, {useEffect} from 'react'


const GoogleStreetWidget = (props) => {

    const coords = props.coords;

    return (
        <div className="insight-maps-widget">
            <iframe
                width="620"
                height="300"
                loading="lazy"
                allowFullScreen
                referrerPolicy="no-referrer-when-downgrade"
                src={`https://www.google.com/maps/embed/v1/streetview?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&location=${coords.latitude},${coords.longitude}`}>
            </iframe>
        </div>
    )

}

export default GoogleStreetWidget