import axiosInstance from "../http/axios_instance"
const http = axiosInstance("/protected/admin",  null, "/dashboard")

const getAdminUsers = () => http.get("user");

const inviteAdminUser = (body) => http.post("user", body);

const updateUserStatus = (body) => http.put("user_status", body);

export default {
    getAdminUsers,
    inviteAdminUser,
    updateUserStatus
}