import {useEffect, useState} from "react";
import MainHeader from "../components/MainHeader";
import tick from "../assets/tick.png";
import profapi from "../api/profile";
import {useNavigate} from "react-router-dom";
import stripe_api from "../api/stripe";
import GenericDialog from "../components/GenericDialog";

const UserProfileChangeMembership = (props) => {
    const navigate = useNavigate();
    const [profile, setProfile] = useState();
    const CARD_SINGLE = 1;
    const CARD_MONTHLY = 2;
    const [activeCard, setActiveCard] = useState(CARD_SINGLE);
    const [payg, setPayg] = useState(true);
    const [monthly, setMonthly] = useState(false);

    // Popup dialog
    const [showErrorDialog, setShowErrorDialog] = useState(false);
    const [title, setTitle] = useState("");
    const [error, setError] = useState("");
    const okButton = [{"text":"OK", "id": "1"}];

    const handleCardClick = (ev) => {
        if (!ev.target.id) return;
        if (parseInt(ev.target.id) === CARD_MONTHLY) {
            setActiveCard(CARD_MONTHLY);
            setPayg(false);
            setMonthly(true);
        }
        else {
            setActiveCard(CARD_SINGLE);
            setPayg(true);
            setMonthly(false);
        }
    }

    function handleBackClick() {
        navigate("/profile/details");
    }

    function handleSubmitClick() {
        if (activeCard === CARD_MONTHLY) {
            CreateCheckoutSession()
        }
        else if (activeCard === CARD_SINGLE) {
            CancelMembership()
        }
    }

    const CreateCheckoutSession = () => {
        sessionStorage.setItem("success_from", "/profile/change-membership");
        let response = stripe_api.createCheckout("subscription", "/profile/change-membership");
        response.then(
            response => {
                let url = response.data.checkout_session_url
                window.location.replace(url)
            }
        ).catch((err) => {
            setTitle("Membership Error")
            setError("An error has occurred. Please contact our help team")
            setShowErrorDialog(true)
        })
    }

    const CancelMembership = () => {
        let response = stripe_api.cancelMembership();
        response.then(
            response => {
                let data = response.data.message
                data += ". A cancelled membership will still show as active until the start of the next billing period."
                setTitle("Membership Cancel")
                setError(data)
                setShowErrorDialog(true)
            }
        )
    }

    const handleOKDialogResult = (res) => {
        setShowErrorDialog(false);
        setError(null)
    }

    const getProfile = () => {
        let response = profapi.getProfile();
        response.then(
            response => {
                setProfile(response.data);
                if (response.data.membership_type === 1) {
                    setPayg(true);
                    setMonthly(false);
                }
                else {
                    setPayg(false);
                    setMonthly(true);
                }
            }
        )
    }

    useEffect(() => {
        getProfile()
    }, [])

    return (
        <>
            <MainHeader />
            <div className="change-membership-page-container">
                <div className="change-membership-page-content">
                    <div className="change-membership-page-heading">
                        Change membership
                    </div>
                    <div className="change-membership-cards-container">
                        <div className="change-membership-card-container col link" onClick={handleCardClick} id={CARD_SINGLE}>
                            {activeCard === CARD_SINGLE ?
                                <div className="pricing-card-gradient-border"></div>
                                : null }
                            <div className="pricing-card">
                                <div className="insights" id={CARD_SINGLE}>Pay as you go</div>
                                <div className="price-with-comment" id={CARD_SINGLE}>
                                    <div className="price" id={CARD_SINGLE}>Free</div>
                                    <div className="price-comment" id={CARD_SINGLE}>
                                        (£9.99 per insight)
                                    </div>
                                </div>
                                <div className="description" id={CARD_SINGLE}>
                                    Search for one property and get surrounding market insights.
                                </div>
                                <div className="bullet-container">
                                    <img src={tick} alt="tick icon"/>
                                    <div className="text" id={CARD_SINGLE}>Access your visual insight for 30 days</div>
                                </div>
                                <div className="bullet-container">
                                    <img src={tick} alt="tick icon"/>
                                    <div className="text" id={CARD_SINGLE}>Get downloadable reports</div>
                                </div>
                                <div className="bullet-container">
                                    <img src={tick} alt="tick icon"/>
                                    <div className="text" id={CARD_SINGLE}>£9.99 per insight</div>
                                </div>
                                <div className="membership-selection-container">
                                    {payg ?
                                    <>
                                        <label className="membership-check-container">
                                            {profile && profile.membership_type === 1 ?
                                                <div className="membership-check-label">Your current membership</div>
                                                :
                                                <div className="membership-check-label">Choose this membership</div>
                                            }
                                            <input type="checkbox" checked={true}/>
                                            <span className="checkmark"></span>
                                        </label>
                                    </>
                                    :
                                    <>
                                        <label className="membership-check-container">
                                            {profile && profile.membership_type === 1 ?
                                                <div className="membership-check-label">Your current membership</div>
                                                :
                                                <div className="membership-check-label">Choose this membership</div>
                                            }
                                            <div className="membership-check-background"></div>
                                            <div className="membership-check-overlay"></div>
                                            <input type="checkbox" checked={false}
                                                   onChange={handleCardClick} id={CARD_SINGLE}/>
                                            <span className="checkmark"></span>
                                        </label>
                                    </>}
                                </div>
                            </div>
                        </div>
                        <div className="change-membership-card-container col link" onClick={handleCardClick} id={CARD_MONTHLY}>
                            {activeCard === CARD_MONTHLY ?
                                <div className="pricing-card-gradient-border"></div>
                                : null }
                            <div className="pricing-card">
                                <div className="insights" id={CARD_MONTHLY}>20 insights</div>
                                <div className="price-with-comment" id={CARD_MONTHLY}>
                                    <div className="price" id={CARD_MONTHLY}>£49</div>
                                    <div className="price-comment" id={CARD_MONTHLY}>
                                        /month
                                    </div>
                                </div>
                                <div className="description" id={CARD_MONTHLY}>
                                    Search for 20 properties and get surrounding market insights.
                                </div>
                                <div className="bullet-container">
                                    <img src={tick} alt="tick icon"/>
                                    <div className="text" id={CARD_MONTHLY}>Access your visual insight always</div>
                                </div>
                                <div className="bullet-container">
                                    <img src={tick} alt="tick icon"/>
                                    <div className="text" id={CARD_MONTHLY}>Get insights for 20 properties per month</div>
                                </div>
                                <div className="bullet-container">
                                    <img src={tick} alt="tick icon"/>
                                    <div className="text">Get downloadable reports</div>
                                </div>
                                <div className="membership-selection-container">
                                    {monthly ?
                                        <>
                                            <label className="membership-check-container">
                                                {profile && profile.membership_type === 2 ?
                                                    <div className="membership-check-label">Your current membership</div>
                                                    :
                                                    <div className="membership-check-label">Choose this membership</div>
                                                }
                                                <input type="checkbox" checked={true}/>
                                                <span className="checkmark"></span>
                                            </label>
                                        </>
                                        :
                                        <>
                                            <label className="membership-check-container">
                                                {profile && profile.membership_type === 2 ?
                                                    <div className="membership-check-label">Your current membership</div>
                                                    :
                                                    <div className="membership-check-label">Choose this membership</div>
                                                }
                                                <div className="membership-check-background"></div>
                                                <div className="membership-check-overlay"></div>
                                                <input type="checkbox" checked={false}
                                                       onChange={handleCardClick} id={CARD_MONTHLY}/>
                                                <span className="checkmark"></span>
                                            </label>
                                        </>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="change-membership-buttons-container">
                        <div className="button left link" onClick={handleBackClick}>
                        </div>
                        <div className="button inactive left link" onClick={handleBackClick}>
                            Go back
                        </div>
                        <div className="button link" onClick={handleSubmitClick}>
                            Submit
                        </div>
                    </div>
                </div>
            </div>
            {showErrorDialog ?
                <GenericDialog title={title} text={error} buttons={okButton}
                               result={handleOKDialogResult.bind(this)} /> : null }
        </>
    )

}

export default UserProfileChangeMembership