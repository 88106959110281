import {useEffect, useState} from "react";
import airport from "../assets/flightsmode.png"
import broadband from "../assets/broadband.png"
import bus from "../assets/directions_bus.png"
import cafe from "../assets/cafe.png"
import crime_rate from "../assets/crime_rate.png"
import education from "../assets/education.png"
import health_idx from "../assets/health_idx.png"
import nightlife from "../assets/nightlife.png"
import pollution_level from "../assets/pollution_level.png"
import restaurants from "../assets/restaurants.png"
import stethoscope from "../assets/stethoscope.png"
import subway from "../assets/directions_subway.png"
import supermarket from "../assets/supermarket.png"
import takeaway from "../assets/takeaway.png"
import vet from "../assets/vet.png"
import LocalAreaCardComponent from "../components/LocalAreaCardComponent"
import LocalAreaSchoolsCard from "../components/LocalAreaSchoolsCard";
import LocalAreaEnvironmentCard from "../components/LocalAreaEnvironmentCard";
import local_area_api from "../api/InsightLocalAreaCards"
import GenericDialog from "./GenericDialog";


const InsightLocalAreaDetails = (props) => {

    const propertyId = props.propertyId;
    const summaryId = props.summaryId;
    const params = props.params;
    const valuation = props.valuation;
    const postcode = props.postcode;
    const updateDataReady = props.updateDataReady;
    const [sectionName, setSectionName] = useState();

    // Environment details
    const [pollutionData, setPollutionData] = useState([]);
    const [ultraBroadbandData, setUltraBroadbandData] = useState([]);
    const [healthData, setHealthData] = useState([]);

    // Food details
    const [restaurantData, setRestaurantData] = useState([]);
    const [takeawayData, setTakeawayData] = useState([]);
    const [cafeData, setCafeData] = useState([]);
    const [nightlifeData, setNightlifeData] = useState([]);
    const [supermarketData, setSupermarketData] = useState([]);

    // School details
    const [primarySchoolData, setPrimarySchoolData] = useState([]);
    const [secondarySchoolData, setSecondarySchoolData] = useState([]);

    // Medical details
    const [gpData, setGpData] = useState([]);
    const [vetData, setVetData] = useState([]);
    const [hospitalData, setHospitalData] = useState([]);

    // Transport details
    const [trainStationData, setTrainStationData] = useState([]);
    const [busStopData, setBusStopData] = useState([]);
    const [airportData, setAirportData] = useState([]);

    // Check data collected status
    const [gotEnvironment, setGotEnvironment] = useState([]);
    const [gotFood, setGotFood] = useState([]);
    const [gotSchools, setGotSchools] = useState([]);
    const [gotMedical, setGotMedical] = useState([]);
    const [gotTransport, setGotTransport] = useState([]);

    const GetEnvironmentCards = () => {
        if (!summaryId) return;
        if (!valuation) return;
        if (params === true) {
            let data = valuation.local_area;
            let _restaurant = []
            let _takeaway = []
            let _cafe = []
            let _nightlife = []
            let _supermarkets = []
            let _primaryS = []
            let _secondaryS = []
            let _gp = []
            let _vet = []
            let _hospital = []
            let _train = []
            let _bus = []
            let _airport = []
            for (let i = 0; i < data.length; i++) {
                let _obj = {
                    "name": data[i].name,
                    "rating": data[i].rating,
                    "distance": data[i].distance,
                }
                if (data[i].tab_type === 1) {
                    if (data[i].card_type === 1) {
                        setPollutionData(data[i].rating)
                    } else if (data[i].card_type === 2) {
                        if (data[i].rating === "1.0") {
                            setUltraBroadbandData(true)
                        } else {
                            setUltraBroadbandData(false)
                        }
                    } else if (data[i].card_type === 3) {
                        setHealthData(data[i].rating)
                    }
                } else if (data[i].tab_type === 2) {
                    if (data[i].card_type === 1) {
                        _restaurant.push(_obj)
                    } else if (data[i].card_type === 2) {
                        _takeaway.push(_obj)
                    } else if (data[i].card_type === 3) {
                        _cafe.push(_obj)
                    } else if (data[i].card_type === 4) {
                        _nightlife.push(_obj)
                    } else if (data[i].card_type === 5) {
                        _supermarkets.push(_obj)
                    }
                } else if (data[i].tab_type === 3) {
                    if (data[i].card_type === 1) {
                        _primaryS.push(_obj)
                    } else if (data[i].card_type === 2) {
                        _secondaryS.push(_obj)
                    }
                } else if (data[i].tab_type === 4) {
                    if (data[i].card_type === 1) {
                        _gp.push(_obj)
                    } else if (data[i].card_type === 2) {
                        _vet.push(_obj)
                    } else if (data[i].card_type === 3) {
                        _hospital.push(_obj)
                    }
                } else if (data[i].tab_type === 5) {
                    if (data[i].card_type === 1) {
                        _train.push(_obj)
                    } else if (data[i].card_type === 2) {
                        _bus.push(_obj)
                    } else if (data[i].card_type === 3) {
                        _airport.push(_obj)
                    }
                }
            }
            setRestaurantData(_restaurant)
            setTakeawayData(_takeaway)
            setCafeData(_cafe)
            setNightlifeData(_nightlife)
            setSupermarketData(_supermarkets)
            setGotFood(true);

            setPrimarySchoolData(_primaryS)
            setSecondarySchoolData(_secondaryS)
            setGotSchools(true);

            setGpData(_gp)
            setVetData(_vet)
            setHospitalData(_hospital)
            setGotMedical(true);

            setTrainStationData(_train)
            setBusStopData(_bus)
            setAirportData(_airport)
            setGotTransport(true);

            setGotEnvironment(true);
        } else {
            let body = {
                "postcode": postcode,
                "summary_id": summaryId,
                "coordinates": {
                    "longitude": valuation.address.longitude,
                    "latitude": valuation.address.latitude
                },
                "section": "Environment",
            }
            let response = local_area_api.getLocalAreaCards(body)
            response.then(response => {
                setPollutionData(response.data.aqi)
                if (response.data.ultrafast_broadband === "1.0") {
                    setUltraBroadbandData(true)
                } else {
                    setUltraBroadbandData(false)
                }
                setHealthData(response.data.health_index)
                setGotEnvironment(true);
            }).catch(error => {
                console.log(error)
                setPollutionData([])
                setUltraBroadbandData(false)
                setHealthData([])
                setGotEnvironment(true);
            })
        }
    }

    const GetFoodCards = () => {
        if (!summaryId) return;
        if (params === true) return;
        let body = {
            "postcode": postcode,
            "summary_id": summaryId,
            "coordinates": {
                "longitude": valuation.address.longitude,
                "latitude": valuation.address.latitude
            },
            "section": "Food and drink",
        }
        let response = local_area_api.getLocalAreaCards(body)
        response.then(response => {
            setRestaurantData(response.data.restaurant)
            setTakeawayData(response.data.take_away)
            setCafeData(response.data.cafe)
            setNightlifeData(response.data.night_life)
            setSupermarketData(response.data.super_markets)
            setGotFood(true);
        }).catch(error => {
            console.log(error)
            setRestaurantData([])
            setTakeawayData([])
            setCafeData([])
            setNightlifeData([])
            setSupermarketData([])
            setGotFood(true);
        })
    }

    const GetSchoolCards = () => {
        if (!summaryId) return;
        if (params === true) return;
        let body = {
            "postcode": postcode,
            "summary_id": summaryId,
            "coordinates": {
                "longitude": valuation.address.longitude,
                "latitude": valuation.address.latitude
            },
            "section": "Schools",
        }
        let response = local_area_api.getLocalAreaCards(body)
        response.then(response => {
            setPrimarySchoolData(response.data.primary_schools)
            setSecondarySchoolData(response.data.secondary_schools)
            setGotSchools(true);
        }).catch(error => {
            console.log(error)
            setPrimarySchoolData([])
            setSecondarySchoolData([])
            setGotSchools(true);
        })
    }

    const GetMedicalCards = () => {
        if (!summaryId) return;
        if (params === true) return;
        let body = {
            "postcode": postcode,
            "summary_id": summaryId,
            "coordinates": {
                "longitude": valuation.address.longitude,
                "latitude": valuation.address.latitude
            },
            "section": "Medical",
        }
        let response = local_area_api.getLocalAreaCards(body)
        response.then(response => {
            setGpData(response.data.gps)
            setVetData(response.data.vets)
            setHospitalData(response.data.hospitals)
            setGotMedical(true);
        }).catch(error => {
            console.log(error)
            setGpData([])
            setVetData([])
            setHospitalData([])
            setGotMedical(true);
        })
    }

    const GetTransportCards = () => {
        if (!summaryId) return;
        if (params === true) return;
        let body = {
            "postcode": postcode,
            "summary_id": summaryId,
            "coordinates": {
                "longitude": valuation.address.longitude,
                "latitude": valuation.address.latitude
            },
            "section": "Transport",
        }
        let response = local_area_api.getLocalAreaCards(body)
        response.then(response => {
            setTrainStationData(response.data.train_stations)
            setBusStopData(response.data.bus_stops)
            setAirportData(response.data.airport)
            setGotTransport(true);
        }).catch(error => {
            console.log(error)
            setTrainStationData([])
            setBusStopData([])
            setAirportData([])
            setGotTransport(true);
        })
    }

    useEffect(() => {
        if (gotEnvironment &&
            gotFood &&
            gotSchools &&
            gotMedical &&
            gotTransport)
        {
            updateDataReady();
        }
    }, [gotEnvironment, gotFood, gotSchools, gotMedical, gotTransport])

    useEffect(() => {
        if (!props.setSelection) return;
        setSectionName(props.setSelection);

    }, [props.setSelection]);

    useEffect(() => {
        if (!summaryId) return;
        GetEnvironmentCards()
        GetFoodCards()
        GetSchoolCards()
        GetMedicalCards()
        GetTransportCards()
    },[summaryId])

    return (
        <>
            {sectionName === "Environment" && pollutionData ?
                <div className="insight-local-flex-container">
                    <LocalAreaEnvironmentCard title={"Air quality:"}
                                              image={pollution_level}
                                              data={pollutionData}/>
                    <LocalAreaEnvironmentCard title={"Ultrafast broadband:"}
                                              image={broadband}
                                              data={ultraBroadbandData}/>
                    <LocalAreaEnvironmentCard title={"Health index:"}
                                              image={health_idx}
                                              data={healthData}/>
                </div>
                : null}
            {sectionName === "Food and drink" && restaurantData ?
                <div className="insight-local-grid-container">

                    <LocalAreaCardComponent title={"Restaurants"}
                                            image={restaurants}
                                            data={restaurantData}/>
                    <LocalAreaCardComponent title={"Takeaways"}
                                            image={takeaway}
                                            data={takeawayData}/>
                    <LocalAreaCardComponent title={"Cafe"}
                                            image={cafe}
                                            data={cafeData}/>
                    <LocalAreaCardComponent title={"Nightlife"}
                                            image={nightlife}
                                            data={nightlifeData}/>
                    <LocalAreaCardComponent title={"Supermarkets"}
                                            image={supermarket}
                                            data={supermarketData}/>

                </div>
                : null}
            {sectionName === "Schools" ?
                <div className="insight-local-grid-container">
                    <LocalAreaSchoolsCard title={"Primary schools nearby"}
                                          image={education}
                                          data={primarySchoolData}/>
                    <LocalAreaSchoolsCard title={"Secondary schools nearby"}
                                          image={education}
                                          data={secondarySchoolData}/>
                </div>
                : null}
            {sectionName === "Medical" ?
                <div className="insight-local-grid-container">
                    <LocalAreaCardComponent title={"GPs nearby"}
                                            image={stethoscope}
                                            data={gpData}/>
                    <LocalAreaCardComponent title={"Vets nearby"}
                                            image={vet}
                                            data={vetData}/>
                    <LocalAreaCardComponent title={"Hospitals nearby"}
                                            image={stethoscope}
                                            data={hospitalData}/>
                </div>
                : null}
            {sectionName === "Transport" ?
                <div className="insight-local-grid-container">
                    <LocalAreaCardComponent title={"Train stations nearby"}
                                            image={subway}
                                            data={trainStationData}/>
                    <LocalAreaCardComponent title={"Bus stops nearby"}
                                            image={bus}
                                            data={busStopData}/>
                    <LocalAreaCardComponent title={"Airports nearby"}
                                            image={airport}
                                            data={airportData}/>
                </div>
                : null}
        </>
    )
}

export default InsightLocalAreaDetails