import close from "../../assets/close_small.png";
import GenericStrCard from "../GenericStrCard";
import GenericDropdownCard from "../GenericDropdownCard";
import check from "../../assets/check_small.png";
import GenericIntCard from "../GenericIntCard";
import GenericCurrencyCard from "../GenericCurrencyCard";
import ButtonLoadingWheel from "../ButtonLoadingWheel";
import {useEffect, useState} from "react";
import property_api from "../../api/property";


const CustomAddressPopup = (props) => {
    const setShowCustomAddressPopup = props.setShowCustomAddressPopup
    const setCustomAddress = props.setCustomAddress

    const [saon, setSaon] = useState("")
    const [street, setStreet] = useState("")
    const [town, setTown] = useState("")
    const [county, setCounty] = useState("")
    const [postcode, setPostcode] = useState("")


    useEffect(() => {
        if (!props.values) return
        setSaon(props.values.saon)
        setStreet(props.values.street)
        setTown(props.values.town)
        setCounty(props.values.county)
        setPostcode(props.values.postcode)
    }, [props.values])

    const handleSave = () => {
        if (!ValidateFields) {
            return
        }
        let v = {
            "saon": saon.trim(),
            "street": street.trim(),
            "town": town.trim(),
            "county": county.trim(),
            "postcode": postcode.toLocaleUpperCase().trim(),
        }
        setCustomAddress(v)
        setShowCustomAddressPopup(false)
    }

    const ValidateFields = () => {
        return saon && street && town && county && postcode;
    }

    return (
        <>
            <div className="page-mask dark"/>
            <div className="popup-holding-container">
                <div className="manual-address-popup-container">
                    <div className="manual-address-header-container">
                        <div className="manual-address-header">
                            Add address
                        </div>
                        <div className="manual-address-header-close link" onClick={() => setShowCustomAddressPopup(false)}>
                            <img src={close} alt="X"/>
                        </div>
                    </div>
                    <div className="manual-adress-content-container" id="scrollbar2">
                        <GenericStrCard title="Company name, unit name, etc*" value={saon} setValue={setSaon}/>
                        <GenericStrCard title="Street name*" value={street} setValue={setStreet}/>
                        <div className="manual-address-locality-container">
                            <GenericStrCard title="Town*" value={town} setValue={setTown} />
                            <GenericStrCard title="County*" value={county} setValue={setCounty} />
                            <div className="generic-string-card-container">
                                <div className="generic-string-card-header-container">
                                    <div className="generic-string-card-header">
                                        Postcode*
                                    </div>
                                </div>
                                <div className="generic-string-text-container">
                                    <input type="text" value={postcode} placeholder=""
                                           onChange={(ev) => setPostcode(ev.target.value)}/>
                                </div>
                            </div>
                        </div>
                        <div className={ValidateFields()
                            ? "manual-address-submit-button link" : "manual-address-submit-button disabled"}
                             onClick={() => handleSave()}>
                            Save
                        </div>
                        <div className="manual-address-footer">
                            *Indicates mandatory field
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CustomAddressPopup;