import {useEffect, useState} from "react";
/*import tick
import cross*/

const LocalAreaEnvironmentCard = (props) => {

    const title = props.title;
    const image = props.image;
    const data = props.data;
    const [tickCross, setTickCross] = useState()


    useEffect(() => {
        if (title !== "Ultrafast broadband:") return;
        if (data === true) {
            setTickCross("✓")
        } else {
            setTickCross("X")
        }
    },[title,data])

    return (
        <>
            <div className="insight-local-flex-item-container">
                <div className="insight-local-environment-card-container">
                    <div className="insight-local-environment-card">
                        <div className="insight-local-card-environment-icon-container">
                            <img src={image} className="insight-local-card-environment-icon" alt="icon"/>
                        </div>
                        <div className="insight-local-environment-card-title-container">
                            <div className="insight-local-environment-card-title">
                                {title}
                            </div>
                        </div>
                        <div className="insight-local-environment-card-data-container">
                            {title==="Ultrafast broadband:" ?
                                <div className="insight-local-environment-card-data">
                                    {tickCross}
                                </div>
                            :
                                <>
                                    {data.length ?
                                        <div className="insight-local-environment-card-data">
                                            {data}
                                        </div>
                                        :
                                        <div className="insight-local-environment-card-data">
                                            No Result
                                        </div>
                                    }
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LocalAreaEnvironmentCard