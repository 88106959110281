import styles from "../../models/Styles";
import format from "../../utils/Address";
import arrow from "../../assets/arrow_forwards.png";
import ManualAddressPopup from "../ManualAddressPopup";


const ValuationAddressList = (props) => {

    const width = props.width && props.width ? props.width : "full";

    const reportCategory = props.reportCategory

    const listAddresses = props.listAddresses
    const showListAddresses = props.show
    const hideListAddresses = props.hide

    const onlyPostcodes = props.onlyPostcodes
    const displayAddress = props.displayAddress

    const selectPostcode = props.selectPostcode
    const selectAddress = props.selectAddress

    const selectedType = props.selectedType

    // Manual Addresses
    const allowManualAddress = props.allowManual && props.allowManual ? props.allowManual : false
    const showManualPopup = props.showManualPopup
    const setShowManualPopup = props.setShowManualPopup
    const manual_evaluate = props.manual_evaluate

    return (
        <div className="valuation-address-list-flex" style={showListAddresses ? styles.show : styles.hide}>
            <div className={"valuation-address-list-container " + width} style={showListAddresses && displayAddress.length > 0 ? styles.show : styles.hide}>
                <div className="valuation-address-list">
                    {listAddresses && listAddresses.map((address, idx) => {
                        return (
                            <>
                                {onlyPostcodes ?
                                    <div className="valuation-list-address link" id={idx} key={idx}
                                         onClick={selectPostcode}>
                                        {format.formatPostCodeAndCount(address)}
                                    </div>
                                    :
                                    <div className="valuation-list-address link" id={idx} key={idx}
                                         onClick={selectAddress}>
                                        {format.formatSearchAddress(address)}
                                    </div>
                                }
                            </>
                        )
                    })}
                    {listAddresses && displayAddress && listAddresses.length === 0 ?
                        <div className="valuation-list-address">
                            {displayAddress + " - no properties found"}
                        </div>
                        : null
                    }
                </div>
                {displayAddress && allowManualAddress && allowManualAddress ?
                    <>
                        <div className="valuation-list-address flex link" onClick={() => {
                            setShowManualPopup(true)
                            hideListAddresses()
                        }}>
                            Can't find the address you're looking for?
                            <div className="valuation-list-button-container">
                                Submit manual address
                                <img src={arrow} alt={">"}/>
                            </div>
                        </div>

                    </>
                    : null
                }

            </div>
            {showManualPopup ?
                <ManualAddressPopup manual_evaluate={manual_evaluate}
                                    selectedType={selectedType}
                                    closeManualPopup={setShowManualPopup.bind(this)}
                                    reportCategory={reportCategory}/>
                : null}
            <div className="invisible-page-overlay"
                 style={showListAddresses || showManualPopup ? styles.show : styles.hide}
                 onClick={() => hideListAddresses()}/>
        </div>


    )

}

export default ValuationAddressList