import {useEffect, useState} from "react";
import ValuerAmendDropdownCard from "./valuer/ValuerAmendDropdownCard";
import GenericStrCard from "./GenericStrCard";
import check from "../assets/check_small.png"
import GenericIntCard from "./GenericIntCard";
import GenericCurrencyCard from "./GenericCurrencyCard";
import property_api from "../api/property"
import GenericDropdownCard from "./GenericDropdownCard";
import close from "../assets/close_small.png"
import ButtonLoadingWheel from "./ButtonLoadingWheel";
import val_api from "../api/valuation";
import {post} from "axios";


const ManualAddressPopup = (props) => {
    const manual_evaluate = props.manual_evaluate
    const closeManualPopup = props.closeManualPopup
    const selectedType = props.selectedType

    // Dropdown Field Options
    const [reportTypes, setReportTypes] = useState([])
    const [reportPurposes, setReportPurposes] = useState([])

    // Required Fields
    const [saon, setSaon] = useState("")
    const [street, setStreet] = useState("")
    const [town, setTown] = useState("")
    const [county, setCounty] = useState("")
    const [postcode, setPostcode] = useState("")
    const [reportType, setReportType] = useState()
    const [reportPurpose, setReportPurpose] = useState()
    const reportCategory = props.reportCategory

    // Optional Fields
    const [showAdditionalInfo, setShowAdditionalInfo] = useState(false)
    const [bedrooms, setBedrooms] = useState("")
    const [bathrooms, setBathrooms] = useState("")
    const [livingRooms, setLivingRooms] = useState("")
    const [kitchens, setKitchens] = useState("")
    const [size, setSize] = useState("")
    const [extraDetails, setExtraDetails] = useState("")
    const [rent, setRent] = useState("")

    const [processing, setProcessing] = useState(false)

    useEffect(() => {
        getValuationPurposes()
    }, [])

    useEffect(() => {
        getValuationPropertyTypes()
    }, [reportCategory])

    const getValuationPurposes = () => {
        let response = val_api.getValuationPurpose();
        response.then(response => {
            setReportPurposes(response.data.types)
        }).catch(err => console.log(err))
    }

    const getValuationPropertyTypes = () => {
        if (!reportCategory) return
        let response = val_api.getValuationPropertyTypes(reportCategory.id);
        response.then(response => {
            setReportTypes(response.data.types)
        }).catch(err => console.log(err))
    }

    const FieldValidation = () => {
        return (saon && street && town && county && postcode && reportType && reportType.id && reportPurpose && reportPurpose.id && reportCategory && reportCategory.id)
    }

    const handleSubmit = () => {
        if (!FieldValidation() || processing) return
        setProcessing(true)
        submitAddress()
    }

    const submitAddress = () => {
        if (!FieldValidation()) return
        let body = {
            saon: saon,
            street: street,
            town: town,
            county: county,
            postcode: postcode.toLocaleUpperCase(),
            purpose: reportPurpose.id,
            report_category: reportCategory.id,
            type: selectedType.id,
            property_type: reportType.id,
            additional_info: {
                selected: showAdditionalInfo,
                bedrooms: parseInt(bedrooms),
                bathrooms: parseInt(bathrooms),
                livingRooms: parseInt(livingRooms),
                kitchens: parseInt(kitchens),
                size: parseInt(size),
                extra_details: extraDetails,
                rent: parseInt(rent)
            }
        }
        if (bedrooms === undefined || bedrooms === null || bedrooms === "") body.additional_info.bedrooms = "-"
        if (bathrooms === undefined || bathrooms === null || bathrooms === "") body.additional_info.bathrooms = "-"
        if (livingRooms === undefined || livingRooms === null || livingRooms === "") body.additional_info.living_rooms = "-"
        if (kitchens === undefined || kitchens === null || kitchens === "") body.additional_info.kitchens = "-"
        if (size === undefined || size === null || size === "") body.additional_info.size = 0
        if (extraDetails === undefined || extraDetails === null) body.additional_info.extra_details = ""
        if (rent === undefined || rent === null || rent === "") body.additional_info.rent = 0
        
        let _addr_str = saon + " " + street + ", " + town + ", " + county + ", " + postcode.toLocaleUpperCase()

        manual_evaluate(body, _addr_str)
        setProcessing(false)
        closeManualPopup(false)
    }

    return(
        <>
            <div className="page-mask dark"/>
            <div className="popup-holding-container">
                <div className="manual-address-popup-container">
                    <div className="manual-address-header-container">
                        <div className="manual-address-header">
                            Add manual address
                        </div>
                        <div className="manual-address-header-close link" onClick={() => closeManualPopup(false)}>
                            <img src={close} alt="X"/>
                        </div>
                    </div>
                    <div className="manual-adress-content-container" id="scrollbar2">
                        <GenericStrCard title="Flat number, house number or name*" value={saon} setValue={setSaon}/>
                        <GenericStrCard title="Street name*" value={street} setValue={setStreet}/>
                        <div className="manual-address-locality-container">
                            <GenericStrCard title="Town*" value={town} setValue={setTown} />
                            <GenericStrCard title="County*" value={county} setValue={setCounty} />
                            <div className="generic-string-card-container">
                                <div className="generic-string-card-header-container">
                                    <div className="generic-string-card-header">
                                        Postcode*
                                    </div>
                                </div>
                                <div className="generic-string-text-container">
                                    <input type="text" value={postcode} placeholder=""
                                           onChange={(ev) => setPostcode(ev.target.value)}/>
                                </div>
                            </div>
                        </div>
                        <div className="manual-address-purpose-container">
                            <GenericDropdownCard title={"Report purpose*"} default="Select purpose" value={reportPurpose}
                                                 options={reportPurposes} setValue={setReportPurpose} />
                            <GenericDropdownCard title={"Type*"} default="Select type" value={reportType}
                                                 options={reportTypes} setValue={setReportType} />
                        </div>
                        <div className="manual-address-additional-info-container">
                            <div className="manual-address-additional-info-checkbox-container link"
                                 onClick={() => setShowAdditionalInfo(!showAdditionalInfo)}>
                                {showAdditionalInfo
                                    ? <img src={check} alt={"tick"} />
                                    :null
                                }
                            </div>
                            <div className="manual-address-additional-info-text">
                                I know additional information about this address
                            </div>
                        </div>
                        {showAdditionalInfo ?
                            <>
                                <div className="manual-address-locality-container">
                                    <GenericIntCard title="Bedrooms" value={bedrooms} setValue={setBedrooms}/>
                                    <GenericIntCard title="Bathrooms" value={bathrooms} setValue={setBathrooms}/>
                                    <GenericIntCard title="Living rooms" value={livingRooms} setValue={setLivingRooms}/>
                                    <GenericIntCard title="Kitchens" value={kitchens} setValue={setKitchens}/>
                                    <GenericIntCard title="Sq ft" value={size} setValue={setSize} />
                                </div>
                                <div className="manual-address-comment">
                                    <div className="manual-address-comment-title">
                                        Details of any outbuildings or annexes, eg garages, garden shed or office
                                        <div className="manual-address-comment-title-counter">({extraDetails.length}/300)</div>
                                    </div>
                                    <div className="manual-address-comment-text">
                                        <textarea value={extraDetails} onChange={(ev) => {
                                            if (ev.target.value.length > 300) return
                                            setExtraDetails(ev.target.value)
                                        }}/>
                                    </div>
                                </div>
                                <GenericCurrencyCard title={"If tenanted, how much rent is tenant currently paying per month?"}
                                                     value={rent}
                                                     setValue={setRent} />
                            </>
                            :null}
                        <div className={FieldValidation() ? "manual-address-submit-button link" : "manual-address-submit-button disabled"} onClick={handleSubmit}>
                            {processing
                                ? <ButtonLoadingWheel show={processing && processing}/>
                                : "Get RICS Regulated Valuation Report"
                            }
                        </div>
                        <div className="manual-address-footer">
                            *Indicates mandatory field
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ManualAddressPopup