const SUMMARY_SECTION=0
const REPORTS_SECTION=1
const PEOPLE_SECTION=2
const CREDIT_SECTION=3
const REPORT_VALUATION_SECTION=4
const REPORT_RICS_SECTION=5
const REPORT_INSIGHT_SECTION=6
const REPORT_ALL_SECTION=7

export default {
    SUMMARY_SECTION,
    REPORTS_SECTION,
    PEOPLE_SECTION,
    CREDIT_SECTION,
    REPORT_VALUATION_SECTION,
    REPORT_RICS_SECTION,
    REPORT_INSIGHT_SECTION,
    REPORT_ALL_SECTION
}