import {useEffect, useState} from "react";
import avatar from "../assets/Avatar.png"
import dateUtils from "../utils/DateUtils";

const UserComment = (props) => {

    const comment = props.comment;
    const date = props.date;

    return (
        <>
            <div className="valuation-user-comment-container">
                <div className="valuation-comment-text-container">
                    <div className="valuation-name-container user">
                        You
                    </div>
                    <div className="valuation-message-container user">
                        <div className="valuation-message-text-container">
                            {comment}
                        </div>
                        <div className="valuation-message-date-container">
                            <div className="valuation-info-time-container">
                                {dateUtils.display_date_time(date*1000)}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="valuation-comment-img-container user">
                    <img src={avatar} alt="avatar"/>
                </div>
            </div>
        </>
    )
}

export default UserComment