import axiosInstance from "../http/axios_instance"
const http = axiosInstance("/protected/orgadmin/",  null, "/dashboard")

const createTeam = (team) => http.post("/teams", team);

const getTeams = () => http.get("/teams");

export default {
    createTeam,
    getTeams
}