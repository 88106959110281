import axiosInstance from "../http/axios_instance"
const http = axiosInstance("/protected/orgadmin/",  null, "/dashboard")

const inviteAdminUser = (body) => http.post("user", body);

const updateUserStatus = (body) => http.put("user_status", body);

export default {
    inviteAdminUser,
    updateUserStatus
}