import MainHeader from "../components/MainHeader";
import ProfilePanel from "../components/ProfilePanel";
import profapi from "../api/profile";
import {useEffect, useState} from "react";

const UserProfile = () => {
    const [profile, setProfile] = useState();

    const getProfile = () => {
        let response = profapi.getProfile();
        response.then(
            response => {
                setProfile(response.data);
            }
        )
    }

    useEffect(() => {
        getProfile()
    }, [])

    return (
        <>
            <MainHeader />
            <div className="user-profile-container">
                <div className="user-profile-title">Profile</div>
                <ProfilePanel profile={profile}/>
            </div>
        </>
    )
}

export default UserProfile