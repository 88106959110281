import orep_api from "../api/orgadmin/orgadmin_report";
import adrep_api from "../api/admin/admin_report";
import {useEffect, useState} from "react";

const ManagementReportGrids = (props) => {
    const PAGE_SIZE = 8
    const report_type = props.report_type;
    const date_range = props.date_range;
    const updateFilter = props.updateFilter;
    const orgId = props.orgId;

    const [reportData, setReportData] = useState();



    const getOrgReportData = () => {
        let body = {
            "report_type": report_type,
            "date_range": date_range
        }
        let response = orep_api.getManagementReports(body);
        response.then(
            response => {
                setReportData(response.data);
            }
        )
    }

    const getAdminReportData = () => {
        let body = {
            "report_type": report_type,
            "date_range": date_range
        }
        let response = adrep_api.getManagementReports(orgId, body);
        response.then(
            response => {
                setReportData(response.data);
            }
        )
    }

    useEffect(() => {
        if (orgId){
            getAdminReportData();
        }
        else {
            getOrgReportData();
        }
    }, [report_type, date_range])

    const handleAllReportsClick = () => {
        let f = initFilter();
        if (report_type > 0){
            f.filters.report_types = [];
            f.filters.report_types.push(report_type);
        }
        updateFilter(f);
    }

    const handleUserReportClick = (ev) => {
        if (!ev.target.id) return;
        let f = initFilter();
        f.filters.team_members = [];
        f.filters.team_members.push(ev.target.id);
        if (report_type > 0){
            f.filters.report_types = [];
            f.filters.report_types.push(report_type);
        }
        updateFilter(f);
    }

    const handleTeamReportClick = (ev) => {
        if (!ev.target.id) return;
        let f = initFilter();
        f.filters.teams = [];
        f.filters.teams.push(ev.target.id);
        if (report_type > 0){
            f.filters.report_types = [];
            f.filters.report_types.push(report_type);
        }
        updateFilter(f);
    }

    const initFilter = () => {
        let f = {};
        f.filters = {};
        f.filters.page = 1;
        f.filters.page_size = PAGE_SIZE;
        f.filters.organisations = [orgId];
        f.filters.date_range=[];
        let dr = {};
        dr.id = date_range;
        f.filters.date_range.push(dr);
        return f;
    }

    return (
        <>
            <div className="management-report-grids">
                <div className="section">
                    <h1>All company reports</h1>
                    <div className="report-total-container">
                        <h2>TOTAL REPORTS</h2>
                        <label>{reportData && reportData.total}</label>
                    </div>
                    <button onClick={handleAllReportsClick}>See full company report list</button>
                </div>
                <div className="section">
                    <h1>Reports by team</h1>
                    <div className="management-reports-grid-container">
                        <div className="management-reports-grid-headers">
                            <div className="name col">Name</div>
                            <div className="count col">Reports</div>
                            <div className="reports col"></div>
                        </div>
                        <div className="management-reports-grid-rows">
                            {reportData && reportData.teams.map((team) => {
                                return (
                                    <>
                                        <div className="management-reports-grid-row">
                                            <div className="name col">{team.team_name}</div>
                                            <div className="count col">{team.count}</div>
                                            <div className="reports col link" onClick={handleTeamReportClick}
                                            id={team.team_id}>See reports</div>
                                        </div>
                                    </>
                                )
                            })}
                        </div>
                    </div>
                </div>
                <div className="section">
                    <h1>Reports by employee</h1>
                    <div className="management-reports-grid-container">
                        <div className="management-reports-grid-headers">
                            <div className="name col">Name</div>
                            <div className="count col">Reports</div>
                            <div className="reports col"></div>
                        </div>
                        <div className="management-reports-grid-rows">
                            {reportData && reportData.users.map((user, idx) => {
                                return (
                                    <>
                                        <div className="management-reports-grid-row">
                                            <div className="name col">{user.firstname} {user.lastname}</div>
                                            <div className="count col">{user.count}</div>
                                            <div className="reports col link" onClick={handleUserReportClick}
                                            id={user.id}>See reports</div>
                                        </div>
                                    </>
                                )
                            })}
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default ManagementReportGrids