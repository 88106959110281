import MainHeader from "../components/MainHeader";
import tick from "../assets/tick.png";
import {useState} from "react";
import stripe_api from "../api/stripe";
import GenericDialog from "../components/GenericDialog";

const SelectPricing = () => {
    const CARD_SINGLE = 1;
    const CARD_MONTHLY = 2;
    const [activeCard, setActiveCard] = useState(CARD_SINGLE);

    // Popup dialog
    const [showErrorDialog, setShowErrorDialog] = useState(false);
    const [title, setTitle] = useState("");
    const [error, setError] = useState("");
    const okButton = [{"text":"OK", "id": "1"}];

    const handleCardClick = (ev) => {
        if (!ev.target.id) return;
        if (parseInt(ev.target.id) === CARD_MONTHLY) {
            setActiveCard(CARD_MONTHLY);
        }
        else {
            setActiveCard(CARD_SINGLE);
        }
    }

    function handleSingleInsightClick() {
        setActiveCard(CARD_SINGLE);
        CreateCheckoutSession("token")
    }

    function handleMonthlyInsightClick() {
        setActiveCard(CARD_MONTHLY);
        CreateCheckoutSession("subscription")
    }

    const CreateCheckoutSession = (_type) => {
        sessionStorage.setItem("success_from", "/valuation");
        let response = stripe_api.createCheckout(_type, "/pricing");
        response.then(
            response => {
                let url = response.data.checkout_session_url
                window.location.replace(url)
            }
        ).catch((err) => {
            setTitle("Membership Error")
            setError("An error has occurred. Please contact our help team")
            setShowErrorDialog(true)
        })
    }

    const handleOKDialogResult = (res) => {
        setShowErrorDialog(false);
        setError(null)
    }

    return (
        <>
            <MainHeader />
            <div className="pricing-page-container">
                <div className="pricing-page-content">
                    <div className="pricing-page-heading">
                        Simple, easy pricing
                    </div>
                    <div className="pricing-page-subheading">
                        Choose to get a one-off insight, or subscribe to get more market insights
                        and any-time access to your visual insight.
                    </div>
                    <div className="pricing-cards-container">
                        <div className="pricing-card-container col link" onClick={handleCardClick} id={CARD_SINGLE}>
                            {activeCard === CARD_SINGLE ?
                                <div className="pricing-card-gradient-border"></div>
                                : null }
                            <div className="pricing-card">
                                <div className="insights" id={CARD_SINGLE}>1 insight</div>
                                <div className="price" id={CARD_SINGLE}>£9.99</div>
                                <div className="description" id={CARD_SINGLE}>
                                    Search for one property and get surrounding market insights.
                                </div>
                                <div className="bullet-container">
                                    <img src={tick} alt="tick icon"/>
                                    <div className="text" id={CARD_SINGLE}>Access your visual insight for 30 days</div>
                                </div>
                                <div className="bullet-container">
                                    <img src={tick} alt="tick icon"/>
                                    <div className="text" id={CARD_SINGLE}>Get downloadable reports</div>
                                </div>
                                {activeCard === CARD_SINGLE ?
                                    <div className="button" onClick={handleMonthlyInsightClick}>
                                        Purchase insight
                                    </div>
                                    :
                                    <div className="button unselected" onClick={handleMonthlyInsightClick}>
                                        <div className="button-unselected-background">
                                            Purchase insight
                                        </div>

                                    </div>
                                }
                            </div>
                        </div>
                        <div className="pricing-card-container col link" onClick={handleCardClick} id={CARD_MONTHLY}>
                            {activeCard === CARD_MONTHLY ?
                                <div className="pricing-card-gradient-border"></div>
                                : null }
                            <div className="pricing-card">
                                <div className="insights" id={CARD_MONTHLY}>20 insights</div>
                                <div className="price-with-comment" id={CARD_MONTHLY}>
                                    <div className="price" id={CARD_MONTHLY}>£49</div>
                                    <div className="price-comment" id={CARD_MONTHLY}>
                                        /month
                                    </div>
                                </div>
                                <div className="description" id={CARD_MONTHLY}>
                                    Search for 20 properties and get surrounding market insights.
                                </div>
                                <div className="bullet-container">
                                    <img src={tick} alt="tick icon"/>
                                    <div className="text" id={CARD_MONTHLY}>Access your visual insight always</div>
                                </div>
                                <div className="bullet-container">
                                    <img src={tick} alt="tick icon"/>
                                    <div className="text" id={CARD_MONTHLY}>Get insights for 20 properties per month</div>
                                </div>
                                <div className="bullet-container">
                                    <img src={tick} alt="tick icon"/>
                                    <div className="text">Get downloadable reports</div>
                                </div>
                                {activeCard === CARD_MONTHLY ?
                                    <div className="button" onClick={handleMonthlyInsightClick}>
                                        Get started
                                    </div>
                                    :
                                    <div className="button unselected" onClick={handleMonthlyInsightClick}>
                                        <div className="button-unselected-background">
                                            Get started
                                        </div>

                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            {showErrorDialog ?
                <GenericDialog title={title} text={error} buttons={okButton}
                               result={handleOKDialogResult.bind(this)} /> : null }
        </>
    )
}

export default SelectPricing