import ManagementReportGrids from "./ManagementReportGrids";
import {useState} from "react";
import DateRanges from "../models/DateRanges";

const ManagementReports = (props) => {
    const report_type = props.report_type;
    const heading = props.heading;
    const orgId = props.orgId;
    const updateFilter = props.updateFilter;
    const [dateRange, setDateRange] = useState(1);

    const handleDateTabClick = (ev) => {
        if (!ev.target.id) return;
        setDateRange(parseInt(ev.target.id));
    }

    return (
        <>
            <div className="management-reports-container">
                <div className="management-reports-heading">
                    <div className="management-reports-title">{heading}</div>
                    <div className="management-reports-menu">
                        <div className={dateRange === DateRanges.DATE_RANGE_TODAY ? "item active link" : "item link"}
                             id={DateRanges.DATE_RANGE_TODAY}
                             onClick={handleDateTabClick}>Today</div>
                        <div className={dateRange === DateRanges.DATE_RANGE_THIS_WEEK ? "item active link" : "item link"}
                             id={DateRanges.DATE_RANGE_THIS_WEEK}
                             onClick={handleDateTabClick}>This week</div>
                        <div className={dateRange === DateRanges.DATE_RANGE_THIS_MONTH ? "item active link" : "item link"}
                             id={DateRanges.DATE_RANGE_THIS_MONTH}
                             onClick={handleDateTabClick}>This month</div>
                        <div className={dateRange === DateRanges.DATE_RANGE_ALL_TIME ? "item active link" : "item link"}
                             id={DateRanges.DATE_RANGE_ALL_TIME}
                             onClick={handleDateTabClick}>All time</div>
                    </div>
                </div>
                <ManagementReportGrids report_type={report_type} date_range={dateRange}
                    updateFilter={updateFilter} orgId={orgId}/>
            </div>

        </>
    )
}

export default ManagementReports