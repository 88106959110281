import {useState} from "react";
import upload from "../assets/file_upload.png"
import valuation_api from "../api/valuation";
import ImageUtils from "../utils/ImageUtils";
import val_api from "../api/valuation";
import GenericDialog from "./GenericDialog";
import ButtonLoadingWheel from "./ButtonLoadingWheel";
import MultiFileUploadPopup from "./MultiFileUploadPopup";


const ReviewCommentInput = (props) => {

    const summaryId = props.summaryId;
    const getHistory = props.getHistory;
    const view = props.view;
    const [comment, setComment] = useState("")
    const [processing, setProcessing] = useState(false);

    const [uploadPopup, setUploadPopup] = useState(false)

    const [uploadingFile, setUploadingFile] = useState(false)
    const [newUpload, setNewUpload] = useState(false)
    const [error, setError] = useState("");
    const okButton = [{"text":"OK", "id": "1"}];

    const PushQuery = () => {
        /*if (fileType && fileName && base64File && view !== "valuer") PushFileQuery()*/
        if (processing) return;
        if (summaryId === undefined || !comment) return
        let body = {
            "summary_id": summaryId,
            "comment": comment
        }
        const response = valuation_api.postValuationQuery(body);
        response.then(
            response => {
                getHistory()
                setProcessing(false)
                ResetText()
            }
        ).catch(err => {
                console.log(err);
            }
        )

    }

    const ResetText = () => {
        setComment("")
    }

    const handleCommentChange = (ev) => {
        setComment(ev.target.value)
    }

    const closePopup = () => {
        setUploadPopup(false)
    }

    const handleOKDialogResult = (res) => {
        setError("");
    }

    return (
        <>
            <div className={view === "valuer"? "review-message-container valuer": "review-message-container"}>
                {view === "user" ? <div className="review-divider"/> : null}
                <div className={view === "valuer" ? "review-comment-input-background-container valuer" :
                    "review-comment-input-background-container"}>
                    <div className="review-comment-input-container">
                        <div className="review-comment-header-container">
                            <div className="review-comment-header">
                                {view === "user" ? "Send a message to a FINVISE internal auditor." :
                                    "Send a message to the client."}
                            </div>
                        </div>
                        <div className="review-comment-container">
                        <textarea value={comment}
                                  onChange={(ev) => handleCommentChange(ev)}/>
                        </div>
                    </div>
                    <div className="review-comment-submit-container">
                        {view && view !== "valuer" ?
                            <div className="review-comment-file-upload">
                                <div className="file-uploader-button link" onClick={() => setUploadPopup(true)}>
                                    Upload files
                                    <img src={upload} alt="Upload File"/>
                                </div>
                            </div>
                            : null}
                        <div className="review-comment-submit link" onClick={() => {
                            if (processing) return;
                            setProcessing(true)
                            PushQuery()
                        }}>
                            {processing && processing ? <ButtonLoadingWheel show={processing}/> : "Submit"}
                        </div>
                    </div>
                </div>
            </div>
            {uploadingFile || newUpload ?
                <div className="dialog-container">
                    <div className="dialog-text upload">
                        <div className="upload-popup-text-container">
                            {uploadingFile && uploadingFile ? "Uploading File" : "Upload complete"}
                        </div>
                        <ButtonLoadingWheel show={uploadingFile && uploadingFile}/>
                    </div>
                    <div className="dialog-footer-container">
                        <div className={!uploadingFile && newUpload ? "dialog-button upload disabled"
                            : "dialog-button upload link"} onClick={() => window.location.reload()}>
                            <div className="dialog-button-text">Cancel</div>
                        </div>
                        <div className={uploadingFile && uploadingFile ? "dialog-button upload disabled"
                            : "dialog-button upload link"} onClick={() => {
                                if (!uploadingFile && newUpload) {
                                    setNewUpload(false)
                                    setUploadingFile(false)
                                }
                        }}>
                            <div className="dialog-button-text">Done</div>
                        </div>
                    </div>
                </div>
                : null
            }
            {uploadPopup ?
                <MultiFileUploadPopup summaryId={summaryId}
                                      getHistory={getHistory.bind(this)}
                                      closePopup={closePopup.bind(this)}
                                      setError={setError.bind(this)}/>
                :null}
            {error && error ?
                <GenericDialog title="Uploading Error" text={error} buttons={okButton}
                               result={handleOKDialogResult.bind(this)} /> : null }
        </>
    )
}

export default ReviewCommentInput