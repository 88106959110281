import {useState} from "react";
import cancel from "../assets/cancel_white.png";
import cloud from "../assets/upload_cloud.png";
import ImageUtils from "../utils/ImageUtils";
import valuation_api from "../api/valuation";
import ButtonLoadingWheel from "./ButtonLoadingWheel";


const MultiFileUploadPopup = (props) => {

    const summaryId = props.summaryId;
    const getHistory = props.getHistory
    const closePopup = props.closePopup;
    const setError = props.setError
    const [files, setFiles] = useState([])
    const [uploadingFile, setUploadingFile] = useState(false)

    const PushFileQuery = () => {
        if (!summaryId || !files.length || uploadingFile) return;
        setUploadingFile(true)
        let body = {
            "summary_id": summaryId,
            "files": files
        }
        const response = valuation_api.postFileValuationQuery(body);
        response.then(
            response => {
                setUploadingFile(false)
                closePopup()
                getHistory()
            }
        ).catch(err => {
                setUploadingFile(false)
                closePopup()
                setError("There was an issue uploading the file. Please try again")
                console.log(err);
            }
        )
    }

    const handleDragOver = (ev) => {
        ev.preventDefault()
    }

    const handleDelete = (ev) => {
        if (!ev) return;
        let _files = [...files]
        _files.splice(ev.target.id, 1)
        setFiles(_files)
    }

    const validFileSize = (_files, _upload_size) => {
        if (!_files || !_upload_size) return false
        if (_upload_size > 300000000) return false
        let _existing_size = 0
        for (let i=0;i<_files.length;i++) {
            _existing_size = _existing_size + _files[i].size
        }
        let _total_size = _existing_size + _upload_size
        return _total_size <= 1000000000;
    }

    const handleDrop = async (ev) => {
        ev.preventDefault()
        let _files = ev.dataTransfer.files
        let _temp_files = []
        _temp_files = [...files]
        for (let i=0;i<_files.length;i++) {
            if (validFileSize(_temp_files, _files[i].size)) {
                let base64_link = await ImageUtils.convertBase64(_files[i]);
                let _file = {name: _files[i].name, base64: base64_link.split(",")[1], size:_files[i].size}
                _temp_files.push(_file)
            }
        }
        setFiles(_temp_files)
    }

    const handleSelect = async (ev) => {
        let _temp_files = [...files]
        for (let i=0;i<ev.target.files.length;i++) {
            if (validFileSize(_temp_files, ev.target.files[i].size)) {
                let base64 = await ImageUtils.convertBase64(ev.target.files[i]);
                let _file = {name: ev.target.files[i].name, base64: base64.split(",")[1], size:ev.target.files[i].size}
                _temp_files.push(_file)
            }
        }
        setFiles(_temp_files)
    }

    const handleFileUploaderClick = () => {
        let element = document.getElementById("file-uploader")
        if (!element) return;
        element.click()
    }

    return(
        <>
            <div className="page-mask"/>
            <div className="multi-file-panel-container">
                <div className="multi-file-header-container">
                    Upload files
                </div>
                <div className="multi-file-upload-container" onDragOver={handleDragOver} onDrop={handleDrop}>
                    <img src={cloud} alt="upload cloud"/>
                    <div>Drag and drop files here or &nbsp;
                        <u style={{cursor: "pointer"}} onClick={handleFileUploaderClick}>
                            select a file
                        </u>
                    </div>
                    <input type='file' id="file-uploader" multiple={true} className="file-uploader-hidden" onChange={handleSelect}/>
                </div>
                <div className="multi-file-upload-footer-container">
                    <div className="multi-file-upload-text-container">
                        Zip files are not supported.
                    </div>
                    <div className="multi-file-upload-text-container">
                        Files must be smaller than 300MB.
                    </div>
                </div>
                {files !== [] ?
                    <div className="multi-file-uploaded-container" id="scrollbar1">
                        {files && files.map((file, idx) => {
                            return (
                                <>
                                    <div className="multi-file-cell-container" key={idx}>
                                        <div className="multi-file-cell-text">
                                            {file.name}
                                        </div>
                                        <div className="multi-file-cell-delete-container link" id={idx}
                                             onClick={(ev) => handleDelete(ev)}>
                                            <img src={cancel} alt="X" id={idx}/>
                                        </div>
                                    </div>
                                </>
                            )
                        })}
                    </div>
                    : null}
                <div className="multi-file-buttons-container">
                    <div className="multi-file-button-container link" onClick={closePopup}>
                        Cancel
                    </div>
                    <div className={files.length > 0 ? "multi-file-button-container upload link" :
                        "multi-file-button-container disabled"} onClick={PushFileQuery}>
                        {uploadingFile ? <ButtonLoadingWheel show={uploadingFile && uploadingFile}/> : "Upload"}
                    </div>
                </div>
            </div>
        </>
    )
}

export default MultiFileUploadPopup