import kapi from "./kratos";
import app_api from "./app";

const getCurrentUserLocal = () => {
    return JSON.parse(localStorage.getItem('user'));
}

const getSession = async () => {
    try {
        const response = await kapi.check_session();
        return true;
    }
    catch {
        return false;
    }
}


const isAuthenticated = () => {
    // check for token
    const user = getCurrentUserLocal();

    if (user && user.email) {
        return true;
    } else {
        return false;
    }
}

const logout = async () => {
    const session = getSession();
    session.then((session) => {
        if (session) {
            const response = kapi.logout_urls();
            response.then((response) => {
                let url = response.data.logout_url;
                window.location.assign(url);
            })
        }
        else {
            window.location.assign("/login");
        }
    })
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    logout,
    getCurrentUserLocal,
    getSession,
    isAuthenticated
};
