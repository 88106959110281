import {useEffect, useState} from "react";
import user_api from "../api/admin/admin_user";
import arrow from "../assets/arrow_back.png";

const AdminNewUser = (props) => {
    const update = props.update;
    const [users, setUsers] = useState();

    const handleCancelClick = () => {
        update(false);
    }

    const handleSendClick = () => {
        for(let i=0;i<users.length;i++){
            if (users[i].email != "" && users[i].email != "{sent}"){
                let body = {
                    "email": users[i].email,
                    "role": 3,
                    "team": 1
                }
                let response = user_api.inviteAdminUser(body);
                response.then(
                    response => {
                        let array = [...users]
                        array[i].email = "{sent}"
                        setUsers(array);
                    }
                )
            }
        }
    }

    const updateEmail = (ev) => {
        if (!ev.target) return;
        if (!users) return;
        const index = parseInt(ev.target.id);

        let array = [...users];
        array[index].email = ev.target.value;
        setUsers(array);
    }

    const handleBackClick = () => {
        update(false);
    }

    useEffect(() => {
        let array = [];
        for(let i=0;i<4;i++){
            let user = {}
            user.email = "";
            array.push(user);
        }
        setUsers(array);
    }, [])

    return (
        <>
            <div className="admin-newuser-title">
                <div className="admin-newuser-header link">
                    <img className="link" src={arrow} alt="arrow icon" onClick={handleBackClick}/>
                </div>
                Invite team members to FINVISE
            </div>
            <div className="admin-newuser-grid-container">
                <div className="admin-newuser-grid-heading">
                    Team member
                </div>
                <div className="admin-newuser-grid-content">
                    {users && users.map((user, idx) => {
                        return (
                            <>
                                <input type="email" value={user.email} placeholder="Email address" id={idx}
                                       onChange={(ev) => updateEmail(ev)}/>
                            </>
                        )
                    })
                    }
                </div>
                <div className="admin-newuser-buttons">
                    <div className="admin-newuser-invite-button link" onClick={handleSendClick}>Send invites</div>
                    <div className="admin-newuser-cancel-button link" onClick={handleCancelClick}>Cancel</div>
                </div>
            </div>
        </>
    )
}

export default AdminNewUser