import RICSHubValuersGrid from "./RICSHubValuersGrid";
import RICSHubRequestsCard from "./RICSHubRequestsCard";
import {useEffect, useState} from "react";
import RICSHubValuerProfile from "./RICSHubValuerProfile";
import admin_api from "../../api/admin/admin_valuer";
import RICSHubValuerRequests from "./RICSHubValuerRequests";

const RICSValuationHub = () => {
    const [showHub, setShowHub] = useState(true);
    const [showValuerProfile, setShowValuerProfile] = useState(false);
    const [showReports, setShowReports] = useState(false);
    const [valuer, setValuer] = useState();
    const [openReports, setOpenReports] = useState();
    const PAGE_SIZE = 6

    const handleShowHub = () => {
        setShowValuerProfile(false);
        setShowHub(true);
        setShowReports(false);
    }

    const handleShowValuer = (selected) => {
        setValuer(selected);
        setShowValuerProfile(true);
        setShowHub(false);
        setShowReports(false);
    }

    const handleShowReports = () => {
        setShowValuerProfile(false);
        setShowHub(false);
        setShowReports(true);
    }

    const getOpenReports = () => {
        let body = {
            "page": 1,
            "page_size": PAGE_SIZE,
            "status": [6, 8, 10, 11, 12]
        }
        let response = admin_api.getValuerReports(body);
        response.then(
            response => {
                setOpenReports(response.data);
            }
        ).catch(err => {
            console.log(err);
        })
    }

    useEffect(() => {
        getOpenReports()
    }, [])

    return (
        <>
            <div className="rics-hub-container">
                {showHub ?
                    <>
                        <RICSHubValuersGrid showValuer={handleShowValuer}/>
                        <RICSHubRequestsCard showReports={handleShowReports} count={openReports && openReports.total_rows}/>
                    </>
                    : null }
                {showReports ? <RICSHubValuerRequests close={handleShowHub}/>
                    : null }
                {showValuerProfile ? <RICSHubValuerProfile valuer={valuer} close={handleShowHub} />
                    : null }
            </div>
        </>
    )
}

export default RICSValuationHub