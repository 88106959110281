import MainHeader from "../components/MainHeader";

const PrivacyPolicy = () => {
    return (
        <>
            <MainHeader />
            <div className="privacy-policy-container">
                <div className="privacy-policy-content">
                    <div className="privacy-policy-title">Privacy Policy</div>
                    <div className="privacy-policy-heading">Lorem Ipsum</div>
                    <div className="privacy-policy-text">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                        ut labore et dolore magna aliqua. Elementum sagittis vitae et leo. Pellentesque habitant
                        morbi tristique senectus et netus et malesuada fames. Mauris in aliquam sem fringilla.
                        Id cursus metus aliquam eleifend mi in nulla. Phasellus vestibulum lorem sed risus ultricies
                        tristique nulla. In massa tempor nec feugiat. Aliquet porttitor lacus luctus accumsan tortor
                        posuere. Lectus quam id leo in vitae turpis massa sed. Placerat in egestas erat imperdiet sed
                        euismod nisi. Nullam ac tortor vitae purus faucibus. Consequat mauris nunc congue nisi vitae.
                        Vel risus commodo viverra maecenas. Nisl tincidunt eget nullam non. A scelerisque purus semper
                        eget duis at tellus at urna. Nunc vel risus commodo viverra maecenas accumsan lacus vel facilisis.
                    </div>
                    <div className="privacy-policy-heading">Lorem Ipsum</div>
                    <div className="privacy-policy-text">
                        Sit amet nisl purus in mollis nunc sed. Blandit cursus risus at ultrices mi. Volutpat est
                        velit egestas dui id ornare arcu odio ut. At erat pellentesque adipiscing commodo. Quisque
                        egestas diam in arcu cursus euismod. Mi sit amet mauris commodo quis imperdiet massa.
                        Viverra mauris in aliquam sem. Aliquam sem et tortor consequat id porta. Purus ut faucibus
                        pulvinar elementum integer enim. Mauris rhoncus aenean vel elit scelerisque mauris
                        pellentesque pulvinar pellentesque. Nulla aliquet enim tortor at auctor urna.
                    </div>
                    <div className="privacy-policy-heading">Lorem Ipsum</div>
                    <div className="privacy-policy-text">
                        Mauris sit amet massa vitae tortor condimentum lacinia. Adipiscing enim eu turpis egestas
                        pretium. Interdum varius sit amet mattis vulputate enim nulla aliquet porttitor.
                        Consequat mauris nunc congue nisi vitae suscipit tellus mauris. Aenean pharetra magna ac
                        placerat vestibulum lectus mauris ultrices. Feugiat nibh sed pulvinar proin gravida.
                        Porta non pulvinar neque laoreet suspendisse interdum consectetur. Quis enim lobortis
                        scelerisque fermentum dui. Aliquam etiam erat velit scelerisque in. Tincidunt praesent
                        semper feugiat nibh sed pulvinar. Mi ipsum faucibus vitae aliquet nec ullamcorper sit amet.
                        Aliquet bibendum enim facilisis gravida.
                    </div>
                    <div className="privacy-policy-heading">Lorem Ipsum</div>
                    <div className="privacy-policy-text">
                        Quam id leo in vitae turpis massa sed elementum tempus. Enim nunc faucibus a pellentesque
                        sit amet. Sed cras ornare arcu dui vivamus. Id interdum velit laoreet id donec ultrices
                        tincidunt arcu. Convallis aenean et tortor at risus viverra adipiscing at in.
                        Gravida rutrum quisque non tellus orci ac. Magna ac placerat vestibulum lectus mauris
                        ultrices eros in. Viverra nam libero justo laoreet sit amet cursus sit amet. Ut ornare
                        lectus sit amet est placerat. Vel risus commodo viverra maecenas accumsan lacus vel facilisis.
                        Scelerisque eleifend donec pretium vulputate sapien. Cras pulvinar mattis nunc sed blandit libero
                        volutpat sed.
                    </div>
                    <div className="privacy-policy-heading">Lorem Ipsum</div>
                    <div className="privacy-policy-text">
                        Enim ut sem viverra aliquet eget sit amet tellus. Sociis natoque penatibus et magnis dis.
                        Tincidunt tortor aliquam nulla facilisi. Scelerisque eu ultrices vitae auctor.
                        Quis vel eros donec ac odio tempor orci dapibus. Lacus vestibulum sed arcu non odio.
                        Interdum consectetur libero id faucibus nisl tincidunt eget nullam non. In massa tempor
                        nec feugiat nisl. Nunc pulvinar sapien et ligula ullamcorper malesuada proin. Nam libero
                        justo laoreet sit amet cursus sit amet dictum. Massa tincidunt dui ut ornare lectus sit
                        amet. Dignissim sodales ut eu sem integer vitae justo eget magna. Augue mauris augue
                        neque gravida in fermentum et sollicitudin. Tellus cras adipiscing enim eu turpis egestas
                        pretium aenean pharetra. Dignissim cras tincidunt lobortis feugiat. A cras semper auctor
                        neque vitae tempus. Pharetra diam sit amet nisl suscipit adipiscing bibendum est ultricies.
                        Netus et malesuada fames ac turpis egestas sed.
                    </div>
                    <div className="privacy-policy-heading">Lorem Ipsum</div>
                    <div className="privacy-policy-text">
                        Amet consectetur adipiscing elit pellentesque habitant morbi tristique. Ac orci
                        phasellus egestas tellus rutrum tellus pellentesque eu tincidunt. Dolor morbi non
                        arcu risus quis varius quam quisque id. Dictum sit amet justo donec. Sed augue lacus
                        viverra vitae congue eu. Vitae proin sagittis nisl rhoncus mattis rhoncus urna. Ut
                        faucibus pulvinar elementum integer enim neque volutpat. Porttitor massa id neque
                        aliquam vestibulum morbi blandit. Urna et pharetra pharetra massa massa. Mattis enim
                        ut tellus elementum sagittis. Nec tincidunt praesent semper feugiat nibh. Nam at lectus
                        urna duis convallis convallis tellus id. Dui ut ornare lectus sit amet. Ipsum consequat
                        nisl vel pretium lectus quam.
                    </div>
                </div>

            </div>
        </>
    )
}

export default PrivacyPolicy