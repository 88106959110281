import avatar from "../assets/Avatar.png";
import {useEffect, useState} from "react";
import dateUtils from "../utils/DateUtils";


const ExternalUserComment = (props) => {

    const comment = props.comment;
    const date = props.date;
    const roleId = props.roleId;
    const status = props.status_id;
    const [name, setName] = useState("")

    useEffect(() => {
        if (!roleId) return;
        switch (roleId) {
            case 1:
                setName("User")
                break;
            case 2:
                setName("Org admin")
                break;
            case 3:
                setName("Admin")
                break;
            case 4:
                setName("Valuer")
                break;
        }
    },[roleId])

    return (
        <>
            <div className="valuation-user-comment-container">
                <div className="valuation-comment-img-container external-user">
                    <img src={avatar} alt="avatar"/>
                </div>
                <div className="valuation-comment-text-container">
                    <div className="valuation-name-container external-user">
                        {name}
                    </div>
                    <div className="valuation-message-container external-user">
                        <div className="valuation-message-text-container">
                            {comment}
                        </div>
                        <div className="valuation-message-date-container">
                            <div className="valuation-info-time-container">
                                {dateUtils.display_date_time(date*1000)}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default ExternalUserComment