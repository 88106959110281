import {useNavigate} from "react-router-dom";

const UserMembershipPanel = (props) => {
    const profile = props.profile;
    const navigate = useNavigate();

    function handleChangeClick() {
        navigate("/profile/change-membership");
    }

    return (
        <>
            <div className="field-input">
                <div className="label">Membership</div>
                {profile && profile.membership_type === 1?
                    <>
                        <div className="membership">Pay as you go</div>
                    </>
                    :
                    <>
                        <div className="membership">Monthly</div>
                        <div className="credit-summary-details">
                            <div className="credit-summary-balance">
                                {profile && profile.credit_balance}
                            </div>
                            <div className="credit-summary-month">credits</div>
                        </div>
                    </> }
                <div className="field-input">
                    <button className="profile-change-membership-button" onClick={handleChangeClick}>
                        Change membership
                    </button>
                </div>
            </div>
        </>
    )
}

export default UserMembershipPanel;