import user_api from "../api/admin/admin_user";
import {useEffect, useState} from "react";
import bin_logo from "../assets/bin.png";
import resurrect_logo from "../assets/ressurect.png";
const AdminUsersGrid = (props) => {
    const update = props.update;
    const [users, setUsers] = useState();

    const getUsers = () => {
        let response = user_api.getAdminUsers();
        response.then(
            response => {
                setUsers(response.data.users);
        })
    }

    const handleAddUserClick = () => {
        update(true);
    }

    const handleRemoveUser = (ev) => {
        if (!ev) return;
        const body = {
            "user_email": ev.target.id,
            "status": 2
        }
        let response = user_api.updateUserStatus(body)
        response.then(
            response => {
                getUsers()
            }
        )
    }
    const handleResurrectUser = (ev) => {
        if (!ev) return;
        const body = {
            "user_email": ev.target.id,
            "status": 1
        }
        let response = user_api.updateUserStatus(body)
        response.then(
            response => {
                getUsers()
            }
        )
    }

    useEffect(() => {
        getUsers()
    }, [])

    return (
        <>
            <div className="admin-user-grid-container">
                <div className="admin-user-grid-title-container">
                    <div className="admin-user-grid-title">Finvise team</div>
                    <div className="admin-user-grid-add-button link" onClick={handleAddUserClick}>
                        Add team member
                    </div>
                </div>
                <div className="admin-user-grid-headers">
                    <div className="admin-user-grid-name">Name</div>
                    <div className="admin-user-grid-email">Email</div>
                    <div className="admin-user-grid-status">Status</div>
                    <div className="admin-user-grid-action">Action</div>
                </div>

                {users && users.map((user, idx) => {
                    return (
                        <div className="admin-user-grid-row">
                            <div className="admin-user-grid-name">{!user.firstname ? "{pending}" : user.firstname + " " + user.lastname}</div>
                            <div className="admin-user-grid-email">{user.email}</div>
                            <div className="admin-user-grid-status">{user.status === 1 ? "Active" : "Inactive"}</div>
                            <div className="admin-user-grid-action">
                                {user.status === 1 ?
                                    <img src={bin_logo} alt="Bin icon" className="link" onClick={handleRemoveUser} id={user.email}/>
                                    :
                                    <img src={resurrect_logo} alt="Resurrect icon" className="link" onClick={handleResurrectUser} id={user.email}/>
                                }
                            </div>
                        </div>
                    )
                })}
            </div>
        </>
    )
}

export default AdminUsersGrid