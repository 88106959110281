const GetCreditBanner = (props) => {
    const handleClick = props.handleClick;

    function handleGetInsightClick() {
        handleClick();
    }

    return (
        <>
            <div className="page-mask blurred"/>
            <div className="get-credit-banner-container">
                <div className="get-credit-banner-title">
                    Want to get the full market insight?
                </div>
                <div className="get-credit-banner-subtitle">
                    Uncover property data and area insights from £9.99.
                </div>
                <div className="get-credit-banner-button link" onClick={handleGetInsightClick}>
                    Get market insight
                </div>
            </div>
        </>
    )
}

export default GetCreditBanner