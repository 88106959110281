import {useEffect, useState} from "react";


const GenericIntCard = (props) => {
    const title = props.title
    const value = props.value
    const setValue = props.setValue

    const formatNumber = (_string) => {
        return _string && _string.replace(/\D/g,'').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    return(
        <>
            <div className="generic-int-card-container" >
                <div className="generic-int-card-header-container">
                    <div className={"generic-int-card-header"}>
                        {title}
                    </div>
                </div>
                <div className="generic-int-text-container">
                    <input type="text" pattern="[0-9]*" value={formatNumber(value)}
                           placeholder={""} onChange={(ev) =>
                        setValue(ev.target.value.replace(",",""))}/>
                </div>
            </div>
        </>
    )
}

export default GenericIntCard