import {useState, useEffect} from "react";
import address_formatter from "../utils/Address";
import down_arrow from "../assets/arrow_down.png"

const PropertyDropdown = (props) => {

    const valuations = props.valuations;
    const valuation = props.valuation;
    const handleDropdownChange = props.handleDropdownChange;
    const dropdownNum = props.dropdownNum;

    const [showDropdown, setShowDropdown] = useState(false)

    const handleRowClick = (detail) => {
        handleDropdownChange(dropdownNum, detail)
        handleDropdown()
    }

    const handleDropdown = () => {
        setShowDropdown(!showDropdown)
    }

    return(
        <>
            <div className="property-dropdown-container" onClick={handleDropdown}>
                {showDropdown ?
                    <div className="property-dropdown open link">
                        <div className="property-dropdown-address" onClick={handleDropdown}>
                            {valuation && valuation ? address_formatter.formatSearchAddress(valuation) : ""}
                        </div>
                        <div className="property-dropdown-arrow" onClick={handleDropdown}>
                            <img src={down_arrow} alt="down_arrow" style={{rotate: 180 + "deg"}}/>
                        </div>
                    </div>
                    :
                    <div className="property-dropdown link">
                        <div className="property-dropdown-address" onClick={handleDropdown}>
                            {valuation && valuation ? address_formatter.formatSearchAddress(valuation) : ""}
                        </div>
                        <div className="property-dropdown-arrow" onClick={handleDropdown}>
                            <img src={down_arrow} alt="down_arrow" />
                        </div>
                    </div>
                }
                {showDropdown ?
                    <div className="property-dropdown-menu-container">
                        {valuations && valuations.map((detail) => {
                            return(
                                <>
                                    <div className="property-dropdown-menu-row link"
                                         onClick={() => handleRowClick(detail)}>
                                        <div className="property-dropdown-menu-address">
                                            {address_formatter.formatSearchAddress(detail)}
                                        </div>
                                    </div>
                                    <div className="recent-searches-divider"/>
                                </>
                            )
                        })}
                    </div>
                    :null}
            </div>
        </>
    )
}

export default PropertyDropdown