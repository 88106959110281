import {useEffect, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import stripe_api from "../api/stripe";
import GenericDialog from "../components/GenericDialog";
import MainHeader from "../components/MainHeader";
import LoadingWheel from "../components/LoadingWheel";

const Success = () => {

    const navigate = useNavigate();
    const [parameters] = useSearchParams();

    const [error, setError] = useState("")
    const [processing, setProcessing] = useState(true)

    const okButton = [{"text":"OK", "id": "1"}];

    useEffect(() => {
        let checkout_id = parameters.get("session_id")
        let type = parameters.get("type")
        if (!checkout_id) {
            setProcessing(false)
            setError("We were unable to locate your transaction. Please contact our help team")
            return
        }
        // Run the check for the given type of checkout session in the URL
        if (type === "token") CheckProductCheckoutSessionOutcome(checkout_id)
        else CheckCheckoutSessionOutcome(checkout_id)
    }, []);

    const CheckProductCheckoutSessionOutcome = (checkout_session_id) => {
        let response = stripe_api.checkProductCheckoutOutcome(checkout_session_id)
        response.then(
            response => {
                // If response comes back as successfully paid navigate the user to cse-plus page;
                // if retry recall endpoint in 5 seconds else show error
                if (response.data?.status === "success") {
                    setProcessing(false)
                    let success_from = sessionStorage.getItem("success_from");
                    if (!success_from) success_from = "/valuation"
                    sessionStorage.removeItem("success_from");
                    navigate(success_from)
                }
                else if (response.data?.status === "retry") {
                    setTimeout(() => CheckCheckoutSessionOutcome(checkout_session_id), 5000)
                }
                else {
                    setProcessing(false)
                    setError("There was an issue processing your transaction. \n" + response.data?.code)
                }
            }
        ).catch((err) => {
            setError("We were unable to locate your transaction. " + err.response.status)
        })
    }

    const CheckCheckoutSessionOutcome = (checkout_session_id) => {
        let response = stripe_api.checkCheckoutOutcome(checkout_session_id)
        response.then(
            response => {
                // If response comes back as successfully paid navigate the user to cse-plus page;
                // if retry recall endpoint in 5 seconds else show error
                if (response.data?.status === "success") {
                    setProcessing(false)
                    let success_from = sessionStorage.getItem("success_from");
                    if (!success_from) success_from = "/profile/change-membership"
                    sessionStorage.removeItem("success_from");
                    navigate(success_from)
                }
                else if (response.data?.status === "retry") {
                    setTimeout(() => CheckCheckoutSessionOutcome(checkout_session_id), 5000)
                }
                else {
                    setProcessing(false)
                    setError("There was an issue processing your transaction. \n" + response.data?.code)
                }
            }
        ).catch((err) => {
            setError("We were unable to locate your transaction. " + err.response.status)
        })
    }

    const CloseErrorPopup = () => {
        setError("")
        navigate("/profile/change-membership")
    }

    return (
        <>
            <MainHeader />
            <div className="user-profile-container">
                {processing ?
                    <div className="stripe-success-processing-container">
                        <LoadingWheel />
                        <div className="stripe-success-processing-message">
                            Please do not leave this page whilst we process the transaction
                        </div>
                    </div>
                    : null }

            </div>
            {error && error ?
                <GenericDialog title={"Error"} text={error} buttons={okButton} result={CloseErrorPopup.bind(this)} />
                : null }
        </>
    )
}

export default Success