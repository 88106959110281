import {useEffect, useState} from "react";
import avatar from "../assets/Avatar.png"
import dateUtils from "../utils/DateUtils";
import TextUtils from "../utils/TextUtils";
import valuation_api from "../api/valuation";
import ButtonLoadingWheel from "./ButtonLoadingWheel";
import GenericDialog from "./GenericDialog";
import MimeTypes from "../models/MimeTypes";

const FileComment = (props) => {

    const [fileName, setFileName] = useState()
    const date = props.date;
    const summary_id = props.summary_id;

    const [downloadingFile, setDownloadingFile] = useState(false);
    const [error, setError] = useState("");
    const okButton = [{"text":"OK", "id": "1"}];

    const handleOKDialogResult = (res) => {
        setError("");
    }

    useEffect(() => {
        if (!props.comment) return
        setFileName(TextUtils.GetFileName(props.comment));
    })

    const downloadFile = () => {
        if (!summary_id || !fileName) return
        setDownloadingFile(true)
        const response = valuation_api.getValuationFile(summary_id, fileName);
        response.then(
            response => {
                const FILE_DATA = response.data.data;
                let file_type = fileName.split(".")[1]
                let MIME_TYPE = MimeTypes[file_type]
                let base64_link = "data:" + MIME_TYPE + ";base64," + FILE_DATA
                console.log(base64_link)
                const anchorElement = document.createElement('a');
                anchorElement.href = base64_link;
                anchorElement.download = fileName;
                anchorElement.click();
                console.log("Clicked")
                setDownloadingFile(false)
            }
        ).catch(err => {
            setDownloadingFile(false)
            setError("There was an issue downloading the file. Please try again")
        })
    }

    return (
        <>
            <div className="valuation-user-comment-container">
                <div className="valuation-comment-text-container">
                    <div className="valuation-name-container user">
                        You
                    </div>
                    <div className="valuation-message-container user">
                        <div className="valuation-message-text-container">
                            File Uploaded:
                            {fileName && fileName ?
                                <span className="file-comment link" onClick={() => downloadFile()}>
                                    {fileName}
                                </span>
                                :
                                "There was an issue locating the file"
                            }
                        </div>
                        <div className="valuation-message-date-container">
                            <div className="valuation-info-time-container">
                                {dateUtils.display_date_time(date*1000)}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="valuation-comment-img-container user">
                    <img src={avatar} alt="avatar"/>
                </div>
            </div>
            {downloadingFile && downloadingFile ?
                <div className="dialog-container">
                    <div className="dialog-text upload">
                        <div className="upload-popup-text-container">
                            Downloading File
                        </div>
                        <ButtonLoadingWheel show={true}/>
                    </div>
                </div>
                : null
            }
            {error && error ?
                <GenericDialog title="Downloading Error" text={error} buttons={okButton}
                               result={handleOKDialogResult.bind(this)} /> : null }
        </>
    )
}

export default FileComment