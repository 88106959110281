import {useEffect, useState} from "react";
import utils from "../utils/DateUtils";
import PdfDocument from "./PdfDocument";
import info_img from "../assets/info.png";
import ValuationInfoPopup from "./ValuationInfoPopup";

const RICSValuation = (props) => {

    // Report Data
    const [valuation, setValuation] = useState();
    const [valuationId, setValuationId] = useState();
    const [processedDate, setProcessedDate] = useState();
    const [furtherDetails, setFurtherDetails] = useState();
    const [showInfoPanel, setShowInfoPanel] = useState(false);

    // Error Showing
    const setError = props.setError
    const setShowErrorDialog = props.setShowErrorDialog

    const [lastTransaction, setLastTransaction] = useState(true)

    const setPercentageStyle = () => {
        let elements = document.getElementsByClassName("actual-degree");
        if (!elements) return;
        const degrees = (valuation.accuracy / 100)  * 135;
        for (let i=0;i<elements.length;i++){
            elements[i].style.setProperty('--final-degree', degrees + 'deg');
        }
    }

    useEffect(() => {
        setValuation(props.valuation)
        setValuationId(props.valuationId)
        setProcessedDate(props.processedDate)
        setFurtherDetails(props.furtherDetails)
    }, [props.processedReport, props.valuation, props.valuationId, props.processedDate, props.furtherDetails])

    const handleInfoClick = () => {
        setShowInfoPanel(true);
    }

    const updateShowInfo = (show) => {
        setShowInfoPanel(show);
    }

    const handleShowDownloadError = (error) => {
        setError(error);
        setShowErrorDialog(true);
    }

    useEffect(() => {
        if (!valuation) return;
        setPercentageStyle();
        if (valuation.valuation_date === undefined || valuation.valuation_date === null || valuation.valuation_date === 0 ||
            valuation.predicted_value === undefined || valuation.predicted_value === null || valuation.predicted_value === 0 ||
            valuation.historic_value === undefined || valuation.historic_value === null || valuation.historic_value === 0) {
            setLastTransaction(false)
        }
    }, [valuation])

    return (
        <>
            <div className="valuation-rics-processing-container">
                <div className="valuation-rics-processing-text title">
                    Thank you for your request.
                </div>
                <div className="valuation-rics-processing-text">
                    Your RICS valuation will be available in your 'My Reports' section soon.
                </div>
                <div className="valuation-rics-processing-text">
                    You’ll receive an email notification when your report is ready to view.
                </div>
            </div>
            {/*{valuation ?
                <div className="valuation-result-container">
                    <div className="valuation-result-details">
                        <div className="rics-current-predicted-container">
                            <div className="predicted-value-title">Current Valuation</div>
                            <div className="predicted-value-icon">
                                <img src={info_img} onClick={handleInfoClick}/>
                            </div>

                            {showInfoPanel ? <ValuationInfoPopup update={updateShowInfo.bind(this)}/> : null }

                            <div className="rics-current-predicted-data-container">
                                <div className="predicted-value-amount">
                                    £{ valuation && valuation.accuracy >= 85
                                    ? valuation.predicted_valuation.toLocaleString()
                                    : "Awaiting Confirmation"}
                                </div>

                                <div className="accuracy-container">
                                    <div className="circle-wrap">
                                        <div className="circle">
                                            <div className="mask full actual-degree" id="full">
                                                <div className="fill actual-degree"></div>
                                            </div>
                                            <div className="mask half">
                                                <div className="fill actual-degree"></div>
                                            </div>
                                            <div className="inside-circle">
                                                <div className="accuracy-title">CONFIDENCE</div>
                                                <div className="accuracy-value">
                                                    {valuation && valuation.accuracy >= 85 ? valuation.accuracy
                                                        : "Awaiting Confirmation"}
                                                </div>
                                                <div className="accuracy-perc">%</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="rics-download-container">
                            {valuation && valuation.accuracy >= 85 ?
                                <PdfDocument valuation={valuation} rics={true} id={valuationId} showError={handleShowDownloadError.bind(this)}
                                             processedDate={processedDate} title={"Your RICS Regulated Valuation Report"}/>
                                : null}
                        </div>

                        {valuation && valuation.accuracy < 85 ?
                            <div className="rics-report-cover-up-container">
                                <div className="rics-report-cover-up">
                                    Your RICS valuation will be available in your profile in up to 24 hours
                                </div>
                            </div>
                            : null}
                    </div>
                    <div className="valuation-details-cards-title">Further Details</div>
                    <div className="valuation-cards">
                        {furtherDetails && furtherDetails.map((card, idx) => {
                            return (
                                <div className="valuation-card" key={idx}>
                                    <div className="valuation-card-icon">
                                        <img src={"/images/valuation/" + card.icon_filename} alt="Valuation Card Icon"/>
                                    </div>
                                    <div className="valuation-card-background">
                                        <img src={"/images/valuation/" + card.background_filename} alt="Valuation Card Background"/>
                                    </div>
                                    <div className="valuation-card-value">
                                        {card.value}
                                    </div>
                                    <div className="valuation-card-title">
                                        {card.title}
                                    </div>
                                </div>
                            )
                        })}
                    </div>

                    <div className="rics-transaction-container">
                        <div className="transaction-title">Last transaction</div>
                        <img src={"/images/valuation/last_transaction.png"} alt={"Last Transaction"}
                             className="last-transaction-card-image"/>
                        <div className="transaction-details">
                            {lastTransaction ?
                                <div className="predicted-valuation-container">
                                    <div className="predicted-value-stat">
                                        <div className="transaction-detail-heading">Transaction date</div>
                                        <div className="transaction-detail-text">{utils.short_display_date(valuation.valuation_date * 1000)}</div>
                                    </div>
                                    <div className="predicted-value-stat">
                                        <div className="transaction-detail-heading">Predicted valuation</div>
                                        <div className="transaction-detail-text">£{valuation.predicted_value.toLocaleString()}</div>
                                    </div>
                                    <div className="predicted-value-stat">
                                        <div className="transaction-detail-heading">Actual Sale price</div>
                                        <div className="transaction-detail-text">£{valuation.historic_value.toLocaleString()}</div>
                                    </div>
                                </div>
                                :
                                <div className="predicted-valuation-empty-container">
                                    We couldn't find a public record of a historical transaction for this property.
                                </div>
                            }
                        </div>
                    </div>
                </div>
                : null }*/}
        </>
    )
}

export default RICSValuation