import {useEffect, useState} from "react";
import profile_api from "../../api/profile";
import GenericDialog from "../GenericDialog";

const PostNominalsPanel = (props) => {
    const profile = props.profile;
    const [postnominals, setPostnominals] = useState();
    const [showSuccess, setShowSuccess] = useState(false);
    const [showErrorDialog, setShowErrorDialog] = useState(false);
    const [error, setError] = useState();
    const okButton = [{"text":"OK", "id": "1"}];

    useEffect(() => {
        if (!profile) return;
        setPostnominals(profile.postnominals);
    }, [profile])

    const handleSaveClick = () => {
        let body = {
            "postnominals": postnominals
        }
        let response = profile_api.setPostnominals(body);
        response.then(
            response => {
                setShowSuccess(true);
            }
        ).catch(err => {
            console.log(err);
            setError("Failed to update profile");
            setShowErrorDialog(true);
        })
    }

    const handleOKDialogResult = (res) => {
        setShowErrorDialog(false);
        setShowSuccess(false);
    }

    return (
        <>
            <div className="postnominals-container">
                <div className="field-input">
                    <div className="label">Auditor post-nominals</div>
                </div>
                <div className="postnominals-sub-title">
                    You must add your post-nominals (e.g. AssocRICS, MRICS, FRICS) so that this
                    appears in the reports you approved.
                </div>
                <div className="field-input">
                    <div className="field-input-entry">
                        <input placeholder="Post-nominals" name="postnominals"
                               type="text" value={postnominals}
                               onChange={(ev) => {setPostnominals(ev.target.value)}}/>
                    </div>
                </div>
                <div className="field-input">
                    <button className="postnominals-save-button" onClick={handleSaveClick}>
                        Save
                    </button>
                </div>
            </div>
            {showSuccess ? <GenericDialog title="Succeeded" text="Your profile update was successful"
                                          buttons={okButton}
                                          result={handleOKDialogResult.bind(this)}/>
                : null}
            {showErrorDialog ?
                <GenericDialog title="Update - Error" text={error} buttons={okButton}
                               result={handleOKDialogResult.bind(this)} /> : null }
        </>
    )
}

export default PostNominalsPanel