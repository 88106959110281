
const mime_types = {
    "doc": "application/msword",
    "docx": "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "pdf": "application/pdf",
    "zip": "application/zip",
    "txt": "text/plain",
    "csv": "text/csv",
    "avi": "video/x-msvideo",
    "mp4": "video/mp4",
    "mpeg": "video/mpeg",
    "jpg": "image/jpeg",
    "jpeg": "image/jpeg",
    "png": "image/png",
    "gif": "image/gif",
    "webp": "image/webp",
}


export default mime_types;