import axiosInstance from "./http/axios_instance"
const http = axiosInstance("/protected",  null, "/forbidden")

const getOverview = (summary_id) => http.get("/comparison/" + summary_id);

const createTable = (firstId, secondId, thirdId) => http.get("/comparison_to_csv" + "/" + firstId + "/" + secondId
    + "/" + thirdId);

export default {
    getOverview,
    createTable
}