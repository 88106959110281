import OrganisationConstants from "../models/OrganisationConstants";

const OrganisationTeamSummary = (props) => {
    const organisation = props.organisation;
    const updateSection = props.updateSection;

    const handleViewClick = () => {
        updateSection(OrganisationConstants.PEOPLE_SECTION);
    }

    return (
        <>
            <div className="organisation-team-summary-container">
                <div className="team-summary-grid-headings">
                    <div className="team-summary-grid-col col">
                        TEAM
                    </div>
                </div>
                <div className="team-summary-grid-rows">
                    {organisation && organisation.teams && organisation.teams.map((team, idx) => {
                        return (
                            <>
                                <div className="team-summary-grid-row">
                                    <div className="team-summary-grid-col col">{team.name}</div>
                                </div>
                            </>
                        )
                    })}
                </div>
                <div className="team-summary-buttons">
                    <div className="team-summary-all link" onClick={handleViewClick}>View teams</div>
                </div>
            </div>
        </>
    )
}

export default OrganisationTeamSummary