import {useEffect, useState} from "react";
import star from "../assets/rating_star.png";
import arrow from "../assets/arrow_top_right.png";

const LocalAreaSchoolsCard = (props) => {

    const title = props.title;
    const image = props.image;
    const data = props.data;

    const [empty, setEmpty] = useState(true)

    useEffect(() => {
        if (data.length !== 0) {
            setEmpty(false)
        }
    },[data])

    return (
        <>
            <div className="insight-local-grid-item-container">
                <div className="insight-local-card-container">
                    <div className="insight-local-card-header-container">
                        <div className="insight-local-card-header-icon-container">
                            <img src={image} className="insight-local-card-header-icon" alt="icon"/>
                        </div>
                        <div className="insight-local-card-header-title-container">
                            <div className="insight-local-card-header-title">{title}</div>
                        </div>
                        <div className="insight-local-card-header-distance-container">
                            est. distance
                        </div>
                    </div>
                    <div className="insight-local-card-details-container">
                        {empty && empty?
                            <div className={"insight-local-card-row-container "}>
                                <div className="insight-local-card-organization-container empty">
                                    <div className="insight-local-card-organization empty">
                                        Our data providers currently do not have any information for this property.
                                    </div>
                                </div>
                            </div>
                            :
                            <>
                                {data && data.slice(0,5).map((row) => {
                                    return (
                                        <div className="insight-local-card-row-container">
                                            <div className="insight-local-card-oraginization-container school">
                                                {row.name}
                                            </div>
                                            <div className="insight-local-card-rating-container">
                                                {row.rating === "Outstanding" ?
                                                    <div className="insight-local-card-rating-star-container">
                                                        <img src={star} alt="star"/>
                                                    </div>
                                                    : null
                                                }
                                                <div className="insight-local-card-rating-value-container">
                                                <div className="insight-local-card-rating-value">
                                                        {row.rating}
                                                    </div>
                                                </div>
                                                <div className="insight-local-card-distance-container">
                                                    <div className="insight-local-card-distance-arrow-container">
                                                        <img src={arrow} alt="arrow"/>
                                                    </div>
                                                    <div className="insight-local-card-distance-value-container">
                                                        <div className="insight-local-card-distance-value">
                                                            {parseFloat(row.distance).toFixed(1)} km
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </>
                        }

                    </div>
                </div>
            </div>
        </>
    )
}

export default LocalAreaSchoolsCard