import MainHeader from "../components/MainHeader";

const Terms = () => {
    return (
        <>
            <div className="header-container"/>
            <div className="privacy-policy-container">
                <div className="privacy-policy-content">
                    <div className="privacy-policy-title">Acceptable Use Policy (AUP)</div>
                    <div className="privacy-policy-heading">Introduction </div>
                    <div className="privacy-policy-text">
                        This Acceptable Use Policy (AUP) outlines the terms and conditions governing the use of FINVISE
                        software. By accessing or using FINVISE's services, you agree to comply with this AUP. Failure
                        to comply with these terms may result in the suspension or termination of access to our services.
                    </div>
                    <div className="privacy-policy-heading">Prohibited Activities </div>
                    <div className="privacy-policy-text">
                        Users of FINVISE services are prohibited from engaging in any of the following activities:
                    </div>
                    <div className="privacy-policy-section">
                        <ul>
                            <li><b>Unauthorised Access</b></li>
                        </ul>
                        <div className="privacy-policy-section-text">
                            You shall not attempt to access, use, or manipulate any part of FINVISE software without
                            proper authorization.
                        </div>
                    </div>
                    <div className="privacy-policy-section">
                        <ul>
                            <li><b>Data Breach</b></li>
                        </ul>
                        <div className="privacy-policy-text">
                            You shall not engage in any activity that compromises the security, confidentiality, or
                            integrity of data stored on the FINVISE platform, including but not limited to hacking,
                            data breaches, or unauthorised data access.
                        </div>
                    </div>
                    <div className="privacy-policy-section">
                        <ul>
                            <li><b>Malicious Software</b></li>
                        </ul>
                        <div className="privacy-policy-text">
                            You shall not upload, distribute, or use any malware, viruses, or other malicious software
                            that may harm or disrupt the FINVISE SaaS service or other users' systems.
                        </div>
                    </div>
                    <div className="privacy-policy-section">
                        <ul>
                            <li><b>Abuse of Resources</b></li>
                        </ul>
                        <div className="privacy-policy-text">
                            You shall not engage in activities that consume excessive system resources, including
                            bandwidth, CPU, or memory, which may disrupt the performance and availability of the
                            FINVISE platform.
                        </div>
                    </div>
                    <div className="privacy-policy-section">
                        <ul>
                            <li><b>Spam and Unsolicited Communication</b></li>
                        </ul>
                        <div className="privacy-policy-text">
                            You shall not use the FINVISE software for the purpose of sending spam, phishing emails,
                            or any other form of unsolicited communication.
                        </div>
                    </div>
                    <div className="privacy-policy-section">
                        <ul>
                            <li><b>Harassment and Hate Speech</b></li>
                        </ul>
                        <div className="privacy-policy-text">
                            You shall not use the FINVISE platform to harass, threaten, or engage in hate speech against
                            any individual or group based on race, religion, gender, or other protected characteristics.
                        </div>
                    </div>
                    <div className="privacy-policy-section">
                        <ul>
                            <li><b>Violation of Laws</b></li>
                        </ul>
                        <div className="privacy-policy-text">
                            You shall not use the FINVISE software for any illegal or unethical activity, including but
                            not limited to copyright infringement, intellectual property violations, or any other
                            violation of applicable laws and regulations.
                        </div>
                    </div>
                    <div className="privacy-policy-section">
                        <ul>
                            <li><b>Impersonation</b></li>
                        </ul>
                        <div className="privacy-policy-text">
                            You shall not impersonate any individual or entity, including FINVISE staff, in a manner
                            that may deceive or harm other users.
                        </div>
                    </div>
                    <div className="privacy-policy-section">
                        <ul>
                            <li><b>Reporting Violations</b></li>
                        </ul>
                        <div className="privacy-policy-text">
                            If you become aware of any violation of this AUP by other users, please report it to FINVISE
                            immediately at david@finvise.com
                        </div>
                    </div>
                    <div className="privacy-policy-section">
                        <ul>
                            <li><b>Enforcement</b></li>
                        </ul>
                        <div className="privacy-policy-text">
                            FINVISE reserves the right to investigate and take appropriate actions, including but not
                            limited to suspending or terminating access to its services for users who violate this AUP.
                            In severe cases, FINVISE may also pursue legal remedies.
                        </div>
                    </div>
                    <div className="privacy-policy-section">
                        <ul>
                            <li><b>Amendments</b></li>
                        </ul>
                        <div className="privacy-policy-text">
                            FINVISE may update this policy from time to time to reflect changes in technology or
                            services. Users will be notified of any changes, and it is their responsibility to review
                            the revised AUP. Continued use of the FINVISE SaaS service following such changes
                            constitutes acceptance of the updated AUP.
                        </div>
                    </div>
                    <div className="privacy-policy-text-padded">
                        By using the FINVISE platform you acknowledge that you have read, understood, and agreed to
                        abide by this Acceptable Use Policy.
                    </div>
                    <div className="privacy-policy-text-padded">
                        If you have any questions or concerns regarding this AUP, please contact us at&thinsp;&thinsp;
                        <a href="david@finvise.com">david@finvise.com</a>
                    </div>
                    <div className="privacy-policy-text-padded">
                        FINVISE
                    </div>
                    <div className="privacy-policy-text-padded">
                        26/10/2023
                    </div>
                </div>

            </div>
        </>
    )
}

export default Terms