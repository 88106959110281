import React, {useEffect, useState} from "react";
import property_api from "../api/property";
import {Link} from "react-router-dom";

const ReactActivityCard = (props) => {


    const [card, setCard] = useState()
    const [currency, setCurrency] = useState()
    const [cardImg, setCardImg] = useState()

    const GetPropertyImg = (item) => {
        if (!item) return;
        let _src = ``
        _src = `https://maps.googleapis.com/maps/api/streetview?size=400x260&loca` +
            `tion=${item.latitude},${item.longitude} &fov=80&heading=70&pitch=0` +
            `&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
        setCardImg(_src)
    }

    useEffect(() => {
        if(!props.currency) return;
        setCurrency(props.currency)
    }, [props.currency])

    useEffect(() => {
        if(!props.card) return;
        setCard(props.card)
        GetPropertyImg(props.card)
    },[props.card])

    return (
        <div className="insight-recent-card-container" style={!cardImg ? {display: "none"}:{}}>
            <div className="insight-recent-card-img-container">
                <img className="insight-recent-card-img"
                     src={cardImg}
                     alt="card image"/>
            </div>
            <div className="insight-recent-card-info-container">
                <div className="insight-recent-card-info-address">{card && card.address}</div>
                <div className="insight-recent-card-info-value">
                    {currency}{parseInt(card && card.price).toLocaleString()}
                </div>
                <div className="insight-recent-card-button-container">
                    <Link to={"/valuation"} target="_blank" className="insight-recent-card-button">
                        Get insight on this property >
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default ReactActivityCard