const DATE_RANGE_TODAY = 1;
const DATE_RANGE_THIS_WEEK = 2;
const DATE_RANGE_THIS_MONTH = 3;
const DATE_RANGE_THIS_YEAR = 4;
const DATE_RANGE_USER_DEFINED = 5;
const DATE_RANGE_ALL_TIME = 6;

const GetDateRanges = () => {
    let ranges = [];
    let range = {};
    range.id = DATE_RANGE_TODAY;
    range.name = "Today";
    ranges.push(range);

    range = {};
    range.id = DATE_RANGE_THIS_WEEK;
    range.name = "This week";
    ranges.push(range);

    range = {};
    range.id = DATE_RANGE_THIS_MONTH;
    range.name = "This month";
    ranges.push(range);

    range = {};
    range.id = DATE_RANGE_THIS_YEAR;
    range.name = "Year-to-date";
    ranges.push(range);

    range = {};
    range.id = DATE_RANGE_USER_DEFINED;
    range.name = "Specific date range";
    ranges.push(range);

    return ranges;
}

export default {
    GetDateRanges,
    DATE_RANGE_TODAY,
    DATE_RANGE_THIS_WEEK,
    DATE_RANGE_THIS_MONTH,
    DATE_RANGE_THIS_YEAR,
    DATE_RANGE_USER_DEFINED,
    DATE_RANGE_ALL_TIME
}