import {useEffect, useState} from "react";
import valuation_api from "../api/valuation";
import {useNavigate, useParams} from "react-router-dom";
import MainHeader from "../components/MainHeader";
import ValuationReviewOverview from "../components/ValuationReviewOverview";
import address_formatter from "../utils/Address";
import ReviewCommentInput from "../components/ReviewCommentInput";
import val_api from "../api/valuation";
import profapi from "../api/profile";
import ValuationStatusPill from "../components/ValuationStatusPill";
import valuer_api from "../api/valuer";

const UserReview = () => {

    const params = useParams();
    const [address, setAddress] = useState();
    const navigate = useNavigate();
    const [history, setHistory] = useState();
    const [profile, setProfile] = useState();
    const [roleId, setRoleId] = useState();

    const [pillName, setPillName] = useState();
    const [pillId, setPillId] = useState();

    const getProfile = () => {
        let response = profapi.getProfile();
        response.then(
            response => {
                setProfile(response.data);
                setRoleId(response.data.role_id)
            }
        )
    }

    const getAddress = () => {
        if (!params.id) return;
        let response = valuation_api.getMyValuation(params.id);
        response.then(
            response => {
                setAddress(response.data);
            }
        ).catch((err) => {
            console.log(err);
        })
    }

    const getHistory = () => {
        if (!params.id) return;
        const response = val_api.getValuationHistory(params.id);
        response.then(
            response => {
                setHistory((response.data.history).reverse());
                for (let i=(response.data.history.length -1); i >=0; i--) {
                    if (response.data.history[i].type_id === 2) {
                        setPillId(response.data.history[i].status_id)
                        setPillName(response.data.history[i].status_name)
                    }
                }
            }
        ).catch(err => {
                console.log(err);
            }
        )
    }

    useEffect(() => {
        getHistory()
    }, [params.id])

    useEffect(() => {
        getAddress()
        getProfile()
    }, [])

    function handleBackClick() {
        navigate("/profile/reports");
    }

    return (
        <>
            <MainHeader />
            {address ?
                <div className="user-review-page-container">
                    <div className="user-review-page-title">
                        <div className="user-review-back link" onClick={handleBackClick}>{"<"}</div>
                        {address_formatter.formatSearchAddress(address)}
                        {pillName && pillId ?
                            <ValuationStatusPill status_id={pillId}
                                                 status_name={pillName}/>
                            :null}
                    </div>
                    <div className="user-review-container">
                        <div className="user-review-content-container">
                            <div className="user-review-section-container">
                                <ValuationReviewOverview history={history}
                                                         summaryId={params.id}
                                                         profileRole={roleId}
                                                         getHistory={getHistory.bind(this)}/>
                            </div>
                        </div>
                    </div>
                </div>
            :null}
        </>
    )

}

export default UserReview