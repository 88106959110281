import {useEffect, useState} from "react";
import utils from "../utils/DateUtils";
import PdfDocument from "./PdfDocument";
import info_img from "../assets/info.png";
import ValuationInfoPopup from "./ValuationInfoPopup";

const MortgageValuation = (props) => {

    // Report Data
    const [valuation, setValuation] = useState();
    const [valuationId, setValuationId] = useState();
    const [processedDate, setProcessedDate] = useState();
    const [furtherDetails, setFurtherDetails] = useState();
    const [showInfoPanel, setShowInfoPanel] = useState(false);

    const [lastTransaction, setLastTransaction] = useState(false);
    const [showValuation, setShowValuation] = useState(false)
    const [showAccuracy, setShowAccuracy] = useState(false)


    // Error Showing
    const setError = props.setError
    const setShowErrorDialog = props.setShowErrorDialog

    const setPercentageStyle = () => {
        let elements = document.getElementsByClassName("actual-degree");
        if (!elements) return;
        const degrees = (valuation.accuracy / 100)  * 135;
        for (let i=0;i<elements.length;i++){
            elements[i].style.setProperty('--final-degree', degrees + 'deg');
        }
    }

    useEffect(() => {
        setValuation(props.valuation)
        setValuationId(props.valuationId)
        setProcessedDate(props.processedDate)
        for (let i=0;i < props.furtherDetails.length;i++) {
            if (props.furtherDetails[i].title === "Japanese Knotweed") {
                let _value = ""
                if (props.furtherDetails[i].value >= 99) {
                    _value = "Category A: Action"
                } else if (95 <= props.furtherDetails[i].value < 99) {
                    _value = "Category B: Action"
                } else if (80 <= props.furtherDetails[i].value < 95) {
                    _value = "Category C: Action"
                } else if (props.furtherDetails[i].value < 80) {
                    _value = "Category D: Action"
                } else {
                    _value = "No Data Available"
                }
                props.furtherDetails[i].value = _value
            }
        }
        setFurtherDetails(props.furtherDetails)
    }, [props.valuation, props.valuationId, props.processedDate, props.furtherDetails])

    const handleInfoClick = () => {
        setShowInfoPanel(true);
    }

    const updateShowInfo = (show) => {
        setShowInfoPanel(show);
    }

    const handleShowDownloadError = (error) => {
        setError(error);
        setShowErrorDialog(true);
    }

    useEffect(() => {
        if (!valuation) return;
        if (valuation.predicted_valuation !== null) {
            setShowValuation(true)
        }
        if (valuation.accuracy !== null) {
            setPercentageStyle();
            setShowAccuracy(true)
        }
        if (valuation.valuation_date !== undefined && valuation.valuation_date !== null && valuation.valuation_date !== 0 &&
            valuation.predicted_value !== undefined && valuation.predicted_value !== null && valuation.predicted_value !== 0 &&
            valuation.historic_value !== undefined && valuation.historic_value !== null && valuation.historic_value !== 0) {
            setLastTransaction(true)
        }
    }, [valuation])

    return (
        <>
            {valuation ?
                <div className="valuation-result-container">
                    <div className="valuation-result-details">
                        <div className="current-predicted-container">
                            <div className="predicted-value-title">Current Valuation</div>
                            <div className="predicted-value-icon">
                                <img src={info_img} alt="info" onClick={handleInfoClick}/>
                            </div>
                            <div className="current-predicted-data-container">
                                <div className="predicted-value-amount">
                                    {showValuation ?
                                        <>
                                            £{valuation.predicted_valuation.toLocaleString()}
                                        </>
                                        : null}
                                </div>
                                <div className="accuracy-container">
                                    {showAccuracy ?
                                        <div className="circle-wrap">
                                            <div className="circle">
                                                <div className="mask full actual-degree" id="full">
                                                    <div className="fill actual-degree"></div>
                                                </div>
                                                <div className="mask half">
                                                    <div className="fill actual-degree"></div>
                                                </div>
                                                <div className="inside-circle">
                                                    <div className="accuracy-title">CONFIDENCE</div>
                                                    <div className="accuracy-value">{valuation.accuracy}</div>
                                                    <div className="accuracy-perc">%</div>
                                                </div>
                                            </div>
                                        </div>
                                        :null}
                                </div>
                            </div>
                        </div>
                        <div className="download-container">
                            <PdfDocument valuation={valuation} id={valuationId}
                                         showError={handleShowDownloadError.bind(this)}
                                         processedDate={processedDate} title={"Your Valuation Report"}/>
                        </div>
                    </div>
                    <div className="valuation-details-cards-title">Further Details</div>
                    <div className="valuation-cards">
                        {furtherDetails && furtherDetails.map((card, idx) => {
                            return (
                                <div className="valuation-card" key={idx}>
                                    <div className="valuation-card-icon">
                                        <img src={"/images/valuation/" + card.icon_filename} alt="Valuation Card Icon"/>
                                    </div>
                                    <div className="valuation-card-background">
                                        <img src={"/images/valuation/" + card.background_filename}
                                             alt="Valuation Card Background"/>
                                    </div>
                                    <div className="valuation-card-value">
                                        {card.value}
                                    </div>
                                    <div className="valuation-card-title">
                                        {card.title}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className="rics-transaction-container">
                        <div className="transaction-title">Last transaction</div>
                        <img src={"/images/valuation/last_transaction.png"} alt={"Last Transaction"}
                             className="last-transaction-card-image"/>
                        <div className="transaction-details">
                            {lastTransaction ?
                                <div className="predicted-valuation-container">
                                    <div className="predicted-value-stat">
                                        <div className="transaction-detail-heading">Transaction date</div>
                                        <div
                                            className="transaction-detail-text">{utils.short_display_date(valuation.valuation_date * 1000)}</div>
                                    </div>
                                    <div className="predicted-value-stat">
                                        <div className="transaction-detail-heading">Predicted valuation</div>
                                        <div
                                            className="transaction-detail-text">£{valuation.predicted_value.toLocaleString()}</div>
                                    </div>
                                    <div className="predicted-value-stat">
                                        <div className="transaction-detail-heading">Actual Sale price</div>
                                        <div
                                            className="transaction-detail-text">£{valuation.historic_value.toLocaleString()}</div>
                                    </div>
                                </div>
                                :
                                <div className="predicted-valuation-empty-container">
                                    We couldn't find a public record of a historical transaction for this property.
                                </div>
                            }
                        </div>
                    </div>
                </div>
                : null}
            {showInfoPanel ? <ValuationInfoPopup update={updateShowInfo.bind(this)}/> : null}
        </>
    )
}

export default MortgageValuation