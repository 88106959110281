import axiosInstance from "./http/axios_instance"
const http = axiosInstance("/protected/valuer/",  null, "/")

const getReports = (search) => http.post("/reports", search);
const getReport = (id) => http.get("/reports/" + id);
const getValuers = () => http.get("/valuers");
const getStatuses = () => http.get("/status");
const getManualValuerStatuses = () => http.get("/manual_status");
const changeValuer = (report_id, valuer_id) => http.post("/change/" + report_id + "/" + valuer_id);
const changeStatus = (report_id, status_id) => http.post("/manual_status/" + report_id + "/" + status_id);
const getPropertyModifications = (summary_id) => http.get("/property_modifications/" + summary_id);
const getReportPreview = (summary_id) => http.get("/report_preview/" + summary_id)
const getClients = () => http.get("/clients")
const getValuerCsv = (summary_id) => http.get("/csv/" + summary_id)

const getRicsNotifications = () => http.get("/rics_notifications")

export default {
    getReports,
    getReport,
    getValuers,
    getStatuses,
    getManualValuerStatuses,
    changeValuer,
    changeStatus,
    getPropertyModifications,
    getReportPreview,
    getClients,
    getValuerCsv,
    getRicsNotifications
}