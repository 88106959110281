import valuer_api from "../api/valuer";
import {useEffect, useState} from "react";
import auth from "../api/auth";
import MainHeader from "../components/MainHeader";
import ValuerReportsGrid from "../components/valuer/ValuerReportsGrid";
import ValuerHeaderTab from "../components/valuer/ValuerHeaderTab";


const Valuer = () => {

    const [reports, setReports] = useState();
    const [headings, setHeadings] = useState([]);
    const [activeSection, setActiveSection] = useState(1);

    const [headingsRICS, setHeadingsRICS] = useState([]);
    const [activeRICS, setActiveRICS] = useState(1)

    const [allNotifications, setAllNotifications] = useState(false)
    const [valuerNotifications, setValuerNotifications] = useState(false)

    const getHeadings = () => {
        let items = [];
        let item = {};
        item.id = 1;
        item.name= "Open";
        item.active = true;
        items.push(item);

        item = {};
        item.id = 2;
        item.name = "Closed";
        item.active = false;
        items.push(item);

        setHeadings(items);
    }

    const getRicsNotifications = () => {
        let response = valuer_api.getRicsNotifications()
        response.then(
            response => {
                if (response.data.all_notifications > 0) {
                    setAllNotifications(true)
                }
                if (response.data.valuer_notifications > 0) {
                    setValuerNotifications(true)
                }
            }
        ).catch(err => {
            console.log(err);
        })
    }

    const getHeadingsRICS = () => {
        let items = [];
        let item = {};
        item.id = 1;
        item.name= "All RICS Requests";
        item.active = true;
        items.push(item);

        item = {};
        item.id = 2;
        item.name = "My RICS requests";
        item.active = false;
        items.push(item);

        setHeadingsRICS(items);
    }

    useEffect(() => {
        getHeadingsRICS()
        getHeadings()
        getRicsNotifications()
    }, [])

    function handleHeadingClick(ev) {
        if (!ev.target.id) return;
        let newHeads = [...headings];
        for(let i=0;i<newHeads.length;i++){
            if (i === parseInt(ev.target.id)){
                newHeads[i].active = true;
                setActiveSection(i+1);
            }
            else {
                newHeads[i].active = false;
            }
        }
        setHeadings(newHeads);
    }

    function handleHeadingRicsClick(ev) {
        if (!ev.target.id) return;
        let newHeads = [...headingsRICS];
        for(let i=0;i<newHeads.length;i++){
            if (i === parseInt(ev.target.id)){
                newHeads[i].active = true;
                setActiveRICS(i+1);
            }
            else {
                newHeads[i].active = false;
            }
        }
        setHeadingsRICS(newHeads);
    }

    return (
        <>
            <MainHeader />
            <div className="valuer-page-container">
                <div className="valuer-page-title">
                    RICS requests
                </div>
                <div className="valuer-page-content-container">
                    <div className="valuer-page-content-header rics">
                        {headingsRICS && headingsRICS.map((heading, idx) => {
                            return (
                                <ValuerHeaderTab header={heading}
                                                 idx={idx}
                                                 handleHeadingRicsClick={handleHeadingRicsClick.bind(this)}
                                                 allNotifications={allNotifications}
                                                 valuerNotifications={valuerNotifications}
                                />
                            )
                        })}
                    </div>
                    <div className="valuer-page-content-header">
                        {headings && headings.map((heading, idx) => {
                            return (
                                <div className={heading.active ?
                                    "valuer-page-content-head active link" :
                                    "valuer-page-content-head link"} id={idx}
                                     onClick={handleHeadingClick}>{heading.name}
                                </div>
                            )
                        })}
                    </div>
                    <div className="valuer-page-content-grid-container">
                        {activeSection === 1 ?
                            <ValuerReportsGrid status={[6, 8, 10, 11, 12]}
                                               showOwn={activeRICS}
                            /> :
                            null
                        }
                        {activeSection === 2 ?
                            <ValuerReportsGrid status={[7, 9]}
                                               showOwn={activeRICS}
                            /> :
                            null
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default Valuer