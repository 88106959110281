import SplashScreen from "../components/SplashScreen";
import eye from "../assets/Eye.png";
import {useEffect, useState} from "react";
import regapi from "../api/register";
import kapi from "../api/kratos";
import auth from "../api/auth";
import validation from "../utils/InputValidation";
import logo from "../assets/logo-splash.png";
import {Link} from "react-router-dom";

const Register = () => {
    const [email, setEmail] = useState();
    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [password, setPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState();
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [flowResponse, setFlowResponse] = useState();
    const [agreeTerms, setAgreeTerms] = useState(false);
    const [validToken, setValidToken] = useState(false);
    const [showUnmatched, setShowUnmatched] = useState(false);

    const InitCorporateRegistration = (token) => {
        let response = regapi.getEmailFromToken(token);
        response.then(
            response => {
                // clear any existing user in storage
                localStorage.removeItem("user");
                // Remember this value for after init flow
                localStorage.setItem("register_token", token);

                setEmail(response.data.email);
                setValidToken(true);

                response = kapi.init_registration();
                response.then(response => {
                    setFlowResponse(response.data);
                }, function(err) {
                    console.log(err);
                })
            }
        ).catch((err) => {
            console.log(err);
        })
    }

    const handleNameChange = (ev, type) => {
        const result = ev.value.replace(/[^a-z]/gi, '');

        if (type === "first") {
            setFirstName(result)
        } else if (type === "last") {
            setLastName(result)
        }


    }

    const InitRetailRegistration = () => {
        // clear any existing user in storage
        localStorage.removeItem("user");

        let response = kapi.init_registration();
        response.then(response => {
            setFlowResponse(response.data);
        }, function(err) {
            console.log(err);
        })
    }

    const PageLoad = () => {
        const params = new URLSearchParams(window.location.search);
        const flowId = params.get('flow');
        const token = params.get('token');

        if (!flowId && token) {
            InitCorporateRegistration(token);
        }
        else if (!flowId && !token){
            InitRetailRegistration();
        }
        else {
            let response = regapi.getEmailFromToken(localStorage.getItem("register_token"));
            response.then( response => {
                setEmail(response.data.email);
                let resp = kapi.get_registration(flowId);
                resp.then(resp => {
                    setFlowResponse(resp.data);
                }).catch(err => {
                    console.log("Unable to retrieve registration: " + err);
                });
            })
        }

    }

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    }

    const toggleShowConfirmedPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    }

    const logout = () => {
        auth.logout();
    }

    const toggleAgreeTNC = () => {
        setAgreeTerms(!agreeTerms)
    }

    useEffect(() => {
        if (!confirmPassword || !password) {
            setShowUnmatched(false);
            return;
        }
        if (confirmPassword !== password) {
            setShowUnmatched(true);
        } else if (password.length < 1) {
            setShowUnmatched(false)
        } else if (confirmPassword.length < 1) {
            setShowUnmatched(false)
        } else {
            setShowUnmatched(false)
        }
    },[confirmPassword, password])

    useEffect(() => {
        PageLoad()
    }, []);

    return (
        <>
            <div className="register-page-container">
                <div className="sign-up-container">
                    <div className="register-form-container">
                        <form name="register-form" action={flowResponse && flowResponse.ui.action}
                              method="POST">
                            <div>
                                {flowResponse && flowResponse?.ui?.messages?.map((msg, idx) => {
                                    return (
                                        <div
                                            className="messages">{msg.text}</div>
                                    )
                                })}
                            </div>
                            <input name="csrf_token" type="hidden"
                                   value={flowResponse && flowResponse.ui.nodes.find(n => n.attributes.name === 'csrf_token')?.attributes.value}/>
                            <div className="register-title-container">
                                { validToken ?
                                    <>
                                        <div className="register-title">Welcome</div>
                                        <div className="register-type-title">Registration for Corporate Customers</div>
                                    </>
                                    :
                                    <>
                                        <div className="register-title">Welcome</div>
                                        <div className="register-type-title">Self Registration for Retail Customers</div>
                                    </>
                                }
                                <div className="register-title-logo">
                                    <img src={logo} alt="Finvise logo" />
                                </div>
                            </div>
                            { validToken ?
                                <div className="register-user-email">
                                    <input name="traits.email" type="hidden" value={email}/>
                                    <input name="traits.email" type="email" value={email} disabled required
                                           onChange={(ev) => setEmail(ev.target.value)}/>
                                    <div>{flowResponse && flowResponse.ui.nodes.find(n => n.attributes.name === 'traits.email')?.messages.map((msg, idx) => {
                                        return (
                                            <div className="messages">{msg.text}</div>
                                        )})}
                                    </div>
                                </div>
                                : null }
                            <div className="register-sub-title">Set up your account</div>
                            { validToken ? null :
                                <div className="field-input">
                                    <div className="label">Email</div>
                                    <div className="field-input-entry">
                                        <input placeholder="Email" name="traits.email"
                                               type="email" value={email}
                                               onChange={(ev) => setEmail(ev.target.value)}
                                               required />
                                        <div>{flowResponse && flowResponse.ui.nodes.find(n => n.attributes.name === 'traits.email')?.messages.map((msg, idx) => {
                                            return (
                                                <div className="messages">{msg.text}</div>
                                            )})}
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className="field-input">
                                <div className="label">Details</div>
                                <div className="field-input-entry">
                                    <input placeholder="First name" name="traits.name.first"
                                           type="text" value={firstName}
                                           onChange={(ev) => handleNameChange(ev.target, "first")}
                                           required
                                           title={validation.SAFE_STRING_TEXT} pattern={validation.SAFE_STRING_PATTERN}/>
                                    <div>{flowResponse && flowResponse.ui.nodes.find(n => n.attributes.name === 'traits.name.first')?.messages.map((msg, idx) => {
                                        return (
                                            <div className="messages">{msg.text}</div>
                                        )})}
                                    </div>
                                </div>
                            </div>
                            <div className="field-input">
                                <div className="field-input-entry">
                                    <input placeholder="Last name" name="traits.name.last"
                                           type="text" value={lastName}
                                           onChange={(ev) => handleNameChange(ev.target, "last")}
                                           required
                                           title={validation.SAFE_STRING_TEXT} pattern={validation.SAFE_STRING_PATTERN}/>
                                    <div>{flowResponse && flowResponse.ui.nodes.find(n => n.attributes.name === 'traits.name.last')?.messages.map((msg, idx) => {
                                        return (
                                            <div className="messages">{msg.text}</div>
                                        )})}
                                    </div>
                                </div>
                            </div>
                            <div className="field-input">
                                <div className="field-input-entry">
                                    <input placeholder="Set password" name="password"
                                           type={showPassword ? "text" : "password"} value={password}
                                           onChange={(ev) => setPassword(ev.target.value)}
                                           required/>
                                    <img src={eye} onClick={toggleShowPassword} alt="eye logo"
                                         className="eye"/>
                                    <div>{flowResponse && flowResponse.ui.nodes.find(n => n.attributes.name === 'password')?.messages.map((msg, idx) => {
                                        return (
                                            <div className="messages">{msg.text}</div>
                                        )})}
                                    </div>
                                </div>
                            </div>
                            <div className="password-size">Password must be minimum 8 characters.</div>
                            <div className="field-input">
                                <div className="field-input-entry">
                                    <input placeholder="Confirm password" name="confirm-password"
                                           type={showConfirmPassword ? "text" : "password"} value={confirmPassword}
                                           onChange={(ev) => setConfirmPassword(ev.target.value)}
                                           required/>
                                    <img src={eye} onClick={toggleShowConfirmedPassword} alt="eye logo"
                                         className="eye"/>
                                </div>
                            </div>
                            {showUnmatched ?
                                <div className="password-unmatched-container">
                                    <div className="password-unmatched-text">Passwords must match</div>
                                </div>
                                :null}
                            <div className="register-term-conditions-checkbox-container">
                                <input type="checkbox" className="register-term-conditions-checkbox" onClick={toggleAgreeTNC}/>
                                <label htmlFor="register-term-conditions-checkbox">I agree to the&thinsp;&thinsp;
                                    <Link className="register-term-conditions-link" to="/terms-and-conditions">
                                        Terms & Conditions
                                    </Link>
                                    <span className="register-term-conditions-checkbox-span">&thinsp;&thinsp;and&thinsp;&thinsp;</span>
                                    <Link className="register-term-conditions-link" to="https://finvise.com/privacy-policy">
                                        Privacy Policy
                                    </Link>

                                </label>
                            </div>
                            <div className="field-input">
                                <button className="register-button" name="method" type="submit" value="password"
                                        disabled={!password || !confirmPassword || confirmPassword !== password || !agreeTerms}>
                                    Sign up
                                </button>
                            </div>
                            <div className="field-input">
                                <div className="register-tologin label" onClick={logout}>
                                    Go back to sign-in
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
                <div className="splash-container">
                    <SplashScreen />
                </div>
            </div>
        </>
    )
}

export default Register