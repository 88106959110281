import {useEffect, useState} from "react";


const ButtonLoadingWheel = (props) => {

    const [show, setShow] = useState(false)

    useEffect(() => {
        setShow(props.show)
    }, [props.show]);

    return (
        <>
            {show && show ?
                <div className="button-loading-wheel-container">
                    <div className="loading-wheel-center" />
                </div>
                : null }
        </>
    )

}


export default ButtonLoadingWheel