import {useEffect} from "react";
import auth from "../api/auth";

const Logout = () => {

    useEffect(() => {
        auth.logout();
    }, [])

    return (
        <>
        </>
    )
}

export default Logout