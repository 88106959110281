import logo from "../assets/profile-logo.png";
import report_logo from "../assets/account_circle.png";
import dashboard_logo from "../assets/dashboard.png";
import logout_logo from "../assets/logout.png";
import roles from "../models/Roles";
import React from "react";

const HeaderProfileMenu = (props) => {
    const hide = props.hide;
    const profile = props.profile;
    const logout = props.logout;
    const notifications = props.notifications;

    const handleAdminDashboardClick = () => {
        hide();
        window.location.assign("/admin/dashboard");
    }

    const handleOrgAdminDashboardClick = () => {
        hide();
        window.location.assign("/admin/organisation");
    }

    const handleUserProfileClick = () => {
        hide();
        window.location.assign("/profile/details");
    }

    const handleUserProfileReportsClick = () => {
        hide();
        window.location.assign("/profile/reports");
    }

    const handleOutsideClick = () => {
        hide();
    }

    const handleLogoutClick = () => {
        logout();
    }

    return (
        <>
            <div className="page-mask" onClick={handleOutsideClick}/>
            <div className="header-profile-menu-container">
                <div className="header-profile-menu-heading">
                    <div className="header-profile-menu-logo">
                        <img src={logo} alt="Profile logo"/>
                    </div>
                    <div className="header-profile-menu-name">
                        {profile && profile.firstname} {profile && profile.lastname}
                    </div>
                    <div className="header-profile-menu-email">
                        {profile && profile.email}
                    </div>
                </div>
                <div className="header-profile-menu-content">
                    <div className="header-profile-menu-content-item link" onClick={handleUserProfileClick}>
                        <img src={logo} alt="Profile Logo"/>
                        Profile
                    </div>
                    {profile && profile.role_id !== roles.ROLE_VALUER ?
                        <div className="header-profile-menu-content-item link" onClick={handleUserProfileReportsClick}>
                            <img src={report_logo} alt="Reports Logo"/>
                            My reports
                            {notifications === true ?
                                <div className="notification-container header-menu"/>
                                :null}
                        </div>
                        : null }
                    {profile && profile.role_id === roles.ROLE_ADMIN ?
                        <div className="header-profile-menu-content-item link" onClick={handleAdminDashboardClick}>
                            <img src={dashboard_logo} alt="Dashboard Logo"/>
                                Admin Dashboard
                        </div>
                        : null}
                    {profile && profile.role_id === roles.ROLE_ORGANISATION_ADMIN ?
                        <div className="header-profile-menu-content-item link" onClick={handleOrgAdminDashboardClick}>
                            <img src={dashboard_logo} alt="Dashboard Logo"/>
                            Admin Dashboard
                        </div>
                        : null}
                    <div className="header-profile-menu-content-item link" onClick={handleLogoutClick}>
                        <img src={logout_logo} alt="Logout Logo" />
                        Logout
                    </div>
                </div>
            </div>
        </>
    )
}

export default HeaderProfileMenu