import close_icon from "../assets/Close.png";
const ValuationInfoPopup = (props) => {
    const update = props.update;

    const handleCloseClick = () => {
        update(false);
    }

    return (
        <>
            <div className="page-mask dark" onClick={() => {handleCloseClick()}}/>
            <div className="valuation-info-panel-container">
                <div className="valuation-info-header-container">
                    <div className="valuation-info-panel-heading">
                        How do we calculate the FINVISE valuation?
                    </div>
                    <img src={close_icon} alt="Close icon" onClick={handleCloseClick} className="link"/>
                </div>
                <div className="valuation-info-panel-text">
                    <p>FINVISE uses a neural network model to replicate the valuation process that a human would take.</p>
                    <p>The model draws data from several sources to build an estimate of value. Every four weeks the
                        data is updated so you can be sure that the figure is based on up-to-date input data.</p>
                    <p>Note that this valuation is only an estimate based on information from third party
                        data sources. The estimate will not include any recent physical changes to the property
                        such as the addition of extensions, or reconfiguration of internal rooms.</p>
                    <p>The confidence level is calculated as the percentage difference between the actual historic price
                        and our AI's predicted historical price.</p>
                </div>
            </div>
        </>
    )
}

export default ValuationInfoPopup