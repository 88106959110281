import {useEffect, useState} from "react";
import valuer_api from "../../api/valuer";
import search_logo from "../../assets/profile_search.png";
import date_formatter from "../../utils/DateUtils";
import PageNavigation from "../PageNavigation";
import {useNavigate} from "react-router-dom";
import ValuationStatusPill from "../ValuationStatusPill";
import valuation_api from "../../api/valuation";
import downarrow from "../../assets/arrow_down.png"
import ValuerFilterDropdown from "./ValuerFilterDropdown";

const ValuerReportsGrid = (props) => {
    const status = props.status;
    const showOwn = props.showOwn;
    const navigate = useNavigate();
    const [propertySearchString, setPropertySearchString] = useState();
    const [reports, setReports] = useState();
    const [states, setStates] = useState();
    const PAGE_SIZE = 8
    const [page, setPage] = useState(1)
    const [maxPage, setMaxPage] = useState()

    const [valuers, setValuers] = useState([])
    const [selectedValuer, setSelectedValuer] = useState({firstname: "Valuer", lastname:""})

    const [clients, setClients] = useState([])
    const [selectedClient, setSelectedClient] = useState({name: "Client"})

    const getValuers = () => {
        let response = valuer_api.getValuers();
        response.then(response => {
            let _valuers = response.data.valuers
            setValuers(_valuers)
        }).catch(err => {
            console.log(err);
        })
    }

    const getClients = () => {
        let response = valuer_api.getClients();
        response.then(response => {
            let _clients = response.data.clients
            setClients(_clients)
        }).catch(err => {
            console.log(err);
        })
    }

    const getReports = () => {
        let _get_own = false
        if (showOwn === 2) _get_own = true;
        let _valuer = selectedValuer;
        if (_valuer.firstname === "Valuer") _valuer = null;
        let _client = selectedClient;
        if (_client.name === "Client") _client = null;
        const searchData = {
            "search": propertySearchString,
            "page": page,
            "page_size": PAGE_SIZE,
            "status": status,
            "get_own": _get_own,
            "valuer": _valuer,
            "client": _client
        }
        let response = valuer_api.getReports(searchData);
        response.then(response => {
            for (let i=0;i<response.data.reports.length;i++){
                response.data.reports[i].remaining_time = setFormattedRemainingTime(response.data.reports[i].request_date);
                response.data.reports[i].status_name = getStateName(response.data.reports[i].current_status_id);
            }
            setReports(response.data.reports);
            setMaxPage(response.data.total_pages);
        }).catch(err => {
            console.log(err);
        })
    }

    const getStates = () => {
        let response = valuer_api.getStatuses()
        response.then(
            response => {
                let list = [{"id":6,"name":"New"}];
                list.push(...response.data.statuses);
                setStates(list);
            }
        ).catch(err => {
            console.log(err);
        })
    }

    const setFormattedRemainingTime = (tm) => {
        if (!tm) return "";
        let result = "";
        const day = 60*60*24*1000;
        let today = new Date();
        const day_ago = (today.getTime() - day);
        let diff = (tm * 1000) - day_ago;
        if (diff < 0)
            result = "EXPIRED";
        else
            result = date_formatter.date_display_diff(diff);
        return result;
    }

    const updateRemainingTime = () => {
        if (!reports) return;
        let updatedReports = [...reports];
        for (let i=0;i<reports.length;i++){
            updatedReports[i].remaining_time = setFormattedRemainingTime(reports[i].request_date);
        }
        setReports(updatedReports);
    }

    const getStateName = (id) => {
        if (!states) return;
        let state = states.find((s) => s.id === parseInt(id));

        return state.name;
    }

    const nextPage = (ev) => {
        if (!page || !ev || page === maxPage) return;
        setPage(page+1);
    }

    const previousPage = (ev) => {
        if (!page || !ev || page === 1) return;
        setPage(page-1);
    }

    const numberPageClick = (page_number) => {
        if (!page_number) return;
        setPage(page_number);
    }

    useEffect(() => {
        getStates()
    }, [])

    useEffect(() => {
        if (!states) return;
        getReports()
        getValuers()
        getClients()
    }, [states, showOwn])

    useEffect(() => {
        getReports()
    }, [propertySearchString, page, showOwn, selectedValuer, selectedClient])

    function handleRowClick(ev) {
        if (!ev.target.id) return;
        const report = reports[parseInt(ev.target.id)]
        if (report.notifications)
            valuation_api.deleteNotifications(report.summary_id);
        navigate("/valuer/review/" + report.id);
    }

    const ChangeClient = (client) => {
        if (!client) return;
        setSelectedClient(client)
    }

    const ChangeValuer = (valuer) => {
        if (!valuer) return;
        setSelectedValuer(valuer)
    }

    const ClearFilter = (type) => {
        if (!type) return;
        if (type === "Client") {
            setSelectedClient({name: "Client"})
        } else {
            setSelectedValuer({firstname: "Valuer", lastname:""})
        }
    }

    const CheckValuation = (valuation) => {
        let _valuation = "None"
        if (typeof(valuation) === "number") {
            _valuation = "£" + valuation.toLocaleString()
        }
        return _valuation
    }

    return (
        <>
            <div className="valuer-report-grid-container">
                <div className="valuer-report-grid-header-container">
                    <ValuerFilterDropdown data={selectedClient.name}
                                          changeValue={ChangeClient}
                                          options={clients}
                                          type="Client"
                                          clearFilter={ClearFilter.bind(this)}
                    />
                    {showOwn === 1 ?
                        <ValuerFilterDropdown data={selectedValuer.firstname + " " + selectedValuer.lastname}
                                              changeValue={ChangeValuer}
                                              options={valuers}
                                              type="Valuer"
                                              clearFilter={ClearFilter.bind(this)}
                        />
                        :null}
                    <div className="report-detail-search-container">
                        <input type="text" placeholder="Search for property" value={propertySearchString}
                               onChange={(ev) => setPropertySearchString(ev.target.value)}/>
                        <div className="link">
                            <img src={search_logo} id="search-icon"/>
                        </div>
                    </div>
                </div>
                <div className="valuer-report-grid">
                    <div className="valuer-report-grid-col-headers">
                        <div className="address col">Property address</div>
                        <div className="postcode col">Postcode</div>
                        <div className="org_name col">Client</div>
                        <div className="username col">Client user</div>
                        <div className="date col">Requested at</div>
                        <div className="reference col">Ref</div>
                        <div className="valuation col">MV</div>
                        <div className="remaining col">Time left</div>
                        <div className="ticket col">Status</div>
                        <div className="ticket col">Assignee</div>
                        <div className="status col">Report status</div>
                    </div>
                    <div className="valuer-report-grid-rows">
                        {reports && reports.map((report, idx) => {
                            return (
                                <>
                                    <div className="valuer-report-grid-row link" onClick={handleRowClick} id={idx}>
                                        <div className="address col" id={idx}>{report.address}</div>
                                        <div className="postcode col" id={idx}>{report.postcode}</div>
                                        <div className="org_name col" id={idx}>{report.org_name}</div>
                                        <div className="username col" id={idx}>{report.user_name}</div>
                                        <div className="date col" id={idx}>{date_formatter.display_date_time(report.request_date*1000)}</div>
                                        <div className="reference col" id={idx}>{report.reference}</div>
                                        <div className="valuation col" id={idx}>{CheckValuation(report.valuation)}</div>
                                        <div className="remaining col" id={idx}>{report.remaining_time}</div>
                                        <div className="ticket col" id={idx}>
                                            {report.status_name}
                                            {report.notifications !== null ?
                                                <div className="notification-container small"/>
                                                :null}
                                        </div>
                                        <div className="ticket col" id={idx}>{report.valuer_name}</div>
                                        <div className="status col" id={idx} onClick={handleRowClick}>
                                            <ValuationStatusPill status_id={report.summary_status_id}
                                                                 status_name={report.summary_status_name}/>
                                        </div>
                                    </div>
                                </>
                            )
                        })}
                    </div>
                    <div className="report-detail-page-navigation-container">
                        <PageNavigation page={page}
                                        maxPage={maxPage}
                                        previousPage={previousPage.bind(this)}
                                        numberPageClick={numberPageClick.bind(this)}
                                        nextPage={nextPage.bind(this)}/>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ValuerReportsGrid