import {useEffect, useState} from "react";

const PageSubMenu = (props) => {

    const contextMenu = props.items;
    const title = props.title;
    const contextImage = props.image;
    const spacing = parseInt(props.spacing);
    const indicatorWidth = props.indicator;
    const startingLeft = props.startingLeft;
    const contextSelection = props.setSelection;

    const [menu, setMenu] = useState();
    const [leftValue, setLeftValue] = useState(0);

    const menuStyles = {
        indicator: {
            left: leftValue,
            width: parseInt(indicatorWidth)
        }
    }

    const [hideSingleMenuItem, setHideSingleMenuItem] = useState(true)

    const activePage = props.setActiveMenu;

    useEffect(() => {
        if (props.hideSingle === undefined) return;
        setHideSingleMenuItem(props.hideSingle);
    }, [props.hideSingle]);

    const clickMenu = (ev) => {
        if (ev.target.textContent === "") return;
        updateMenu(ev.target.textContent);
    }

    const updateMenu = (selection) => {
        if (!menu && !contextMenu) return;
        let updateMenu = menu ? [...menu] : [...contextMenu];
        let total_pixels = startingLeft ? startingLeft : 32;
        let idx = updateMenu.map(item => item.name.toUpperCase()).indexOf(selection.toUpperCase());
        for(let i=0; i<updateMenu.length; i++){
            if (i !== idx){
                if (title==="The local area") {
                    updateMenu[i].activeClass = "local link noselect submenu-item";
                } else {
                    updateMenu[i].activeClass = "link noselect submenu-item";
                }

                total_pixels += updateMenu[i].width + spacing;
            }
            else {
                if (title==="The local area") {
                    updateMenu[i].activeClass = "active local link noselect submenu-item";
                } else {
                    updateMenu[i].activeClass = "active link noselect submenu-item";
                }
                let pixels = (updateMenu[i].width/2) + total_pixels - indicatorWidth/2;
                setLeftValue(pixels);
                activePage(i);
            }
        }
        setMenu(updateMenu);
    }

    useEffect(() => {
        if (!contextSelection) return;
        updateMenu(contextSelection);
    }, [contextSelection, contextMenu]);

    useEffect(() => {
        if (contextMenu && contextMenu.length > 0){
            setMenu(contextMenu);
            let total_pixels = startingLeft ? startingLeft : 32;
            for(let i=0; i<contextMenu.length; i++){
                if (contextMenu[i].activeClass.includes("active")){
                    let pixels = (contextMenu[i].width/2) + total_pixels - indicatorWidth/2;
                    setLeftValue(pixels);
                    activePage(i);
                }
                else {
                    total_pixels += contextMenu[i].width + spacing
                }
            }
        }
    }, [contextMenu]);

    return (
        <>
            <div className={title ? "page-submenu-heading local" : "page-submenu-heading"}>
                <div className="page-submenu-container">

                    <ul>
                        {title ?
                            <div className="page-submenu-heading-block start"/>
                            :null}
                        {menu && menu.map((_menu, idx) => {
                            return (
                                <li className={menu && menu.length === 1 && hideSingleMenuItem === true ?
                                    _menu.activeClass + " hidden" : _menu.activeClass}
                                    key={idx}
                                    id="submenu"
                                    onClick={clickMenu}
                                    style={{minWidth:_menu.width, marginRight:spacing + "px"}}>
                                    {_menu.name}
                                </li>
                            )
                        })}
                        {title ?
                            <div className="page-submenu-heading-block end"/>
                            :null}
                    </ul>

                </div>
            </div>
        </>
    )
}

export default PageSubMenu;