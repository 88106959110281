import {useEffect, useState} from "react";
import OrganisationConstants from "../models/OrganisationConstants";
import admin_credit_api from "../api/admin/admin_credits";
import GenericDialog from "./GenericDialog";

const OrganisationCreditDetails = (props) => {
    const organisation = props.organisation;
    const updateSection = props.updateSection;
    const [balance, setBalance] = useState();
    const [allowance, setAllowance] = useState();
    const [finviseBalance, setFinviseBalance] = useState();
    const [finviseAllowance, setFinviseAllowance] = useState();
    const [ricsBalance, setRicsBalance] = useState();
    const [ricsAllowance, setRicsAllowance] = useState();
    const [insightBalance, setInsightBalance] = useState();
    const [insightAllowance, setInsightAllowance] = useState();
    const [creditBased, setCreditBased] = useState();
    const [usageBased, setUsageBased] = useState();
    const [freeTrial, setFreeTrial] = useState(false)

    const [error, setError] = useState();
    const [showErrorDialog, setShowErrorDialog] = useState(false);
    const okButton = [{"text":"OK", "id": "1"}];

    const handleCancelClick = () => {
        updateSection(OrganisationConstants.SUMMARY_SECTION);
    }

    const handleOKDialogResult = (res) => {
        setShowErrorDialog(false);
    }

    const handleSaveClick = () => {
        let method = 1;
        let _finvise_allowance = finviseAllowance;
        let _finvise_balance = finviseBalance;
        let _rics_allowance = ricsAllowance;
        let _rics_balance = ricsBalance;
        let _insight_allowance = insightAllowance;
        let _insight_balance = insightBalance;
        if (creditBased) {
            if (_finvise_allowance === null || _finvise_balance === null || _rics_allowance === null ||
                _rics_balance === null || _insight_allowance === null || _insight_balance === null ||
                _finvise_allowance === undefined || _finvise_balance === undefined || _rics_allowance === undefined ||
                _rics_balance === undefined || _insight_allowance === undefined || _insight_balance === undefined) {
                setError("Missing values for credits.")
                setShowErrorDialog(true)
                return;
            }
        }
        if (usageBased) {
            method = 2;
            _finvise_allowance = 0;
            _finvise_balance = 0;
            _rics_allowance = 0;
            _rics_balance = 0;
            _insight_allowance = 0;
            _insight_balance = 0;
        }
        let _allowance = {finvise:_finvise_allowance, rics: _rics_allowance, insight: _insight_allowance}
        let _balance = {finvise:_finvise_balance, rics: _rics_balance, insight: _insight_balance}
        let body = {
            "organisation_id": organisation.id,
            "balance": _balance,
            "allowance": _allowance,
            "billing_method": method,
            "free_trial": freeTrial
        }
        let response = admin_credit_api.updateCredits(body);
        response.then(
            response => {
                updateSection(OrganisationConstants.SUMMARY_SECTION);
            }
        ).catch(() => {})
    }

    const CheckValue = (value) => {
        if (!value) return;
        let _value = value
        if (_value < 0) {
            _value = 0
        }
        return _value;
    }

    useEffect(() => {
        setFinviseBalance(organisation.finvise_balance);
        setFinviseAllowance(organisation.finvise_allowance);
        setRicsBalance(organisation.rics_balance);
        setRicsAllowance(organisation.rics_allowance);
        setInsightBalance(organisation.mi_balance);
        setInsightAllowance(organisation.mi_allowance);
        setAllowance(organisation.credit_allowance);
        if (organisation.billing_method === 1){
            setCreditBased(true);
            setUsageBased(false);
        }
        else {
            setCreditBased(false);
            setUsageBased(true);
        }
        setFreeTrial(organisation.free_trial)
    }, [organisation])

    function handleUsageClick() {
        setFinviseBalance(0);
        setFinviseAllowance(0);
        setRicsBalance(0);
        setRicsAllowance(0);
        setInsightBalance(0);
        setInsightAllowance(0);
        setUsageBased(true);
        setCreditBased(false);
    }

    function handleFreeTrialClick() {
        setFreeTrial(!freeTrial)
    }

    return (
        <>
            <div className="credit-detail-container">
                <div className="credit-detail-title">
                    {organisation && organisation.name}
                </div>
                <div className="admin-neworg-billing-container">
                    <div className="admin-neworg-field-label">Billing method</div>
                    <div className="admin-neworg-billing-cards-container">
                        <div className="admin-neworg-billing-card">
                            <label className="billing-check-container">
                                <input type="checkbox" checked={creditBased} onChange={() => {
                                    setCreditBased(!creditBased);
                                    setUsageBased(!usageBased)
                                }}/>
                                <span className="checkmark"></span>
                                <div className="billing-check-label">Credit-based</div>
                            </label>
                            <div className="admin-neworg-credit-container">
                                <div className="admin-neworg-field-label">Credit allowance per month</div>
                                <div className="admin-neworg-field-label">FINVISE allowance</div>
                                <input type="number" value={finviseAllowance} onChange={(ev) => setFinviseAllowance(CheckValue(ev.target.value))}/>
                                <div className="admin-neworg-field-label">FINVISE balance</div>
                                <input type="number" value={finviseBalance} onChange={(ev) => setFinviseBalance(CheckValue(ev.target.value))}/>
                                <div className="admin-neworg-field-label">RICS allowance</div>
                                <input type="number" value={ricsAllowance} onChange={(ev) => setRicsAllowance(CheckValue(ev.target.value))}/>
                                <div className="admin-neworg-field-label">RICS balance</div>
                                <input type="number" value={ricsBalance} onChange={(ev) => setRicsBalance(CheckValue(ev.target.value))}/>
                                <div className="admin-neworg-field-label">Market Insight allowance</div>
                                <input type="number" value={insightAllowance} onChange={(ev) => setInsightAllowance(CheckValue(ev.target.value))}/>
                                <div className="admin-neworg-field-label">Market Insight balance</div>
                                <input type="number" value={insightBalance} onChange={(ev) => setInsightBalance(CheckValue(ev.target.value))}/>
                            </div>
                        </div>
                        <div className="admin-edit-billing-card">
                            <label className="billing-check-container">
                                <input type="checkbox" checked={usageBased} onChange={handleUsageClick}/>
                                <span className="checkmark"></span>
                                <div className="billing-check-label">Usage-based</div>
                            </label>
                        </div>
                    </div>
                    <div className="admin-neworg-field-label free-trial">Free trial</div>
                    <div className="admin-neworg-billing-cards-container">
                        <div className="admin-edit-billing-card short">
                            <label className="billing-check-container">
                                <input type="checkbox" checked={freeTrial} onChange={handleFreeTrialClick}/>
                                <span className="checkmark"></span>
                                <div className="billing-check-label">Free trial</div>
                            </label>
                        </div>
                    </div>
                </div>
                <div className="credit-detail-buttons">
                    <div className="credit-detail-save-button link" onClick={handleSaveClick}>Save changes</div>
                    <div className="credit-detail-cancel-button link" onClick={handleCancelClick}>Cancel</div>
                </div>
            </div>
            {showErrorDialog ?
                <GenericDialog title="Credit Update Error" text={error} buttons={okButton}
                               result={handleOKDialogResult.bind(this)} /> : null }
        </>
    )
}

export default OrganisationCreditDetails