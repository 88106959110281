import {useEffect, useState} from "react";

const PropertyCards = (props) => {

    // Report Data
    const details = props.propertyDetails;

    // Error Showing
    const setError = props.setError
    const setShowErrorDialog = props.setShowErrorDialog

    const handleShowDownloadError = (error) => {
        setError(error);
        setShowErrorDialog(true);
    }

    return (
        <div className="valuation-card insight">
            <div className="valuation-card-icon">
                <img src={"/images/valuation/" + details.icon_filename} alt="Valuation Card Icon"/>
            </div>
            <div className="valuation-card-background">
                <img src={"/images/valuation/" + details.background_filename} alt="Valuation Card Background"/>
            </div>
            <div className="valuation-card-value">
                {details.value}
            </div>
            <div className="valuation-card-title">
                {details.title}
            </div>
        </div>
    )
}

export default PropertyCards