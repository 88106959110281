import axiosInstance from "../http/axios_instance"
const http = axiosInstance("/protected/admin",  null, "/dashboard")

const createTeam = (org_id, team) => http.post("/teams/" + org_id, team);

const getTeams = (org) => org ?
    http.get("/teams/" + org.id) :
    http.get("/teams");

export default {
    createTeam,
    getTeams
}