import {useEffect, useRef, useState} from "react";
import address_api from "../../api/address";
import property_api from "../../api/property"
import styles from "../../models/Styles";
import format from "../../utils/Address";
import roles from "../../models/Roles";
import val_api from "../../api/valuation";
import AddressLookupFlyout from "../AddressLookupFlyout";
import arrow from "../../assets/arrow_forwards.png"
import SearchDetailRow from "../SearchDetailRow";
import TabTypeSelector from "./TabTypeSelector";
import RICSInputBar from "./RICSInputBar";
import MarketInsightInputBar from "./MarketInsightInputBar";
import FINVISEInputBar from "./FINVISEInputBar";

const ValuationAddressSearch = (props) => {
    const evaluate = props.evaluate;
    const manual_evaluate = props.manualEvaluate

    const clearBar = props.clearBar
    const setClearBar = props.setClearBar

    // Values for Request
    const [selectedType, setSelectedType] = useState(null)
    const [lastSelectedType, setLastSelectedType] = useState(null)

    useEffect(() => {
        if (clearBar) resetSearchBar()
        setClearBar(false)
    }, [clearBar])

    // Clear SearchBar
    const resetSearchBar = () => {
        let _tab = selectedType
        setSelectedType({"id": 0})
        setLastSelectedType(_tab)
    }
    useEffect(() => {
        if (selectedType && selectedType.id===0 && lastSelectedType) setSelectedType(lastSelectedType)
    }, [selectedType])

    return (
        <>
            <form className="valuation-form" autoComplete="off" id="address-search-form">
                <TabTypeSelector setType={setSelectedType} />
                {
                    selectedType && selectedType.id === 2 ?
                        <RICSInputBar selectedType={selectedType} reset={resetSearchBar.bind(this)} evaluate={evaluate} manual_evaluate={manual_evaluate} />
                    : selectedType && selectedType.id === 1 ?
                        <FINVISEInputBar selectedType={selectedType} reset={resetSearchBar.bind(this)} evaluate={evaluate}/>
                    : selectedType && selectedType.id === 3 ?
                        <MarketInsightInputBar selectedType={selectedType} reset={resetSearchBar.bind(this)} evaluate={evaluate}/>
                            : null
                }
            </form>
        </>
    )
}

export default ValuationAddressSearch