import arrow from "../../assets/arrow_back.png";
import {useEffect, useState} from "react";
import admin_api from "../../api/admin/admin_valuer";
const RICSHubValuerProfile = (props) => {
    const valuer = props.valuer;
    const [status, setStatus] = useState();
    const close = props.close;

    useEffect(() => {
        if (!valuer) return;
        setStatus(valuer.status);
    }, [valuer])

    const handleChangeStatus = (ev) => {
        if (!ev.target.value) return;
        setStatus(parseInt(ev.target.value));
    }

    const handleSaveClick = () => {
        let response = admin_api.changeValuerStatus(valuer.id, status);
        response.then(
            () => {
                close()
            }
        ).catch(err => {
            console.log(err);
        })
    }

    const handleBackClick = () => {
        close()
    }

    return (
        <>
            <div>
                <div className="rics-hub-valuer-heading">
                    <img className="link" src={arrow} alt="arrow icon" onClick={handleBackClick}/>
                    <h1>{valuer && valuer.firstname.substring(0,1) + ". " + valuer.lastname }</h1>
                </div>
                <div className="rics-hub-valuer-content">
                    <div className="rics-hub-vauler-details">
                        <label>Status</label>
                        <select value={status} onChange={handleChangeStatus}>
                            <option value={1}>Active</option>
                            <option value={2}>Inactive</option>
                        </select>
                    </div>
                    <div className="rics-hub-valuer-buttons">
                        <button disabled={valuer && valuer.status === status} onClick={handleSaveClick}>Save</button>
                    </div>
                </div>
            </div>


        </>
    )
}

export default RICSHubValuerProfile