import dateUtils from "../../utils/DateUtils";
import ValuerOverviewMessenger from "./ValuerOverviewMessenger";

const ValuerReviewOverview = (props) => {
    const summaryId = props.summaryId;
    const history = props.history;
    const profile = props.profile;
    const updateHistory = props.updateHistory;

    return (
        <>
            <div className="valuer-overview-container">
                <div className="valuer-overview-grid-rows">
                    {history && history.map((entry) => {
                        return(
                            <>
                                {entry.type_id !== 1 && entry.comment && entry.comment ?
                                    <div className="valuer-overview-grid-row">
                                        <div className="comment col">{entry.comment}</div>
                                        <div className="date col">{dateUtils.display_date_time(entry.entry_date*1000)}</div>
                                    </div>
                                : null }
                            </>
                        )
                    })}
                </div>
                {history && profile && <ValuerOverviewMessenger profileRole={profile.role_id}
                                         history={history}
                                         summaryId={summaryId} getHistory={updateHistory}/>}
            </div>
        </>
    )
}

export default ValuerReviewOverview