import axiosInstance from "../http/axios_instance"
const http = axiosInstance("/protected/admin",  null, "/dashboard")

const getReportDetails = (org_id, search) => (search) ? http.get("/report_details/" + org_id + "?search=" + search)
        : http.get("/report_details/" + org_id);
const getAllReportDetails = (filters) => http.post("/all_report_details", filters);
const getManagementReports = (org_id, body) => http.post("/management_reports/" + org_id, body);
const downloadCSV = (filters) => http.post("/reports_csv", filters);

export default {
    getReportDetails,
    getAllReportDetails,
    getManagementReports,
    downloadCSV
}