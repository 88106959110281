import {useEffect, useState} from "react";
import search_logo from "../assets/profile_search.png";
import date_formatter from "../utils/DateUtils";
import report_api from "../api/admin/admin_report";
import org_report_api from "../api/orgadmin/orgadmin_report";
import teams_api from "../api/admin/admin_teams";
import org_teams_api from "../api/orgadmin/orgadmin_teams";
import members_api from "../api/admin/admin_people";
import org_members_api from "../api/orgadmin/orgadmin_people";
import val_api from "../api/valuation";
import ranges from "../models/DateRanges";
import AdminDropDownList from "./AdminDropDownList";
import org_api from "../api/admin/admin_orgs";
import PageNavigation from "./PageNavigation";
import DateRangePickerDialog from "./DateRangePickerDialog";
import papi from "../api/pdf";
import address_formatter from "../utils/Address";
import GenericDialog from "./GenericDialog";
import download from "../assets/download.png";

const AdminReportOverviewGrid = (props) => {
    const profile = props.profile;
    const org = props.organisation;
    const [organisations, setOrganisations] = useState();
    const [teams, setTeams] = useState();
    const [teamMembers, setTeamMembers] = useState();
    const [propertySearchString, setPropertySearchString] = useState("");
    const [selectedReportTypes, setSelectedReportTypes] = useState();
    const [selectedDateRange, setSelectedDateRange] = useState();
    const [selectedOrganisations, setSelectedOrganisations] = useState();
    const [selectedTeams, setSelectedTeams] = useState();
    const [selectedTeamMembers, setSelectedTeamMembers] = useState();
    const [reportTypes, setReportTypes] = useState();
    const [details, setDetails] = useState();
    const [dateRanges, setDateRanges] = useState();
    const [filter, setFilter] = useState();
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [showDownloading, setShowDownloading] = useState(false);
    const [showErrorDialog, setShowErrorDialog] = useState(false);
    const [error, setError] = useState();
    const okButton = [{"text":"OK", "id": "1"}];

    const PAGE_SIZE = 8
    const [page, setPage] = useState(1)
    const [maxPage, setMaxPage] = useState()


    const getDetails = () => {
        if (!filter) return;
        if (!profile) return;

        let json = JSON.stringify(filter);

        // Use api for correct role
        let response = null;
        if (profile.role_id === 3)
            response = report_api.getAllReportDetails(json);
        else if (profile.role_id === 2)
            response = org_report_api.getAllReportDetails(json);

        response.then(
            response => {
                setDetails(response.data.report_details);
                setMaxPage(response.data.total_pages);
            }
        )
    }

    const getReportTypes = () => {
        let response = val_api.getValuationTypes();
        response.then(response => {
            setReportTypes(response.data.types);
        })
    }

    const getOrganisations = () => {
        if (!profile || profile.role_id !== 3) return;
        let response = org_api.getOrganisations();
        response.then(
            response => {
                setOrganisations(response.data.organisations);
            }
        )
    }

    const getTeams = () => {
        // Use api for correct role
        let response = null;
        if (profile.role_id === 3) {
            response = teams_api.getTeams(org);
        }
        else if (profile.role_id === 2)
            response = org_teams_api.getTeams();

        response.then(
            response => {
                setTeams(response.data.teams);
            }
        )
    }

    const getTeamMembers = () => {
        // Use api for correct role
        let response = null;
        if (profile.role_id === 3)
            response = members_api.getAllTeamMembers(org);
        else if (profile.role_id === 2)
            response = org_members_api.getAllTeamMembers();
        response.then(
            response => {
                setTeamMembers(response.data.members);
            }
        )
    }

    const getDateRanges = () => {
        setDateRanges(ranges.GetDateRanges());
    }

    const addSelectedReportType = (id) => {
        let types = [];
        if (selectedReportTypes)
            types = [...selectedReportTypes];
        const alreadySelected = types.find((t) => t.id === parseInt(id));
        if (!alreadySelected){
            let item = reportTypes.find((t) => t.id === parseInt(id));
            if (item) {
                types.push(item);
                setSelectedReportTypes(types);
            }
        }
    }

    const removeSelectedReportType = (ev) => {
        if (!ev.target.id) return;
        let types = [...selectedReportTypes];
        const selected = types.findIndex((t) => t.id === parseInt(ev.target.id));
        if (selected < types.length){
            types.splice(selected, 1);
            setSelectedReportTypes(types);
        }
    }

    const addSelectedDateRange = (id) => {
        let dates = [];
        if (selectedDateRange)
            dates = [...selectedDateRange];
        if (dates.length === 0){
            if (ranges.DATE_RANGE_USER_DEFINED === parseInt(id)){
                setShowDatePicker(true);
            }
            else {
                let item = dateRanges.find((t) => t.id === parseInt(id));
                if (item) {
                    dates.push(item);
                    setSelectedDateRange(dates);
                }
            }
        }
    }

    const removeSelectedDateRange = (ev) => {
        if (!ev.target.id) return;
        let dates = [...selectedDateRange];
        const selected = dates.findIndex((t) => t.id === parseInt(ev.target.id));
        if (selected < dates.length){
            dates.splice(selected, 1);
            setSelectedDateRange(dates);
        }
    }

    const handleClosePicker = (start, end) => {
        let dates = [];

        let item = dateRanges.find((t) => t.id === ranges.DATE_RANGE_USER_DEFINED);
        if (item) {
            item.start_date = start;
            item.end_date = end;
            dates.push(item);
            setSelectedDateRange(dates);
        }

        setShowDatePicker(false);
    }

    const addSelectedOrganisation = (id) => {
        let orgs = [];
        if (selectedOrganisations)
            orgs = [...selectedOrganisations];
        const alreadySelected = orgs.find((t) => t.id === parseInt(id));
        if (!alreadySelected){
            let item = organisations.find((t) => t.id === parseInt(id));
            if (item) {
                orgs.push(item);
                setSelectedOrganisations(orgs);
            }
        }
    }

    const removeSelectedOrganisation = (ev) => {
        if (!ev.target.id) return;
        let orgs = [...selectedOrganisations];
        const selected = orgs.findIndex((t) => t.id === parseInt(ev.target.id));
        if (selected < orgs.length){
            orgs.splice(selected, 1);
            setSelectedOrganisations(orgs);
        }
    }

    const addSelectedTeam = (id) => {
        let tms = [];
        if (selectedTeams)
            tms = [...selectedTeams];
        const alreadySelected = tms.find((t) => t.id === parseInt(id));
        if (!alreadySelected){
            let item = teams.find((t) => t.id === parseInt(id));
            if (item) {
                tms.push(item);
                setSelectedTeams(tms);
            }
        }
    }

    const removeSelectedTeam = (ev) => {
        if (!ev.target.id) return;
        let tms = [...selectedTeams];
        const selected = tms.findIndex((t) => t.id === parseInt(ev.target.id));
        if (selected < tms.length){
            tms.splice(selected, 1);
            setSelectedTeams(tms);
        }
    }

    const addSelectedTeamMember = (id) => {
        let members = [];
        if (selectedTeamMembers)
            members = [...selectedTeamMembers];
        const alreadySelected = members.find((t) => t.id === parseInt(id));
        if (!alreadySelected){
            let item = teamMembers.find((t) => t.id === parseInt(id));
            if (item) {
                members.push(item);
                setSelectedTeamMembers(members);
                getDetails();
            }
        }
    }

    const removeSelectedTeamMember = (ev) => {
        if (!ev.target.id) return;
        let members = [...selectedTeamMembers];
        const selected = members.findIndex((t) => t.id === parseInt(ev.target.id));
        if (selected < members.length){
            members.splice(selected, 1);
            setSelectedTeamMembers(members);
        }
    }

    const nextPage = (ev) => {
        if (!page || !ev || page === maxPage) return;
        let newFilter = {...filter};
        newFilter.filters.page = page+1;
        setPage(newFilter.filters.page);
        setFilter(newFilter);
    }

    const previousPage = (ev) => {
        if (!page || !ev || page === 1) return;
        let newFilter = {...filter};
        newFilter.filters.page = page-1;
        setPage(newFilter.filters.page);
        setFilter(newFilter);
    }

    const numberPageClick = (page_number) => {
        if (!page_number) return;
        let newFilter = {...filter};
        newFilter.filters.page = page_number;
        setPage(newFilter.filters.page);
        setFilter(newFilter);
    }

    useEffect(() => {
        let f = {};
        f.filters = {};
        f.filters.page = 1;
        f.filters.page_size = PAGE_SIZE;
        if (org){
            f.filters.organisations = [org.id];
        }
        setFilter(f);
        getReportTypes()
        getOrganisations()
        getTeams()
        getTeamMembers()
        getDateRanges()
    }, [])

    useEffect(() => {
        if (!selectedTeamMembers) return;
        if (selectedTeamMembers){
            let newFilter = {...filter};
            if (selectedTeamMembers.length > 0)
                newFilter.filters.team_members = [];
            else
                delete newFilter.filters.team_members;
            for(let i=0;i<selectedTeamMembers.length; i++){
                newFilter.filters.team_members.push(selectedTeamMembers[i].id);
            }
            newFilter.filters.page = 1;
            setPage(1);
            setFilter(newFilter);
        }
    }, [selectedTeamMembers])

    useEffect(() => {
        if (!selectedTeams) return;
        if (selectedTeams){
            let newFilter = {...filter};
            if (selectedTeams.length > 0)
                newFilter.filters.teams = [];
            else
                delete newFilter.filters.teams;
            for(let i=0;i<selectedTeams.length; i++){
                newFilter.filters.teams.push(selectedTeams[i].id);
            }
            newFilter.filters.page = 1;
            setPage(1);
            setFilter(newFilter);
        }
    }, [selectedTeams])

    useEffect(() => {
        if (!selectedOrganisations) return;
        if (selectedOrganisations){
            let newFilter = {...filter};
            if (selectedOrganisations.length > 0)
                newFilter.filters.organisations = [];
            else
                delete newFilter.filters.organisations;
            for(let i=0;i<selectedOrganisations.length; i++){
                newFilter.filters.organisations.push(selectedOrganisations[i].id);
            }
            newFilter.filters.page = 1;
            setPage(1);
            setFilter(newFilter);
        }
    }, [selectedOrganisations])

    useEffect(() => {
        if (!selectedReportTypes) return;
        if (selectedReportTypes){
            let newFilter = {...filter};
            if (selectedReportTypes.length > 0)
                newFilter.filters.report_types = [];
            else
                delete newFilter.filters.report_types;
            for(let i=0;i<selectedReportTypes.length; i++){
                newFilter.filters.report_types.push(selectedReportTypes[i].id);
            }
            newFilter.filters.page = 1;
            setPage(1);
            setFilter(newFilter);
        }
    }, [selectedReportTypes])

    useEffect(() => {
        if (!filter) return;
        if (!selectedDateRange) return;
        if (selectedDateRange){
            let newFilter = {...filter};
            if (selectedDateRange.length > 0)
                newFilter.filters.date_range = [];
            else
                delete newFilter.filters.date_range;
            for(let i=0;i<selectedDateRange.length; i++){
                newFilter.filters.date_range.push(selectedDateRange[i]);
            }
            newFilter.filters.page = 1;
            setPage(1);
            setFilter(newFilter);
        }
    }, [selectedDateRange])

    useEffect(() => {
        if (!filter) return;
        if (propertySearchString === undefined) return;
        let newFilter = {...filter};
        if (propertySearchString)
            newFilter.filters.property = propertySearchString;
        else
            delete newFilter.filters.property;
        newFilter.filters.page = 1;
        setPage(1);
        setFilter(newFilter);
    }, [propertySearchString])

    useEffect(() => {
        if (!filter) return;
        getDetails()
    }, [filter])

    useEffect(() => {
        if (!props.filter) return;
        setFilter(props.filter);
    }, [props.filter])

    useEffect(() => {
        if (!teamMembers) return;
        if (!props.filter) return;
        if (props.filter.filters.team_members && props.filter.filters.team_members.length > 0){
            addSelectedTeamMember(parseInt(props.filter.filters.team_members[0]))
        }
    }, [teamMembers])

    useEffect(() => {
        if (!dateRanges) return;
        if (!props.filter) return;
        if (props.filter.filters.date_range && props.filter.filters.date_range.length > 0){
            addSelectedDateRange(parseInt(props.filter.filters.date_range[0].id))
        }
    }, [dateRanges])

    useEffect(() => {
        if (!reportTypes) return;
        if (!props.filter) return;
        if (props.filter.filters.report_types && props.filter.filters.report_types.length > 0){
            addSelectedReportType(parseInt(props.filter.filters.report_types[0]))
        }
    }, [reportTypes])

    useEffect(() => {
        if (!teams) return;
        if (!props.filter) return;
        if (props.filter.filters.teams && props.filter.filters.teams.length > 0){
            addSelectedTeam(parseInt(props.filter.filters.teams[0]))
        }
    }, [teams])

    function handleDownloadReport(ev) {
        if (!details) return;
        setShowDownloading(true);
        let response = papi.getReport(ev.target.id);
        response.then(
            response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                let filename = address_formatter.getPdfFilename(details[ev.target.dataset.index].property);
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
            }
        ).catch((err) => {
            setError("Failed to download report");
            setShowErrorDialog(true);
        }).finally(() => {
                setShowDownloading(false);
            }
        )
    }

    const handleOKDialogResult = (res) => {
        setShowErrorDialog(false);
    }

    const handleDownloadCSV = () => {
        if (!filter) return;
        if (!profile) return;

        let json = JSON.stringify(filter);

        // Use api for correct role
        let response = null;
        if (profile.role_id === 3)
            response = report_api.downloadCSV(json);
        else if (profile.role_id === 2)
            response = org_report_api.downloadCSV(json);

        let currentTimestamp = Date.now();
        let date = new Intl.DateTimeFormat("en-US", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
        }).format(currentTimestamp);

        setShowDownloading(true)
        response.then(
            response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'management-reports - '
                    + date.replace(",", "") + '.csv');
                document.body.appendChild(link);
                link.click();
            }
        ).catch((err) => {
            setError("Failed to download csv");
            setShowErrorDialog(true);
        }).finally(() => {
                setShowDownloading(false);
            }
        )
    }

    return (
        <>
            <div className="report-detail-container">
                <div className="report-detail-header">
                    <div className="report-detail-title">
                        Overview
                    </div>
                    <div className="report-detail-search-container">
                        <input type="text" placeholder="Search for property" value={propertySearchString}
                               onChange={(ev) => setPropertySearchString(ev.target.value)}/>
                        <div className="link">
                            <img src={search_logo} alt="search logo" id="search-icon"/>
                        </div>
                    </div>
                </div>
                <div className="report-detail-filter-container">
                    <AdminDropDownList items={reportTypes} placeholder="Report type" handleSelectedItem={addSelectedReportType}/>
                    <AdminDropDownList items={dateRanges} placeholder="Date range" handleSelectedItem={addSelectedDateRange}/>
                    {showDatePicker ? <DateRangePickerDialog handleClose={handleClosePicker.bind(this)} /> : null }
                    {profile && profile.role_id === 3 && !org? <AdminDropDownList items={organisations} placeholder="Organisation"
                                                                                  searchPlaceholder="Search for organisation"
                                                                                  handleSelectedItem={addSelectedOrganisation}
                                                                                  hasSearch={true}/>
                        : null }
                    <AdminDropDownList items={teams} placeholder="Team"
                                       searchPlaceholder="Search for team"
                                       handleSelectedItem={addSelectedTeam}
                                       hasSearch={true}/>
                    <AdminDropDownList items={teamMembers} placeholder="Team member"
                                       searchPlaceholder="Search for member"
                                       handleSelectedItem={addSelectedTeamMember}
                                       hasSearch={true}/>

                    <div className="download-grid-button link" onClick={handleDownloadCSV}>
                        Export CSV
                    </div>

                </div>
                <div className="report-detail-filter-list-container">
                    {selectedReportTypes && selectedReportTypes.map((item, idx) => {
                        return (
                            <>
                                <div className="report-detail-filter-item" >
                                    <div className="report-detail-filter-item-name">{item.name}</div>
                                    <div className="report-detail-filter-item-cross link" id={item.id}
                                         onClick={removeSelectedReportType}>x</div>
                                </div>
                            </>
                        )
                    })}
                    {selectedDateRange && selectedDateRange.map((item, idx) => {
                        return (
                            <>
                                <div className="report-detail-filter-item" >
                                    <div className="report-detail-filter-item-name">{item.start_date ?
                                        date_formatter.display_date(item.start_date) + " - " + date_formatter.display_date(item.end_date)
                                        : item.name}</div>
                                    <div className="report-detail-filter-item-cross link" id={item.id}
                                         onClick={removeSelectedDateRange}>x</div>
                                </div>
                            </>
                        )
                    })}
                    {selectedOrganisations && selectedOrganisations.map((item, idx) => {
                        return (
                            <>
                                <div className="report-detail-filter-item" >
                                    <div className="report-detail-filter-item-name">{item.name}</div>
                                    <div className="report-detail-filter-item-cross link" id={item.id}
                                         onClick={removeSelectedOrganisation}>x</div>
                                </div>
                            </>
                        )
                    })}
                    {selectedTeams && selectedTeams.map((item, idx) => {
                        return (
                            <>
                                <div className="report-detail-filter-item" >
                                    <div className="report-detail-filter-item-name">{item.name}</div>
                                    <div className="report-detail-filter-item-cross link" id={item.id}
                                         onClick={removeSelectedTeam}>x</div>
                                </div>
                            </>
                        )
                    })}
                    {selectedTeamMembers && selectedTeamMembers.map((item, idx) => {
                        return (
                            <>
                                <div className="report-detail-filter-item" >
                                    <div className="report-detail-filter-item-name">{item.name}</div>
                                    <div className="report-detail-filter-item-cross link" id={item.id}
                                         onClick={removeSelectedTeamMember}>x</div>
                                </div>
                            </>
                        )
                    })}
                </div>
                <div className="report-detail-grid">
                    <div className="report-detail-grid-col-headers">
                        <div className="requested col">Team member</div>
                        <div className="address col">Property address</div>
                        <div className="postcode col">Postcode</div>
                        <div className="date col">Date and time</div>
                        {!org ? <div className="org col">Organisation</div> : null }
                        <div className="type col">Type</div>
                        <div className="report col" style={{paddingLeft: "17px"}}>Report</div>
                    </div>
                    <div className="report-detail-grid-rows">
                        {details && details.map((detail, idx) => {
                            return (
                                <>
                                    <div className="report-detail-grid-row">
                                        <div className="requested col">{detail.user.firstname.substring(0, 1)}.{detail.user.lastname}</div>
                                        <div className="address col">{detail.property.address}</div>
                                        <div className="postcode col">{detail.property.postcode}</div>
                                        <div className="date col">{date_formatter.display_date_time(detail.report_date*1000)}</div>
                                        {!org ? <div className="org col">{detail.organisation.name}</div>
                                            : null }
                                        <div className="type col">{detail.report_type.name}</div>
                                        {detail.status_id === 1 ?
                                            <div className="report col" style={{paddingLeft: "15px"}} id={detail.id} data-index={idx}>
                                                Pending
                                            </div>
                                            :
                                            <div className="report col" onClick={handleDownloadReport} id={detail.id} data-index={idx}>
                                                <img src={download} alt="download icon" id={detail.id} data-index={idx}/>
                                            </div>
                                        }
                                    </div>
                                </>
                            )
                        })}
                    </div>
                </div>
                <div className="report-detail-page-navigation-container">
                    <PageNavigation page={page}
                                    maxPage={maxPage}
                                    previousPage={previousPage.bind(this)}
                                    numberPageClick={numberPageClick.bind(this)}
                                    nextPage={nextPage.bind(this)}/>
                </div>
            </div>
            {showDownloading ? <GenericDialog title="Download" text="Your report is being prepared"/>
                : null}
            {showErrorDialog ?
                <GenericDialog title="Valuation - Error" text={error} buttons={okButton}
                               result={handleOKDialogResult.bind(this)} /> : null }
        </>
    )
}

export default AdminReportOverviewGrid