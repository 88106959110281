import UserComment from "../UserComment";
import ExternalUserComment from "../ExternalUserComment";
import ReviewCommentInput from "../ReviewCommentInput";
import FileComment from "../FileComment";
import ExternalFileComment from "../ExternalFileComment";

const ValuerOverviewMessenger = (props) => {
    const profileRole = props.profileRole;
    const history = props.history;
    const summaryId = props.summaryId;
    const getHistory = props.getHistory;

    return (
        <>
            <div className="valuer-messenger-container">
                <div className="valuation-overview-grid-rows valuer">
                    {history && history.map((entry) => {
                        return(
                            <>
                                {entry.type_id === 1  ?
                                    <>
                                        <div className="valuation-comment-row-container">
                                            {entry.current_user === true ?
                                                <UserComment comment={entry.comment}
                                                             date={entry.entry_date}/>
                                                :
                                                <ExternalUserComment comment={entry.comment}
                                                                     date={entry.entry_date}
                                                                     roleId={entry.entry_user.role_id}/>
                                            }
                                        </div>
                                    </>
                                    : null
                                }
                                {entry.type_id === 5  ?
                                    <>
                                        <div className="valuation-comment-row-container">
                                            {entry.current_user === true ?
                                                <FileComment comment={entry.comment}
                                                             date={entry.entry_date}
                                                             summary_id={summaryId}
                                                />
                                                :
                                                <ExternalFileComment comment={entry.comment}
                                                                     date={entry.entry_date}
                                                                     summary_id={summaryId}
                                                                     roleId={entry.entry_user.role_id}/>
                                            }
                                        </div>
                                    </>
                                    : null
                                }
                            </>
                        )
                    })}
                </div>
                <ReviewCommentInput summaryId={summaryId}
                                    getHistory={getHistory}
                                    view={"valuer"}/>
            </div>

        </>
    )
}

export default ValuerOverviewMessenger