const GenericDialog = (props) => {
    const title = props.title;
    const text = props.text;
    const buttons = props.buttons;
    const result = props.result;

    const handleButtonClick = (ev) => {
        if (!ev) return;
        result(ev.currentTarget.id);
    }

    return (
        <>
            <div className="page-mask"/>
            <div className="dialog-container">
                <div className="dialog-header-container">
                    <div className="dialog-header">
                        {title}
                    </div>
                    <div className="dialog-text">
                        {text}
                    </div>
                    <div className="dialog-footer-container">
                        {buttons && buttons.map((button, idx) => {
                            return (
                                <>
                                    <div className="dialog-button link" onClick={handleButtonClick} id={button.id}>
                                        <div className="dialog-button-text">{button.text}</div>
                                    </div>
                                </>
                            )
                        })}
                    </div>
                </div>
            </div>
        </>
    )
}

export default GenericDialog