import {useEffect, useState} from "react";
import cancel from "../assets/cancel.png"
import check from "../assets/check_circle.png"
import clock from "../assets/history_toggle_off.png"
import under_review from "../assets/do_not_disturb_on.png"
import changed from "../assets/published_with_changes.png"
import dateUtils from "../utils/DateUtils";

const ReportComment = (props) => {

    const name = props.name;
    const description = props.description;
    const date = props.date;
    const status = props.status;
    const [statusColor, setStatusColor] = useState("green")
    const [image, setImage] = useState(check)

    useEffect(() => {
        if (!status) return;
        if (status === 1) {
            setStatusColor("yellow")
            setImage(clock)
        } else if (status === 4) {
            setStatusColor("green")
            setImage(check)
        } else if (status === 5) {
            setStatusColor("red")
            setImage(cancel)
        } else if (status === 8) {
            // For amended status
            setStatusColor("white")
            setImage(changed)
        }
    }, [status]);

    return (
        <>
            <div className="valuation-comment-container">
                <div className="valuation-flex-container">
                    <div className={`report-message-color ${statusColor}`}/>
                    <div className="valuation-review-img-contianer">
                        <img src={image} alt="tick"/>
                    </div>
                    <div className="valuation-data-container">
                        <div className="valuation-info-header-container">
                            <div className="valuation-info-status-container">
                                {name}
                            </div>
                            <div className="valuation-info-date-container">
                                <div className="valuation-info-time-container">
                                    {dateUtils.display_date_time(date*1000)}
                                </div>
                            </div>
                        </div>
                        <div className="valuation-report-comment-container">
                            <div className="valuation-report-comment">
                                {description}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ReportComment