import {useState} from "react";


const InputBarDropdown = (props) => {

    const allowInput = props.allowInput
    const firstField = props.firstField
    const _default_value = props.default
    const _title = props.title

    const values = props.values
    const setValue = props.setSelectedValue
    const selectedValue = props.selectedValue

    const [dropdownOpen, setDropdownOpen] = useState(false)

    return (
        <div className={"valuation-field-container " + (firstField && firstField ? "borderless" : "")}>
            <div className="valuation-type-dropdown-ancor">
                <div className='valuation-dropdown-container'>
                    <div className={allowInput && allowInput ? "valuation-type-title" : "valuation-type-title disabled"}>
                        {_title}
                    </div>
                    <div className={allowInput && allowInput ? "valuation-type" : "valuation-type disabled"}
                         onClick={() => setDropdownOpen(allowInput) }>
                        {selectedValue && selectedValue ? selectedValue.name : _default_value}
                    </div>
                </div>
                {dropdownOpen ?
                    <>
                        <div className="valuation-type-dropdown-options">
                            {values && values.map((value, idx) => (
                                <div className={"valuation-type-option-container " +
                                    (idx === 0 ? "first" : idx === values.length - 1 ? "last" : "")}
                                     onClick={() => {
                                         setValue(value)
                                         setDropdownOpen(false)
                                     }}>
                                    <div className={"valuation-type-option " +
                                        (idx === 0 ? "first" : idx === values.length - 1 ? "last" : "")}
                                         id={value.id} key={value.id}>
                                        {value.name}
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="invisible-page-overlay" onClick={() => setDropdownOpen(false)}/>
                    </> : null
                }
            </div>
        </div>
    )
}

export default InputBarDropdown;