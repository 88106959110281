import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

import papi from "../api/pdf";

import valuation_api from "../api/valuation";
import address_formatter from "../utils/Address";
import date_formatter from "../utils/DateUtils";

import download_logo from "../assets/download.png";
import query_logo from "../assets/query_logo.png"
import search_logo from "../assets/profile_search.png";

import PageNavigation from "./PageNavigation";
import GenericDialog from "./GenericDialog";
import ValuationStatusPill from "./ValuationStatusPill";
import CompareProperties from "./CompareProperties";
import ValuationTypeMenuItem from "./ValuationTypeMenuItem";
import profapi from "../api/profile";


const ValuationHistory = () => {

    const navigate = useNavigate();
    const PAGE_SIZE = 8

    const [valuations, setValuations] = useState([]);
    const [showNotifications, setShowNotifications] = useState(false);

    // Search Values
    const [valuationTypes, setValuationTypes] = useState()
    const [searchString, setSearchString] = useState("");
    const [activeType, setActiveType] = useState(1)
    const [page, setPage] = useState(1)
    const [maxPage, setMaxPage] = useState()

    // Error Vars
    const [showDownloading, setShowDownloading] = useState(false);
    const [showErrorDialog, setShowErrorDialog] = useState(false);
    const [error, setError] = useState();
    const okButton = [{"text":"OK", "id": "1"}];

    useEffect(() => {
        getValuationTypes()
    }, []);

    const handleRowClick = (summary_id) => {
        if (!summary_id) return;
        if (activeType != 2) return;
        valuation_api.deleteNotifications(summary_id)
        window.location.assign("/profile/review/"+summary_id)
    }

    const getValuationTypes = () => {
        let response = valuation_api.getValuationTypes();
        response.then(response => {
            setValuationTypes(response.data.types);
            // Set Active type if there is atleast one
            if (response.data.types.length > 0) setActiveType(response.data.types[0].id)
        })
    }

    const NotificationCheck = () => {
        let response = profapi.getNotifications();
        response.then(
            response => {
                if (response.data.notifications === 0) {
                    setShowNotifications(false)
                } else {
                    setShowNotifications(true)
                }
            }
        )
    }

    useEffect(() => {
        const searchData = {
            "search": searchString,
            "type": activeType,
            "page": page,
            "page_size": PAGE_SIZE
        }
        let response = valuation_api.getMyValuations(searchData);
        response.then(
            response => {
                setMaxPage(response.data.total_pages)
                setValuations(response.data.valuation_summaries);
            }
        )
    }, [activeType, page, searchString]);

    useEffect(() => {
        setPage(1)
    },[searchString])

    const handleOKDialogResult = (res) => {
        setShowErrorDialog(false);
    }

    const handleDownloadReport = (ev) => {
        setShowDownloading(true);
        let response = papi.getReport(ev.target.id);
        response.then(
            response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                let filename = address_formatter.getPdfFilename(valuations[ev.target.dataset.index]);
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
            }
        ).catch((err) => {
            setError("Failed to download report");
            setShowErrorDialog(true);
        }).finally(() => {
                setShowDownloading(false);
            }
        )
    }

    const handleSeeInsight = (valuation_id) => {
        navigate("/valuation?id=" + valuation_id, {replace:true})
    }

    useEffect(() => {
        setDefaultBehaviour()
        NotificationCheck()
    }, [])

    function setDefaultBehaviour() {
        let container = document.getElementById("history-container");
        if (container){
            container.addEventListener("keypress", function (ev) {
                if (ev.key === "Enter") {
                    ev.preventDefault();
                    document.getElementById("search-icon").click();
                }
            })
        }
    }

    const nextPage = (ev) => {
        if (!page || !ev || page === maxPage) return;
        setPage(page + 1)
    }

    const previousPage = (ev) => {
        if (!page || !ev || page === 1) return;
        setPage(page - 1)
    }

    const numberPageClick = (page_number) => {
        if (!page_number) return;
        setPage(page_number)
    }

    return (
        <>
            <div className="valuation-history-container col" id="history-container">
                <div className="valuation-history-grid-container">
                    <div className="valuation-grid-header">

                        <div className="valuation-grid-types-container">
                            {valuationTypes && valuationTypes.map( (type, idx) => {
                                return (
                                    <ValuationTypeMenuItem type={type}
                                                           activeType={activeType}
                                                           setActiveType={setActiveType}
                                                           showNotifications={showNotifications}/>
                                )
                            })}
                        </div>

                        <div className="valuation-grid-search-container">
                            <input type="text" placeholder="Search for property" value={searchString}
                                   onChange={(ev) => setSearchString(ev.target.value)}/>
                            <div>
                                <img src={search_logo} alt="Magnifying Glass"/>
                            </div>
                        </div>
                    </div>
                    <div className="valuation-grid-contents">
                        <div className="valuation-grid-col-headers">
                            <div className="address col">Address</div>
                            <div className="date col">Date / Time </div>
                            {activeType && activeType === 3 ? <div className="insight col">Insight</div> : null}
                            <div className="report col">Report</div>
                            <div className="mobile-date col">Date</div>
                            <div className="mobile-report col">Report</div>
                            {activeType && activeType === 2 ? <div className="status col">Status</div> : null}
                            {activeType && activeType === 2 ? <div className="query col">Query</div> : null}
                        </div>
                        <div className="valuation-grid-rows">
                            {valuations && valuations.map((detail, idx) => {
                                return (
                                    <>
                                        <div className="valuation-grid-row link" >
                                            <div className="address col link" onClick={() => handleRowClick(detail.id)}>
                                                {address_formatter.formatSearchAddress(detail)}
                                            </div>
                                            <div className="date col" onClick={() => handleRowClick(detail.id)}>
                                                {date_formatter.display_date_time(detail.date*1000)}
                                            </div>
                                            {detail.type === 3 ?
                                                <div className="insight col" data-index={idx}
                                                     onClick={() => handleSeeInsight(detail.id)}>
                                                    See insight
                                                </div>
                                                : null }
                                            {detail.summary_status_id === 4 || detail.type === 1 || detail.type === 3 ?
                                                <>
                                                    <div className="report col" id={detail.id} data-index={idx}>
                                                        <u id={detail.id} data-index={idx} onClick={handleDownloadReport}>Download
                                                            report</u>
                                                    </div>
                                                    <div className="mobile-report col" onClick={handleDownloadReport}
                                                         id={detail.id} data-index={idx}>
                                                        <img src={download_logo} alt="Download icon"/>
                                                    </div>
                                                </>
                                                :
                                                <div className="report col" id={detail.id} data-index={idx}
                                                     onClick={() => handleRowClick(detail.id)}/>
                                        }

                                        <div className="mobile-date col" onClick={() => handleRowClick(detail.id)}>
                                                {date_formatter.display_date_short(detail.date*1000)}
                                            </div>
                                            {detail.type === 2 ?
                                                <>
                                                    <div className="status col" data-index={idx}
                                                         onClick={() => handleRowClick(detail.id)}>
                                                        <ValuationStatusPill status_id={detail.summary_status_id}
                                                                             status_name={detail.summary_status_name}/>
                                                    </div>
                                                    <div className="query col" data-index={idx}
                                                         onClick={() => handleRowClick(detail.id)}>
                                                        <img src={query_logo} alt="Speech Bubble"/>
                                                        {detail.notifications !== null ?
                                                            <div className="notification-container">
                                                                <div className="notification-text">
                                                                    {detail.notifications}
                                                                </div>
                                                            </div>
                                                        :null}

                                                    </div>
                                                </>
                                                : null }
                                        </div>
                                    </>
                                )
                            })}
                        </div>
                    </div>
                    <div className="valuation-page-navigation-container">
                        <PageNavigation page={page}
                                        maxPage={maxPage}
                                        previousPage={previousPage.bind(this)}
                                        numberPageClick={numberPageClick.bind(this)}
                                        nextPage={nextPage.bind(this)}/>
                    </div>
                    {activeType === 3 ?
                        <>
                            <div className="recent-searches-divider"/>
                            <div className="comparison-title-container reports">
                                <div className="comparison-title">
                                    Compare your searches
                                </div>
                            </div>
                            <CompareProperties />
                        </>
                        :null}
                </div>
            </div>
            {showDownloading ? <GenericDialog title="Download" text="Your report is being prepared"/>
                : null}
            {showErrorDialog ?
                <GenericDialog title="Valuation - Error" text={error} buttons={okButton}
                               result={handleOKDialogResult.bind(this)} /> : null }
        </>
    )
}

export default ValuationHistory