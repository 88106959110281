import SearchDetailRow from "../SearchDetailRow";


const PropertyDetails = (props) => {

    const category = props.category
    const setShowPropertyDetails = props.setShowPropertyDetails

    const bedrooms = props.bedrooms
    const setBedrooms = props.setBedrooms
    const bathrooms = props.bathrooms
    const setBathrooms = props.setBathrooms
    const livingRooms = props.livingRooms
    const setLivingRooms = props.setLivingRooms
    const size = props.size
    const setSize = props.setSize


    const propertySummary = props.propertySummary
    const setPropertySummary = props.setPropertySummary

    const formatSize = (_string) => {
        return _string && _string.replace(/\D/g,'').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    return (
        <>
            {category && category.id === 1 ?
                <>
                    <div className="valuation-type-dropdown-options">
                        <SearchDetailRow value={bedrooms} change={setBedrooms} first={true} name={"Bedrooms"} max={5}/>
                        <SearchDetailRow value={bathrooms} change={setBathrooms} name={"Bathrooms"}/>
                        <SearchDetailRow value={livingRooms} change={setLivingRooms} name={"Living rooms"}/>
                        <div className="valuation-type-option-container counter last">
                            <div className="valuation-type-option counter last">
                                <div className="valuation-type-option-title"> Sq ft</div>
                                <input type="text" value={formatSize(size)}
                                       onChange={(ev) => setSize(ev.target.value)}/>
                            </div>
                        </div>
                    </div>
                    <div className="invisible-page-overlay" onClick={() => setShowPropertyDetails(false)}/>
                </>
                : category && category.id === 2 ?
                    <>
                        <div className="valuation-know-property-free-text-container">
                            <div className="valuation-type-option full last">
                                Share summary details of the property:
                            </div>
                            <textarea className="valuation-know-property-free-text" value={propertySummary}
                                      onChange={(ev) => {
                                          if (ev.target.value.length > 1100) return
                                          setPropertySummary(ev.target.value)
                                      }}/>
                            <div className="valuation-know-property-free-text-character-count">
                                {propertySummary.length}/1100 characters
                            </div>
                        </div>
                        <div className="invisible-page-overlay" onClick={() => {
                            console.log("Clicked")
                            setShowPropertyDetails(false)
                        }}/>
                    </>
                    : null
            }
        </>
    )
}

export default PropertyDetails;
