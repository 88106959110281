import axiosInstance from "./http/axios_instance"
const http_protected = axiosInstance("/protected/stripe", "/profile/details")


const checkCheckoutOutcome = (checkout_id) => http_protected.get("/check_checkout_outcome/" + checkout_id);

const checkProductCheckoutOutcome = (checkout_id) => http_protected.get("/check_product_checkout_outcome/" + checkout_id);

const createCheckout = (price_id, from) => http_protected.post("/create_checkout_session/" + price_id, {
    "from": from
});

const createPortal = (from) => http_protected.post("/create_portal_session", {
    "from": from
});

const cancelMembership = () => http_protected.post("/cancel_membership");

export default {
    checkCheckoutOutcome,
    checkProductCheckoutOutcome,

    createCheckout,
    createPortal,
    cancelMembership
}