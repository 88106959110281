import {useEffect, useState} from "react";


const LoadingWheel = () => {

    const [segments, setSegments] = useState([])

    useEffect(() => {
        let no_sections = 12;
        let section_rotate = 360 / no_sections
        let sections = []
        let section;

        for (let x = 0; x < no_sections; x++) {
            section = {}
            section.transform = "rotate(" + x * section_rotate + "deg)"
            section.animationDelay = ((no_sections - x) / 10) * - 1 + "s"
            sections.push(section)
        }

        setSegments(sections)
    }, []);

    return (
        <div className="stripe-success-loading-wheel">
            {segments && segments.map((style) => {
                return (
                    <div className="loading-wheel-section" style={style} />
                )
            })}
        </div>
    )
}

export default LoadingWheel