import {useEffect, useState} from "react";


const ValuerAmendStrCard = (props) => {

    const title = props.title
    const data = props.data
    const changeValue = props.changeValue

    const [newValue, setNewValue] = useState()

    const handleChange = (ev) => {
        let _value = ev.target.value
        setNewValue(_value)
        changeValue(title, _value)
    }

    useEffect(() => {
        setNewValue(data)
    },[data])

    return(
        <>
            <div className="valuer-amend-card-container">
                <div className="valuer-amend-card-header-container">
                    <div className="valuer-amend-card-header">
                        {title}
                    </div>
                </div>
                <div className="valuer-amend-valuation-text">
                    <input type="text" value={newValue} placeholder="No Data" onChange={handleChange}/>
                </div>
            </div>
        </>
    )
}

export default ValuerAmendStrCard