import {useEffect} from "react";


const SearchDetailRow = (props) => {
    const min = 0
    const max = props.max && props.max ? props.max : -1

    const value = props.value
    const name = props.name
    const change = props.change
    const first = props.first && props.first ? props.first : false

    return(
        <div className={"valuation-type-option-container counter " + (first && first ? "first" : "")}>
            <div className="valuation-type-option counter">
                <div className="valuation-type-option-title">
                    {name}
                </div>
                <div className="valuation-type-option-input">
                    <div className={value === min ? "counter-button-container disabled": "counter-button-container"}
                         onClick={() => {
                             if (value === min) return;
                             change(value - 1)
                         }}>
                        -
                    </div>
                    <div className="counter-value-container">
                        {value && value
                            ?
                            value === max ?
                                value + "+"
                                : value
                            : "-"}
                    </div>
                    <div className={"counter-button-container " + (value === max ? "disabled" : "")}
                         onClick={() => {
                             if (value === max) return;
                             change(value + 1)
                         }}>
                        +
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SearchDetailRow