import React, {useEffect, useState} from "react";
import "chart.js/auto";
import { Bar } from "react-chartjs-2";

const InsightAreaBarChart = (props) => {

    const data = props.data;
    const name = props.name;

    const [labels, setLabels] = useState();
    const [similarData, setSimilarData] = useState([]);
    const [allData, setAllData] = useState([]);

    const FormatData = () => {
        if(!data) return;
        let _l = []
        let _s = []
        let _a = []
        for (let i=0; i<data.length;i++) {
            _l.push(data[i].name.split(" "))
            _s.push(data[i].similar_properties_avg)
            _a.push(data[i].all_properties_avg)
        }
        setLabels(_l)
        setSimilarData(_s)
        setAllData(_a)
    }

    useEffect(() => {
        FormatData()
    },[data])

    const saleChartData ={
        labels: labels,
        datasets: [{
            label: "Similar properties",
            barThickness: 36,
            backgroundColor: "#4975A1",
            borderWidth: {
                left: 10,
                right: 10,
            },
            borderColor: "transparent",
            data: similarData,
        },
            {
                label: "All properties",
                barThickness: 36,
                backgroundColor: "#9B3D8C",
                borderWidth: {
                    left: 10,
                    right: 10,
                },
                borderColor: "transparent",
                data: allData,
            }]
    }

    const rentChartData ={
        labels: labels,
        datasets: [{
            label: "Similar properties",
            barThickness: 16,
            backgroundColor: "#7E468F",
            borderColor: "#7E468F",
            data: similarData,
        }]
    }

    const saleOptions = {
        elements: {
            point: {
                pointStyle: false,
            },
            line: {
                borderWidth: 6,
            },
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                grid: {
                    display: false,
                    color: "#4B6075",
                },
                ticks: {
                    color: "white",
                }
            },
            xAxes: {
                ticks: {
                    callback: function(label) {
                        if (/\s/.test(label)) {
                            return label.split(" ");
                        }else{
                            return label;
                        }

                    },
                },
                gridLines: {color: "#4B6075"},
                display: false,
                title: {
                    align: "center",
                    text: "anything"
                }
            },
            y: {
                grid: {
                    display: true,
                    color: "#626972",
                    borderColor: "#626972",
                    lineWidth: 1,
                },
                border: {
                    display: false,
                },
                ticks: {
                    callback: function(value) {
                        if (value < 1000) {
                            return "£" + value;
                        } else if (value >= 1000 && value < 1000000) {
                            return "£" + value/1000 + "k";
                        } else if (value >= 1000000) {
                            return "£" + value/1000000 + "m";
                        }
                    },
                    color: "white",
                }
            },
            yAxes: {
                gridLines: {color: "#4B6075"},
                display: false,
            },
        },
        plugins: {
            legend: {
                display: false,
                position: "bottom",
                labels: {
                    color: "#ffffff",
                    padding: 10
                }
            },
            tooltip: {
                callbacks: {
                    title: (context) => {

                    }
                }
            },

        },
        layout: {
            padding: {
                left: 0
            }
        }
    }

    const rentOptions = {
        elements: {
            point: {
                pointStyle: false,
            },
            line: {
                borderWidth: 6,
            },
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                grid: {
                    display: false,
                    color: "#4B6075",
                },
                ticks: {
                    color: "white",
                }
            },
            xAxes: {
                ticks: {
                    callback: function(label) {
                        if (/\s/.test(label)) {
                            return label.split(" ");
                        }else{
                            return label;
                        }

                    },
                },
                gridLines: {color: "#4B6075"},
                display: false,
                title: {
                    align: "center",
                    text: "anything"
                }
            },
            y: {
                grid: {
                    display: true,
                    color: "#626972",
                    borderColor: "#626972",
                    lineWidth: 1,
                },
                border: {
                    display: false,
                },
                ticks: {
                    callback: function(value) {
                        if (value < 1000) {
                            return "£" + value;
                        } else if (value >= 1000 && value < 1000000) {
                            return "£" + value/1000 + "k";
                        } else if (value >= 1000000) {
                            return "£" + value/1000000 + "m";
                        }
                    },
                    color: "white",
                }
            },
            yAxes: {
                gridLines: {color: "#4B6075"},
                display: false,
            },
        },
        plugins: {
            legend: {
                display: false,
                position: "bottom",
                labels: {
                    color: "#ffffff",
                    padding: 10
                }
            },
            tooltip: {
                callbacks: {
                    title: (context) => {

                    }
                }
            },

        },
        layout: {
            padding: {
                left: 0
            }
        }
    }

    return (
        <div className="insight-area-chart-container">
            {name === "Average prices by area" ?
                <Bar data={saleChartData} options={saleOptions}/>
                :
                <Bar data={rentChartData} options={rentOptions}/>
            }

        </div>
    );
}

export default InsightAreaBarChart