import download from "../assets/download.png";
import rics_logo from "../assets/rics_logo.png";
import pdf_api from "../api/pdf";
import address_formatter from "../utils/Address";
import {useEffect, useState} from "react";
import GenericDialog from "./GenericDialog";

const PdfDocument = (props) => {
    const valuation = props.valuation;
    const processedDate = props.processedDate;
    const showError = props.showError;
    const summary_id = props.id;
    const title = props.title

    const [rics, setRics] = useState(false)

    const [showDownloading, setShowDownloading] = useState(false);
    const [showErrorDialog, setShowErrorDialog] = useState(false);
    const [error, setError] = useState();
    const okButton = [{"text":"OK", "id": "1"}];

    useEffect(() => {
        if (!props.rics) return;
        setRics(props.rics)
    }, [props.rics]);

    const handleOKDialogResult = (res) => {
        setShowErrorDialog(false);
    }

    const handleDownloadClick = () => {
        if (!summary_id) return;
        setShowDownloading(true);
        let response = pdf_api.getReport(summary_id);
        response.then(
            response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                let detail = valuation["address"];
                detail.date = processedDate;
                let filename = address_formatter.getPdfFilename(detail);
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
            }
        ).catch((err) => {
            setError("Failed to download report");
            setShowErrorDialog(true);
        }).finally(() => {
                setShowDownloading(false);
            }
        )
    }

    return (
        <>
            <div className="valuation-report-title">{title}</div>
            <div className="valuation-report-text">{"The full report has been created for you to download."}</div>

            <div className="valuation-report-download-button-container">
                <button className="valuation-report-button link col" onClick={handleDownloadClick}>
                    <div className="valuation-report-button-content">
                        <img src={download} alt="download logo"/>
                        <div className="valuation-report-button-text">{"Download PDF"}</div>
                    </div>
                </button>
                {rics && rics ?
                    <div className="report-download-rics-logo-container col">
                        <img src={rics_logo} alt={"RICS Logo"} />
                    </div>
                    : null
                }
            </div>
            {showDownloading ? <GenericDialog title="Download" text="Your report is being prepared"/>
                : null}
            {showErrorDialog ?
                <GenericDialog title="Report - Error" text={error} buttons={okButton}
                               result={handleOKDialogResult.bind(this)} /> : null }
        </>
    )
}

export default PdfDocument